import { ITermsDetails } from '@models/index'
import { Action, ActionTypes } from '../actions'

export type TermsState = {
  terms: ITermsDetails
}

export default (state: TermsState, action: Action): TermsState => {
  switch (action.type) {
    case ActionTypes.TERMS: {
      return { ...state, terms: action.payload }
    }
    default:
      return state
  }
}
