import { StudySubject } from "@models/api.models";
import React, { createContext, FunctionComponent, useCallback, useReducer } from "react";
import { Action, setSubjectsAction } from "./subject.actions";
import reducer, { SubjectState } from './subject.reducer'
import useSubjectSocket from '../websocket/subjects.websocket'

export const initialState: SubjectState = {
    subjects: {}
}

const SubjectContext = createContext<{
    state: SubjectState,
    dispatch: React.Dispatch<Action>
}>({
    state: initialState,
    dispatch: () => null
})

type SubjectProviderParams = {
    siteId: string
}

const SubjectProvider: FunctionComponent<SubjectProviderParams> = ({ siteId, children }) => {
    const [state, dispatch] = useReducer(reducer, initialState)
    const setSubjects = useCallback((subjects: StudySubject[]) => {
        dispatch(setSubjectsAction(subjects))
    }, [])
    useSubjectSocket({ siteId, setSubjects })

    return (
        <SubjectContext.Provider value={{ state, dispatch }}>
            {children}
        </SubjectContext.Provider>
    )
}

export { SubjectContext, SubjectProvider }