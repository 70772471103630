import { useCallback } from 'react'
import { useKeycloak } from '@providers/auth'
import useToast from '@providers/data/hooks/useToast'
import { addSubject } from '@lib/fetch-requests'

export interface INewSubject {
  label: string
}

export default () => {
  const { token } = useKeycloak()
  const { createToast } = useToast()

  const createSubject = useCallback(
    (siteId: string, data: INewSubject) =>
      addSubject(token, siteId, data)
        .then((subject) => subject.id)
        .then((id) => {
          createToast('Successfully created subject.')
          return id
        })
        .catch((e) => {
          console.log(e)
          createToast('Error - Could not create subject', 'error')
          throw e
        }),
    [token, createToast]
  )

  return {
    createSubject,
  }
}
