import { IRoleDetail } from '@models/index'
import { Action, ActionTypes } from '../actions'

export default (state: IRoleDetail[], action: Action): IRoleDetail[] => {
  switch (action.type) {
    case ActionTypes.ROLES: {
      return [...action.payload]
    }
    default:
      return state
  }
}
