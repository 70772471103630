import React from 'react'
import { Grid } from '@material-ui/core'
import { useKeycloak } from '@providers/auth'
import SiteStaffCardView from '@components/Staff/SiteStaffCardView'

type SiteStaffManagementViewProps = {
  siteId: string
}

const SiteStaffManagementView: React.FunctionComponent<
  SiteStaffManagementViewProps
> = (props) => {
  const { keycloak } = useKeycloak()

  return (
    <Grid container spacing={2} style={{ marginTop: '1em' }}>
      {keycloak.hasRealmRole('study-lead') && (
        <Grid item xs={12} lg={6}>
          <SiteStaffCardView
            siteId={props.siteId}
            cardTitle="Dexcom Staff"
            staffType="dexcomStaff"
          />
        </Grid>
      )}
      <Grid item xs={12} lg={6}>
        <SiteStaffCardView
          siteId={props.siteId}
          cardTitle="Site Staff"
          staffType="siteStaff"
        />
      </Grid>
    </Grid>
  )
}

export default SiteStaffManagementView
