import React from 'react'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import Divider from '@material-ui/core/Divider'
import { makeStyles, useTheme } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '40px',
  },
  title: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: theme.palette.text.secondary,
  },
  section: {
    padding: '5px 15px 5px 15px',
  },
  value: {
    float: 'right',
    fontWeight: 'bold',
    borderRadius: '10px',
    padding: '0 5px 0 5px',
  },
  green: {
    backgroundColor: theme.inRange.backgroundColor,
    color: theme.inRange.color,
    borderColor: theme.inRange.borderColor,
  },
  red: {
    color: theme.egvAlert.color,
    background: theme.egvAlert.backgroundColor,
    borderColor: theme.egvAlert.borderColor,
  },
  yellow: {
    color: theme.egvWarn.color,
    background: theme.egvWarn.backgroundColor,
    borderColor: theme.egvWarn.borderColor,
  },
}))

type EventsCardProps = {
  timeInRange: number
  timeHypo: number
  timeHyper: number
  numberHypoEvents: number
  numberHyperEvents: number
}

export default function EventsCard(props: EventsCardProps) {
  const theme = useTheme()
  const classes = useStyles(theme)
  const {
    timeInRange,
    timeHypo,
    timeHyper,
    numberHypoEvents,
    numberHyperEvents,
  } = props

  return (
    <Card>
      <CardHeader
        classes={{ root: classes.root, title: classes.title }}
        title="Events"
      />
      <Divider />
      <div className={classes.section}>
        <span>Time In Range (24h)</span>
        <span className={`${classes.value} ${classes.green}`}>
          {formatMinutes(timeInRange)}
        </span>
      </div>
      <Divider />
      <div className={classes.section}>
        <div style={{ paddingBottom: 2 }}>
          <span>Time In Hypoglycemia (24h)</span>
          <span className={`${classes.value} ${classes.red}`}>
            {formatMinutes(timeHypo)}
          </span>
        </div>
        <div>
          <span>Number of Hypoglycemia Events</span>
          <span className={`${classes.value} ${classes.red}`}>
            {numberHypoEvents}
          </span>
        </div>
      </div>
      <Divider />
      <div className={classes.section}>
        <div style={{ paddingBottom: 2 }}>
          <span>Time in Hyperglycemia (24h)</span>
          <span className={`${classes.value} ${classes.yellow}`}>
            {formatMinutes(timeHyper)}
          </span>
        </div>
        <div>
          <span>Number of Hyperglycemia Events</span>
          <span className={`${classes.value} ${classes.yellow}`}>
            {numberHyperEvents}
          </span>
        </div>
      </div>
    </Card>
  )
}

function formatMinutes(minutes: number): string {
  const hours = (minutes / 60) | 0
  if (hours < 1) {
    return `${minutes}m`
  }

  minutes = minutes % 60

  return `${hours}h ${minutes}m`
}
