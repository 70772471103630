import { API_URL, siteUrl } from '@config/resourceUrls'
import { get, put } from '@lib/utils/http'
import { Site } from '@models/api.models'

export async function getSite(token: string, siteId: string): Promise<Site> {
  const response = await get<Site>(`${siteUrl}/${siteId}`, token)
  if (!response.parsedBody) {
    throw new Error('getSite - response body empty')
  }

  return response.parsedBody
}

export async function updateSiteStatus(
  token: string,
  siteId: string,
  status: string
): Promise<Site> {
  const response = await put<Site>(
    `${siteUrl}/${siteId}/status`,
    { siteId, status },
    token
  )
  if (!response.parsedBody) {
    throw new Error('updateSiteStatus - response body empty')
  }

  return response.parsedBody
}
