import React from 'react'
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
  useTheme,
} from '@material-ui/core'
import useDashboardStyles from '@components/GlobalStyles'
import useConsents from '@providers/data/hooks/useConsent'

type GranularConsentsProps = {
  studyId: string
  siteId: string
}

export default function GranularConsents(props: GranularConsentsProps) {
  const theme = useTheme()
  const classes = useDashboardStyles(theme)
  const { consents } = useConsents(props)

  return (
    <Card style={{ width: '100%' }}>
      <CardHeader
        title="Granular Consents"
        classes={{
          root: classes.cardRoot,
          title: classes.cardTitle,
          action: classes.cardAction,
        }}
        color="textSecondary"
      />
      <Divider />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" color="textSecondary" align="justify">
              {consents.title}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" color="textPrimary" align="justify">
              {consents.body}
            </Typography>
          </Grid>
          {consents.consentList.map((consent) => (
            <Grid container item xs={12} key={consent.id}>
              <Typography variant="h5" color="textSecondary">
                {consent.label}
              </Typography>
              <Typography
                variant="body2"
                align="justify"
                style={{ marginTop: '20px' }}
              >
                {consent.subHeading}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  )
}
