import { useContext } from 'react'
import { BeepContext } from './beep.provider'

export default () => {
  const ctx = useContext(BeepContext)

  if (!ctx) {
    throw new Error('Beep hook cannot be used outside of Beep context')
  }

  const beep = ctx.beep

  return beep
}
