import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import logo from '@assets/images/dexcom-green-standalone.png'

const useStyles = makeStyles({
  button: {
    '&:hover': {
      backgroundColor: '#FFF',
    },
  },
})

const Logo = () => {
  const classes = useStyles()
  return (
    <Button
      key="logoButton"
      to="/"
      component={RouterLink}
      color="inherit"
      className={classes.button}
    >
      <img
        src={logo}
        alt="Dexcom Logo"
        style={{
          paddingTop: '1em',
          paddingBottom: '1em',
          maxHeight: '4em',
        }}
      />
    </Button>
  )
}

export default Logo
