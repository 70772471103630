import { useCallback } from 'react'
import { useKeycloak } from '@providers/auth'
import {
  IDefaultEventRule,
  IEventRule,
  IInstanceConfig,
  TermsCreateBySiteParams,
  TermsUpdateParams,
} from '@models/index'
import {
  inactivateParticipant,
  reactivateParticipant,
  acknowledgeAlarm,
  dischargeParticipant,
  submitComment,
  saveInstanceConfig,
  updateMetadata,
  saveEventRule,
  insertEventRule,
  saveSiteConfig,
} from '@lib/fetch-requests'

import * as DashAPI from '@lib/fetch-requests'

import useParticipantMap from './useParticipants'
import useToast from './useToast'
import useAlarm from './useAlarm'
import { SiteConfig } from '@models/api.models'

export default () => {
  const { token } = useKeycloak()
  const { participantData: participants } = useParticipantMap()
  const { createToast } = useToast()
  const { setAcknowledgement } = useAlarm()

  const inactivate = useCallback(
    (participantId: string) => {
      inactivateParticipant(token, participantId)
    },
    [token]
  )

  const reactivate = useCallback(
    (participantId: string) => {
      reactivateParticipant(token, participantId)
    },
    [token]
  )

  const discharge = useCallback(
    (participantId: string) => {
      dischargeParticipant(token, participantId)
    },
    [token]
  )

  const acknowledge = useCallback(
    (participantId: string) => {
      const participant = participants[participantId]
      if (participant && participant.alarms.length > 0) {
        const alarm = participant.alarms[0]
        acknowledgeAlarm(token, participantId, alarm.id).then((ack) => {
          setAcknowledgement(ack)
        })
      }
    },
    [token, participants, setAcknowledgement]
  )

  const updateInstanceConfig = useCallback(
    (instanceConfig: IInstanceConfig) => {
      return saveInstanceConfig(token, instanceConfig)
    },
    [token]
  )

  const updateSiteConfig = useCallback(
    (siteConfig: SiteConfig) => {
      return saveSiteConfig(token, siteConfig)
    },
    [token]
  )

  const addComment = useCallback(
    (participantId: string, comment: string) =>
      submitComment(participantId, comment, token)
        .then(() => {
          createToast('Successfully added comment')
        })
        .catch((e) => {
          console.log(e)
          createToast('Error - Could not add comment', 'error')
        }),
    [token, createToast]
  )

  const updateMetadataValue = useCallback(
    (participantId: string, value: string) =>
      updateMetadata(participantId, value, token).catch((e) => {
        console.log(e)
        createToast('Error - Could not update metadata', 'error')
      }),
    [token, createToast]
  )

  const updateEventRule = useCallback(
    (eventRule: IEventRule) => {
      return saveEventRule(token, eventRule)
    },
    [token]
  )

  const createEventRule = useCallback(
    (eventRule: IDefaultEventRule) => {
      return insertEventRule(token, eventRule)
    },
    [token]
  )
  const getConsents = useCallback(
    (studyId: string, siteId: string) => {
      return DashAPI.getConsents(token, studyId, siteId)
    },
    [token]
  )
  const getTermsBySiteId = useCallback(
    (siteId: string) => {
      return DashAPI.getTermsBySiteId(token, siteId)
    },
    [token]
  )

  const updateTerms = useCallback(
    (terms: TermsUpdateParams) => {
      return DashAPI.updateTerms(token, terms)
    },
    [token]
  )

  const createSiteTerms = useCallback(
    (terms: TermsCreateBySiteParams) => {
      return DashAPI.createSiteTerms(token, terms)
    },
    [token]
  )

  const getInstanceRoles = useCallback(
    (dashboardType: string) => {
      return DashAPI.getInstanceRoles(token, dashboardType)
    },
    [token]
  )

  return {
    inactivate,
    reactivate,
    acknowledge,
    discharge,
    addComment,
    updateInstanceConfig,
    updateSiteConfig,
    updateMetadataValue,
    updateEventRule,
    createEventRule,
    getTermsBySiteId,
    createSiteTerms,
    updateTerms,
    getInstanceRoles,
    getConsents,
  }
}
