import { useCallback } from 'react'
import { Socket } from 'socket.io-client'
import useInstanceConfig from '@providers/data/hooks/useInstanceConfig'
import useWebsocket from './Websocket'

const IO_NAME = '/instance'
export default () => {
    const { setInstanceConfig } = useInstanceConfig()
    const register = useCallback((socket: Socket) => {
        socket.on('SITE_CONFIG', setInstanceConfig)
    }, [setInstanceConfig])
    useWebsocket(IO_NAME, register)
}
