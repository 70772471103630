import React from 'react'
import { NavLink } from 'react-router-dom'
import AccessCodeDialog from '@components/AccessCode/AccessCodeDialog'
import ContextMenu from '@components/ContextMenu/ContextMenu'
import Logo from '@components/Logo/Logo'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Toolbar from '@material-ui/core/Toolbar'
import BallotIcon from '@material-ui/icons/Ballot'
import { Theme, useTheme } from '@material-ui/core/styles'
import Link from '@material-ui/core/Link'
import { useKeycloak } from '@providers/auth'

type StudyMenuProps = {
  theme: Theme
}

const StudyMenu = ({ theme }: StudyMenuProps) => (
  <Toolbar
    disableGutters
    style={{
      color: theme.oppositePalette.colorInverted,
    }}
  >
    <Button
      key="studiesListView"
      to="/"
      component={NavLink}
      activeStyle={{ color: theme.palette.primary.main }}
      color="inherit"
      style={{
        fontSize: '14px',
      }}
    >
      <BallotIcon></BallotIcon>
      Studies
    </Button>
  </Toolbar>
)

export default function VoyagerHeader(): JSX.Element {
  const theme = useTheme()
  const { parsed } = useKeycloak()

  return (
    <React.Fragment>
      <Container maxWidth="xl">
        <Grid container justifyContent="flex-end" alignItems="center">
          {parsed.email.endsWith('@dexcom.com') && (
            <Link href="/contactus" variant="body2">
              Contact Us
            </Link>
          )}
        </Grid>
      </Container>
      <Paper
        elevation={3}
        style={{
          marginBottom: 10,
          width: '100%',
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <Container maxWidth="xl">
          <Grid container alignItems="center" justifyContent="space-between">
            <Logo />
            <ContextMenu />
          </Grid>
          <Grid container alignItems="center" justifyContent="space-between">
            <StudyMenu theme={theme} />
          </Grid>
        </Container>
      </Paper>
      <AccessCodeDialog />
    </React.Fragment>
  )
}
