import { CUSTOM_SORT_OPTIONS } from "@models/sortOptions.models";
import * as Actions from "../actions";
import { useGlobalState } from "./useGlobalState";

interface CustomSortData {
  sortBy: CUSTOM_SORT_OPTIONS,
  setCustomSort: (sortBy: CUSTOM_SORT_OPTIONS) => void
}

const useCustomSort = (): CustomSortData => {
  const { state, dispatch } = useGlobalState()

  const setCustomSort = (customSort: CUSTOM_SORT_OPTIONS) => {
    dispatch(Actions.setSortBy(customSort))
  }

  return {
    sortBy: state.sortBy,
    setCustomSort
  }

}

export default useCustomSort
