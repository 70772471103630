import { siteUrl } from '@config/resourceUrls'
import { get } from '@lib/utils/http'
import { ExportSubjectResponse } from '@models/api.models'

export const pingSubject = async (
  token: string,
  siteId: string,
  subjectId: string
): Promise<ExportSubjectResponse> => {
  const resp = await get<ExportSubjectResponse>(
    `${siteUrl}/${siteId}/subject/${subjectId}/ping`,
    token
  )
  if (!resp.parsedBody) {
    throw new Error('Expected response body, got undefined')
  }
  return resp.parsedBody
}
