import React, { SyntheticEvent, useMemo } from 'react'
import { Tooltip, useTheme } from '@material-ui/core'
import {
  DataGrid,
  GridCellParams,
  GridColumns,
  GridRowParams,
  GridValueGetterParams,
  MuiEvent,
} from '@material-ui/data-grid'
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined'
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined'

import { StudyData } from '@models/data.models'

import ActionMenu from '../ActionMenu/ActionMenu'
import { StatusEnum, statusStyle } from './models'
import { EmptyInfoContainer } from '@components/EmptyInfoContainer'

interface StudiesListViewProps {
  studies: Array<StudyData>
  onStudyClick: (id: string) => void
}

const studyStatusTitleText = (status: StatusEnum) => {
  switch (status) {
    case StatusEnum.CREATED:
      return 'Study needs to be customized'
    case StatusEnum.ERROR:
      return 'Study has errors'
    default:
      return `Study status ${status}`
  }
}

export const StudiesListView: React.FunctionComponent<StudiesListViewProps> = ({
  studies,
  onStudyClick,
}) => {
  const theme = useTheme()

  const columnsDefinition = useMemo(() => {
    const columns: GridColumns = [
      {
        field: 'status',
        headerName: ' ',
        align: 'center',
        flex: 0.2,
        sortable: false,
        filterable: false,
        hideSortIcons: true,
        renderCell: (params: GridCellParams) => {
          const parsedStudyStatus = params.value as string
          const castedStudyStatus =
            parsedStudyStatus.toLowerCase() as StatusEnum

          switch (castedStudyStatus) {
            case StatusEnum.CREATED:
              return (
                <Tooltip
                  title={studyStatusTitleText(castedStudyStatus)}
                  placement={'top-start'}
                >
                  <ReportProblemOutlinedIcon
                    style={{
                      color: statusStyle(castedStudyStatus, theme).color,
                    }}
                  />
                </Tooltip>
              )
            case StatusEnum.ERROR:
              return (
                <Tooltip
                  title={studyStatusTitleText(castedStudyStatus)}
                  placement={'top-start'}
                >
                  <HighlightOffOutlinedIcon
                    style={{
                      color: statusStyle(castedStudyStatus, theme).color,
                    }}
                  />
                </Tooltip>
              )
            default:
              return ' '
          }
        },
      },
      {
        field: 'protocolId',
        headerName: 'Protocol ID',
        flex: 0.6,
      },
      {
        field: 'name',
        headerName: 'Name',
        flex: 1,
      },
      {
        field: 'lead',
        headerName: 'Study Lead',
        flex: 1,
      },
      {
        field: 'studyType',
        headerName: 'Type',
        flex: 0.6,
      },
      {
        field: 'subjectCount',
        headerName: 'Subjects',
        flex: 0.5,
      },
      {
        field: 'siteCount',
        headerName: 'Sites',
        flex: 0.5,
      },
      {
        field: 'id',
        headerName: 'Actions',
        sortable: false,
        filterable: false,
        hideSortIcons: true,
        flex: 0.4,
        valueGetter: (params: GridValueGetterParams) => {
          const actions = params.getValue(params.id, 'actions') as string[]
          if (!actions) return []
          return actions
        },
        renderCell: (params: GridCellParams) => (
          <ActionMenu
            id={params.id as string}
            menuOptions={params.value as string[]}
          />
        ),
      },
    ]
    return columns
  }, [])

  const handleStudyClick = (
    params: GridRowParams,
    _event: MuiEvent<SyntheticEvent<Element, Event>>
  ) => {
    onStudyClick(params.id as string)
  }

  if (studies.length === 0) {
    return <EmptyInfoContainer infoText="No Studies" />
  }

  return (
    <DataGrid
      rows={studies}
      columns={columnsDefinition}
      hideFooter
      density="compact"
      autoHeight
      onRowClick={handleStudyClick}
    />
  )
}
