const ONE_HOUR_MILLISECONDS = 60 * 60 * 1000
enum TIME_RANGE {
  RANGE_72_HOURS = 72 * ONE_HOUR_MILLISECONDS,
  RANGE_24_HOURS = 24 * ONE_HOUR_MILLISECONDS,
  RANGE_12_HOURS = 12 * ONE_HOUR_MILLISECONDS,
  RANGE_6_HOURS = 6 * ONE_HOUR_MILLISECONDS,
  RANGE_3_HOURS = 3 * ONE_HOUR_MILLISECONDS,
}

export default TIME_RANGE
