import { IEventRule } from "@models/index";
import { Action, ActionTypes } from '../actions'

export type EventRuleState = {
  [id: string]: IEventRule
}

export default (state: EventRuleState, action: Action): EventRuleState => {
  switch (action.type) {
    case ActionTypes.ALL_EVENT_RULES:
      return action.payload.reduce((acc, er) => {
        acc[er.id] = er
        return acc
      }, {} as EventRuleState)
    case ActionTypes.UPDATE_EVENT_RULE:
      return {...state, [action.payload.id]: action.payload}
    case ActionTypes.DELETE_EVENT_RULE: {
      delete state[action.payload]
      return {...state}
    }
    default:
      return state
  }
}