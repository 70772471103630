import { API_URL } from '@config/resourceUrls'
import { get, post } from '@lib/utils/http'
import { Study, NewStudy, Site, CreateStudyResponse } from '@models/api.models'

export async function getStudies(token: string): Promise<Study[]> {
  const response = await get<Study[]>(`${API_URL}/study/all`, token)
  if (!response.parsedBody) {
    throw new Error('fetchStudies - response body empty')
  }

  return response.parsedBody
}

export async function getStudy(studyId: string, token: string): Promise<Study> {
  const response = await get<Study>(`${API_URL}/study/${studyId}`, token)
  if (!response.parsedBody) {
    throw new Error('getStudy - response body empty')
  }

  return response.parsedBody
}

export async function postStudy(
  study: NewStudy,
  token: string
): Promise<CreateStudyResponse> {
  const response = await post<CreateStudyResponse>(
    `${API_URL}/study`,
    study,
    token
  )
  if (!response.parsedBody) {
    throw new Error('postStudy - response body empty')
  }

  return response.parsedBody
}

export async function getSites(
  studyId: string,
  token: string
): Promise<Site[]> {
  const response = await get<Site[]>(
    `${API_URL}/study/${studyId}/site/all`,
    token
  )
  if (!response.parsedBody) {
    throw new Error('getSites - response body empty')
  }

  return response.parsedBody
}
