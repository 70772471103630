import React from 'react'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import Divider from '@material-ui/core/Divider'
import TextField from '@material-ui/core/TextField'
import CardContent from '@material-ui/core/CardContent'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import InputAdornment from '@material-ui/core/InputAdornment'
import { Grid, useTheme } from '@material-ui/core'
import useDashboardStyles from '@components/GlobalStyles'
import { ClientConfig, ClientConfigErrors } from '@models/clientConfig.models'

type ClientConfigCardViewProps = {
  values: ClientConfig
  errors: ClientConfigErrors
  canEdit: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onSwitchChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export default function ClientConfigCardView(props: ClientConfigCardViewProps) {
  const theme = useTheme()
  const classes = useDashboardStyles(theme)
  const { values, errors } = props

  return (
    <Card>
      <CardHeader
        title="App Settings"
        classes={{
          root: classes.cardRoot,
          title: classes.cardTitle,
          action: classes.cardAction,
        }}
        color="textSecondary"
      />
      <Divider />
      <CardContent>
        <Grid container item xs spacing={2}>
          <Grid item xs={12}>
            <TextField
              id="sensorTypeSettings"
              label="Sensor Type"
              size="small"
              variant="outlined"
              type="string"
              style={{ width: '100%' }}
              disabled={true}
              value="G6"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  id="isStudyBlinded"
                  name="isStudyBlinded"
                  color="primary"
                  disabled={!props.canEdit}
                  checked={values.isStudyBlinded}
                  onChange={props.onSwitchChange}
                />
              }
              label={values.isStudyBlinded ? 'Blinded Mode' : 'Unblinded Mode'}
              labelPlacement="end"
              title="Click to enable/disable blinded mode."
            />
          </Grid>
          {!values.isStudyBlinded && (
            <React.Fragment>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      id="isHighAlertsEnabled"
                      name="isHighAlertsEnabled"
                      checked={values.isHighAlertsEnabled}
                      onChange={props.onSwitchChange}
                      color="primary"
                      disabled={!props.canEdit}
                    />
                  }
                  label={
                    values.isHighAlertsEnabled
                      ? 'High Alerts Enabled'
                      : 'High Alerts Disabled'
                  }
                  labelPlacement="end"
                  title="Click to enable/disable high alerts"
                />
              </Grid>
              {values.isHighAlertsEnabled && (
                <React.Fragment>
                  <Grid item xs={9}>
                    <TextField
                      id="highAlertThreshold"
                      label="High Threshold"
                      size="small"
                      variant="outlined"
                      type="number"
                      style={{ width: '100%' }}
                      disabled={!props.canEdit}
                      value={values.highAlertThreshold}
                      error={errors.highAlertThreshold !== ''}
                      helperText={errors.highAlertThreshold}
                      onChange={props.onChange}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            mg/dL
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FormControlLabel
                      control={
                        <Switch
                          id="isHighAlertsAudible"
                          name="isHighAlertsAudible"
                          checked={values.isHighAlertsAudible}
                          onChange={props.onSwitchChange}
                          color="primary"
                          disabled={!props.canEdit}
                        />
                      }
                      label={values.isHighAlertsAudible ? 'Audible' : 'Muted'}
                      labelPlacement="end"
                      title="Click to enable/disable audible high alerts"
                    />
                  </Grid>
                </React.Fragment>
              )}
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      id="isLowAlertsEnabled"
                      name="isLowAlertsEnabled"
                      checked={values.isLowAlertsEnabled}
                      onChange={props.onSwitchChange}
                      color="primary"
                      disabled={!props.canEdit}
                    />
                  }
                  label={
                    values.isLowAlertsEnabled
                      ? 'Low Alerts Enabled'
                      : 'Low Alerts Disabled'
                  }
                  labelPlacement="end"
                  title="Click to enable/disable low alerts"
                />
              </Grid>
              {values.isLowAlertsEnabled && (
                <React.Fragment>
                  <Grid item xs={9}>
                    <TextField
                      id="lowAlertThreshold"
                      label="Low Threshold"
                      size="small"
                      variant="outlined"
                      type="number"
                      style={{ width: '100%' }}
                      disabled={!props.canEdit}
                      value={values.lowAlertThreshold}
                      error={errors.lowAlertThreshold !== ''}
                      helperText={errors.lowAlertThreshold}
                      onChange={props.onChange}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            mg/dL
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <FormControlLabel
                      control={
                        <Switch
                          id="isLowAlertsAudible"
                          name="isLowAlertsAudible"
                          checked={values.isLowAlertsAudible}
                          onChange={props.onSwitchChange}
                          color="primary"
                          disabled={!props.canEdit}
                        />
                      }
                      label={values.isLowAlertsAudible ? 'Audible' : 'Muted'}
                      labelPlacement="end"
                      title="Click to enable/disable audible low alerts"
                    />
                  </Grid>
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </Grid>
      </CardContent>
    </Card>
  )
}
