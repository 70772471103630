/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";

export interface IAuthClientError {
  error: string;
  error_description: string;
}

export interface IAuthClientInitOptions {
  [key: string]: any;
}

export interface IAuthLoginOptions {
  [key: string]: any;
}

export interface IAuthTokenParsed {
  [key: string]: any;
}

export interface IUserProfile {
  [key: string]: any;
}

export interface IAuthClient {
  token?: string;
  refreshToken?: string;
  idToken?: string;
  tokenParsed?: IAuthTokenParsed;
  onReady?(authenticated?: boolean): void;
  onAuthSuccess?(): void;
  onAuthError?(error: IAuthClientError): void;
  onAuthRefreshSuccess?(): void;
  onAuthRefreshError?(error: IAuthClientError): void;
  onAuthLogout?(): void;
  onTokenExpired?(): void;

  /**
   * Called to initialize the adapter
   * @param initOptions Initialization Options
   * @returns A promise containing a boolean for success or failure
   */
  init(initOptions: IAuthClientInitOptions): Promise<boolean>;

  login(options?: IAuthLoginOptions): Promise<void>;
  logout(options?: any): Promise<void>;

  /**
   * Token is refreshed if expiring within minValidity.
   * Checks session status if session iframe is enabled.
   * @param minValidity seconds the token expires within
   */
  updateToken(minValidity: number): Promise<boolean>;

  loadUserProfile(): Promise<IUserProfile>;
}

export type AuthClientTokens = Pick<
  IAuthClient,
  "idToken" | "refreshToken" | "token"
>;
export type AuthClientEvent =
  | "onReady"
  | "onInitError"
  | "onAuthSuccess"
  | "onAuthError"
  | "onAuthRefreshSuccess"
  | "onAuthRefreshError"
  | "onAuthLogout"
  | "onTokenExpired";

export interface IAuthContextProps<T extends IAuthClient> {
  authClient?: T;
  initialized: boolean;
}

export const createAuthContext = <T extends IAuthClient>(
  initialContext?: Partial<IAuthContextProps<T>>
): React.Context<IAuthContextProps<T>> => {
  return React.createContext({
    initialized: false,
    ...initialContext,
  });
};

export default createAuthContext;
