import { formatDate } from '@lib/utils/dates'
import { egvStatus, EGV_STATUS, menuOptions } from '@lib/egv'
import {
  TEN_DAYS_MILLISECONDS,
  TWENTY_FOUR_HOURS_MILLISECONDS,
} from '@lib/utils/constants'
import {
  EGVRecord,
  IAcknowledgement,
  IAlarm,
  IParticipantData,
  IParticipantRecord,
  ISession,
  IInstanceConfig,
} from '@models/index'
import { Action, ActionTypes } from '../actions'

export type ParticipantDataState = {
  [participantId: string]: IParticipantData
}

export default (
  state: ParticipantDataState,
  siteConfig: IInstanceConfig | undefined,
  acknowledgements: IAcknowledgement[],
  action: Action
): ParticipantDataState => {
  switch (action.type) {
    case ActionTypes.TRIGGER_ALARM_ON: {
      const newState = { ...state }
      newState[action.payload] = {
        ...newState[action.payload],
        alarmTrigger: true,
      }
      return newState
    }
    case ActionTypes.TRIGGER_ALARM_OFF: {
      const newState = { ...state }
      newState[action.payload] = {
        ...newState[action.payload],
        alarmTrigger: false,
        lastAlarmTrigger: Date.now(),
      }
      return newState
    }
    case ActionTypes.TICK: {
      const newState = { ...state }
      Object.keys(state).forEach((participantId) => {
        newState[participantId] = updateReceived(
          state[participantId],
          action.payload,
          siteConfig
        )
      })
      return newState
    }
    case ActionTypes.ALARM_UPDATE: {
      const newState = { ...state }
      Object.keys(action.payload).forEach((participantId) => {
        newState[participantId] = updateAlarms(
          state[participantId],
          action.payload[participantId],
          acknowledgements,
          siteConfig
        )
      })
      return newState
    }
    case ActionTypes.PARTICIPANT_UPDATE: {
      const newState = { ...state }
      action.payload.forEach((participantRecord) => {
        const updated = updateParticipant(
          state[participantRecord.id],
          participantRecord,
          siteConfig,
          acknowledgements
        )

        if (!updated) {
          delete newState[participantRecord.id]
        } else {
          newState[participantRecord.id] = updated
        }
      })
      return newState
    }
    case ActionTypes.ALL_EGVS: {
      const newState = { ...state }
      Object.keys(action.payload).forEach((participantId) => {
        newState[participantId] = egvsReducer(
          state[participantId],
          action.payload[participantId],
          siteConfig,
          true
        )
      })
      return newState
    }
    case ActionTypes.NEW_EGVS: {
      const newState = { ...state }
      Object.keys(action.payload).forEach((participantId) => {
        newState[participantId] = egvsReducer(
          state[participantId],
          action.payload[participantId],
          siteConfig
        )
      })
      return newState
    }
    case ActionTypes.INSTANCE_CONFIG_UPDATE: {
      const newState = { ...state }
      Object.keys(state).forEach((participantId) => {
        newState[participantId] = configChange(
          state[participantId],
          action.payload
        )
      })
      return newState
    }
    case ActionTypes.ALL_ACKNOWLEDGEMENTS: {
      const newState = { ...state }
      action.payload.forEach((ack) => {
        newState[ack.participantId] = ackReducer(
          newState[ack.participantId],
          ack,
          siteConfig
        )
      })
      return newState
    }
    case ActionTypes.NEW_ACKNOWLEDGEMENT: {
      const newState = { ...state }
      newState[action.payload.participantId] = ackReducer(
        newState[action.payload.participantId],
        action.payload,
        siteConfig
      )
      return newState
    }
    default:
      return state
  }
}

const ackReducer = (
  participant: IParticipantData | undefined,
  acknowledgement: IAcknowledgement,
  siteConfig: IInstanceConfig | undefined
): IParticipantData => {
  if (!participant || !siteConfig) {
    participant = emptyParticipant()
  } else {
    participant = { ...participant }
  }

  participant.alarm = setAlarm(participant.status, participant.alarms, [
    acknowledgement,
  ])

  participant.actions = menuOptions(
    participant.status,
    participant.alarm,
    siteConfig?.is_voyager || false
  )

  return participant
}

const updateAlarms = (
  participant: IParticipantData,
  alarms: IAlarm[],
  acknowledgements: IAcknowledgement[],
  siteConfig: IInstanceConfig | undefined
): IParticipantData => {
  if (!participant) {
    participant = emptyParticipant()
  } else {
    participant = { ...participant }
  }

  participant.alarms = alarms
  participant.alarm = setAlarm(
    participant.status,
    participant.alarms,
    acknowledgements
  )

  if (participant.status !== '') {
    participant.actions = menuOptions(
      participant.status,
      participant.alarm,
      siteConfig?.is_voyager || false
    )
  }

  return participant
}

const updateReceived = (
  participant: IParticipantData,
  timestamp: number,
  siteConfig: IInstanceConfig | undefined
): IParticipantData => {
  if (participant.allEgvs.length > 0) {
    const latestEgv = participant.allEgvs[participant.allEgvs.length - 1]
    participant = { ...participant }
    participant.received = timeSinceLatestEgv(
      latestEgv.systemtime.getTime(),
      timestamp
    )

    if (siteConfig) {
      participant.status = setStatus(participant.status, siteConfig, latestEgv)
    }
  }

  return participant
}

const configChange = (
  participant: IParticipantData,
  siteConfig: IInstanceConfig
): IParticipantData => {
  const latestEgv =
    participant.allEgvs.length > 0
      ? participant.allEgvs[participant.allEgvs.length - 1]
      : undefined
  participant = {
    ...participant,
    status: setStatus(participant.status, siteConfig, latestEgv),
  }

  if (participant.allEgvs.length > 0) {
    const timeInRange = calculateTimeInRange(participant.allEgvs, siteConfig)
    participant.timeInRange = timeInRange.timeInRange
    participant.timeHyper = timeInRange.timeHyper
    participant.timeHypo = timeInRange.timeHypo
    participant.percentTimeInRange = timeInRange.percentTimeInRange
    participant.percentTimeOutOfRange = timeInRange.percentTimeOutOfRange
  }

  if (participant.status !== '') {
    participant.actions = menuOptions(
      participant.status,
      participant.alarm,
      siteConfig.is_voyager
    )
  }

  return participant
}

const updateParticipant = (
  participant: IParticipantData | undefined,
  participantRecord: IParticipantRecord,
  siteConfig: IInstanceConfig | undefined,
  acknowledgements: IAcknowledgement[]
): IParticipantData | undefined => {
  if (!participant || !siteConfig) {
    participant = emptyParticipant()
  } else {
    participant = { ...participant }
  }

  if (participantRecord.status === 'discharged') {
    return undefined
  }

  participant.id = participantRecord.id
  participant.unit_id = participantRecord.unit_id || ''
  participant.sessionEnd = displaySessionEnd(participantRecord.sessions || [])
  participant.metadata = participantRecord.metadata || []
  participant.condition = participantRecord.condition
  participant.events = (participantRecord.events || []).map((e) => ({
    ...e,
    startdate: new Date(e.startdate),
    enddate: new Date(e.enddate),
  }))
  participant.comments = (participantRecord.comments || [])
    .map((c) => ({ ...c, date: new Date(c.date) }))
    .sort((a, b) => b.date.getTime() - a.date.getTime())
  participant.endDate = new Date(participantRecord.enddate).getTime()
  participant.numberOfHyperEvents =
    participant.events.length !== 0
      ? participant.events.filter((event) => event.type === 'high').length
      : 0
  participant.numberOfHypoEvents =
    participant.events.length !== 0
      ? participant.events.filter((event) => event.type === 'low').length
      : 0

  const latestEgv =
    participant.allEgvs.length > 0
      ? participant.allEgvs[participant.allEgvs.length - 1]
      : undefined

  if (siteConfig) {
    participant.status = setStatus(
      participantRecord.status,
      siteConfig,
      latestEgv
    )
  }

  if (participant.status !== '') {
    participant.actions = menuOptions(
      participant.status,
      participant.alarm,
      siteConfig?.is_voyager || false
    )
  }

  participant.alarm = setAlarm(
    participant.status,
    participant.alarms,
    acknowledgements
  )

  return participant
}

const egvsReducer = (
  participant: IParticipantData | undefined,
  egvs: EGVRecord[],
  siteConfig: IInstanceConfig | undefined,
  replaceEgvs?: boolean
): IParticipantData => {
  if (!participant) {
    participant = emptyParticipant()
  } else {
    participant = { ...participant }
  }

  egvs = egvs.map((egv) => ({
    ...egv,
    systemtime: new Date(egv.systemtime),
    displaytime: new Date(egv.displaytime),
  }))

  if (replaceEgvs) {
    participant.allEgvs = [...egvs]
  } else {
    participant.allEgvs = [...participant.allEgvs, ...egvs]
  }

  // make sure egvs are sorted oldest to newest
  participant.allEgvs.sort(
    (a, b) => a.systemtime.getTime() - b.systemtime.getTime()
  )

  const latestEgv =
    participant.allEgvs.length > 0
      ? participant.allEgvs[participant.allEgvs.length - 1]
      : undefined

  participant.latestEgv = latestEgv ? latestEgv.value : 0
  participant.trend = latestEgv ? latestEgv.trend : 'unknown'
  participant.received = latestEgv
    ? timeSinceLatestEgv(latestEgv.systemtime.getTime(), Date.now())
    : '--'

  if (siteConfig) {
    participant.status = setStatus(participant.status, siteConfig, latestEgv)
    const timeInRange = calculateTimeInRange(participant.allEgvs, siteConfig)
    participant.timeInRange = timeInRange.timeInRange
    participant.timeHyper = timeInRange.timeHyper
    participant.timeHypo = timeInRange.timeHypo
    participant.percentTimeInRange = timeInRange.percentTimeInRange
    participant.percentTimeOutOfRange = timeInRange.percentTimeOutOfRange
  }

  if (participant.status !== '') {
    participant.actions = menuOptions(
      participant.status,
      participant.alarm,
      siteConfig?.is_voyager || false
    )
  }

  return participant
}

function emptyParticipant(): IParticipantData {
  return {
    id: '',
    status: '',
    sessionEnd: '',
    latestEgv: 0,
    trend: '',
    received: '--',
    allEgvs: [],
    alarms: [],
    metadata: ['', '', ''],
    condition: 'N/A',
    percentTimeInRange: 0,
    percentTimeOutOfRange: 0,
    timeInRange: 0,
    timeHyper: 0,
    timeHypo: 0,
    events: [],
    numberOfHyperEvents: 0,
    numberOfHypoEvents: 0,
    endDate: 0,
    actions: [],
    alarm: false,
    alarmTrigger: false,
    lastAlarmTrigger: 0,
    comments: [],
    unit_id: '',
  }
}

function calculateTimeInRange(egvs: EGVRecord[], config: IInstanceConfig) {
  const last24Hours = egvs.filter(
    (egv) =>
      egv.systemtime > new Date(Date.now() - TWENTY_FOUR_HOURS_MILLISECONDS)
  )
  const lowEgvs = last24Hours.filter(
    (egv) => egv.value < config.loweventthreshold
  )
  const highEgvs = last24Hours.filter(
    (egv) => egv.value > config.higheventthreshold
  )
  const percentInRange =
    (last24Hours.length - lowEgvs.length - highEgvs.length) / last24Hours.length
  const percentOutOfRange =
    (lowEgvs.length + highEgvs.length) / last24Hours.length

  return {
    timeInRange: (last24Hours.length - lowEgvs.length - highEgvs.length) * 5, // minutes
    timeHypo: lowEgvs.length * 5,
    timeHyper: highEgvs.length * 5,
    percentTimeInRange:
      Math.round((percentInRange + Number.EPSILON) * 100) / 100,
    percentTimeOutOfRange:
      Math.round((percentOutOfRange + Number.EPSILON) * 100) / 100,
  }
}

const displaySessionEnd = (sessions: ISession[]): string => {
  if (!sessions[0] || sessions.length > 1) {
    return 'Expired'
  }

  const sessionEnd = new Date(
    new Date(sessions[0].startdate).getTime() + TEN_DAYS_MILLISECONDS
  )
  return formatDate(sessionEnd)
}

const timeSinceLatestEgv = (
  latestEgvTimestamp: number,
  currentTimestamp: number
): string => {
  const timeSpanTicks = currentTimestamp - latestEgvTimestamp
  if (timeSpanTicks < 0) {
    return '-- ago'
  }

  const oneMinuteMilli = 1000 * 60
  const timeSpanMinutes = Math.floor(timeSpanTicks / oneMinuteMilli)
  if (timeSpanMinutes < 1) {
    return '<1m ago'
  }
  if (timeSpanMinutes < 10) {
    return `${timeSpanMinutes}m ago`
  }
  if (timeSpanMinutes < 60) {
    return `No Data (${timeSpanMinutes}m)`
  }

  const timeSpanHours = Math.floor(timeSpanMinutes / 60)
  if (timeSpanHours < 24) {
    return `No Data (${timeSpanHours}h)`
  }
  const timeSpanDays = Math.floor(timeSpanHours / 24)
  return `No Data (${timeSpanDays}d)`
}

function setAlarm(
  status: string,
  alarms: IAlarm[],
  acknowledgements: IAcknowledgement[]
): boolean {
  if (status === EGV_STATUS.INACTIVE) {
    return false
  }
  if (alarms.length > 0) {
    let shouldAlarm = false
    alarms.forEach((alarm) => {
      const idx = acknowledgements.findIndex((ack) => ack.alarmId === alarm.id)
      if (idx < 0) {
        shouldAlarm = true
      }
    })

    return shouldAlarm
  }

  return false
}

// Calculates the participant status
// recordStatus is the current status of the participant or the status on the participant data record.
function setStatus(
  recordStatus: string,
  siteConfig: IInstanceConfig,
  latestEgv: EGVRecord | undefined
): string {
  if (recordStatus === '') {
    return ''
  } else if (recordStatus.toLowerCase() === 'inactive') {
    return EGV_STATUS.INACTIVE
  }

  return egvStatus(
    latestEgv,
    Date.now(),
    siteConfig.lowalertthreshold,
    siteConfig.highalertthreshold
  )
}
