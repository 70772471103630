import { validateId } from '@lib/study/validate'
import {
  required,
  validateOption,
  validateEmail,
  validateLength,
} from '@lib/validate'

export function validateSiteLabel(
  siteLabel: string,
  existingLabels: string[]
): string {
  let error = required(siteLabel)

  if (!error) {
    error = validateId(siteLabel)
  }

  if (!error) {
    if (existingLabels.includes(siteLabel)) {
      error = 'Site Id already in use'
    }
  }

  return error
}

export function validateSiteCountry(country: string): string {
  let error = required(country)

  if (!error) {
    error = validateOption(['United States', 'United Kingdom'], country)
  }

  return error
}

export function validateSiteAdmin(siteAdmin: string): string {
  let error = required(siteAdmin)
  if (!error) {
    error = validateEmail(siteAdmin)
  }

  if (!error) {
    error = validateLength(320, siteAdmin)
  }

  return error
}
