import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'

import { KeycloakProvider } from '@providers/auth'
import keycloak from '@config/keycloak'
import ThemeProvider from '@providers/themes/ThemeProvider'
import Spinner from '@components/Spinner/Spinner'

import App from './App'
import reportWebVitals from './reportWebVitals'
import './index.css'

const LoadingElement = <Spinner />

const render = () => {
  const themedRouting = (
    <KeycloakProvider authClient={keycloak} LoadingComponent={LoadingElement}>
      <ThemeProvider>
        <React.StrictMode>
          <BrowserRouter>
            <App></App>
          </BrowserRouter>
        </React.StrictMode>
      </ThemeProvider>
    </KeycloakProvider>
  )

  ReactDOM.render(themedRouting, document.getElementById('root'))
}

render()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./App', render)
}
