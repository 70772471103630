import React from 'react'
import { KeycloakInstance } from 'keycloak-js'
import { Switch, Route, Redirect } from 'react-router-dom'
import Dashboard from '@components/Dashboard/Dashboard'
import Settings from '@components/Settings/Settings'
import FAQ from '@components/FAQ/FAQ'
import ExportData from '@components/ExportData/ExportData'
import ActivateParticipant from '@components/ParticipantAdd/ActivateParticipant'
import Participant from '@components/Participant/Participant'
import NotFoundPage from '@components/NotFound/NotFound'
import UsersHome from '@components/User/UsersHome'
import InstancesHome from '@components/Instance/InstancesHome'

import ProtectedRoute from './ProtectedRoute/ProtectedRoute'
import StudyHome from '@components/Study/StudyHome'
import StudyDetails from '@components/Study/StudyDetails'
import SiteDetails from '@components/Site/SiteDetails'
import SubjectDetails from '@components/Subject/SubjectDetails'
import ContactUs from '@components/ContactUs/ContactUs'
import { ParsedKeycloakToken } from '@providers/auth/useKeycloak'

type RouterProps = {
  keycloak: KeycloakInstance
  parsed?: ParsedKeycloakToken
}

export const HospitalRouter = ({ keycloak }: RouterProps) => (
  <Switch>
    <Route exact path="/" component={Dashboard} />
    <Route exact path="/exportdata" component={ExportData} />
    <Route path="/participant/add/callback" component={ActivateParticipant} />
    <Route path="/participant/:id" component={Participant} />
    <ProtectedRoute
      isAuthenticated={keycloak.hasRealmRole('dashboard-settings-management')}
      authenticationPath={'/404'}
      path="/settings"
      component={Settings}
    />
    <ProtectedRoute
      isAuthenticated={keycloak.hasRealmRole('dashboard-admin')}
      authenticationPath={'/404'}
      path="/users"
      component={UsersHome}
    />
    <ProtectedRoute
      isAuthenticated={keycloak.hasRealmRole('dashboard-engineer')}
      authenticationPath={'/404'}
      path="/instances"
      component={InstancesHome}
    />
    <Route path="/faq" component={FAQ} />
    <Route path="/404" component={NotFoundPage} />
    <Redirect to="/404" />
  </Switch>
)

export const StudyRouter = ({ keycloak, parsed }: RouterProps) => (
  <Switch>
    <Route exact path="/" component={StudyHome} />
    <Route exact path="/exportdata" component={ExportData} />
    <Route path="/participant/add/callback" component={ActivateParticipant} />
    <Route path="/participant/:id" component={Participant} />
    <Route exact path="/study/:studyId" component={StudyDetails} />
    <Route exact path="/study/:studyId/site/:siteId" component={SiteDetails} />
    <Route
      path="/study/:studyId/site/:siteId/subject/:subjectId"
      component={SubjectDetails}
    />

    <ProtectedRoute
      isAuthenticated={keycloak.hasRealmRole('dashboard-settings-management')}
      authenticationPath={'/404'}
      path="/settings"
      component={Settings}
    />
    <ProtectedRoute
      isAuthenticated={keycloak.hasRealmRole('dashboard-admin')}
      authenticationPath={'/404'}
      path="/users"
      component={UsersHome}
    />
    <ProtectedRoute
      isAuthenticated={keycloak.hasRealmRole('dashboard-engineer')}
      authenticationPath={'/404'}
      path="/instances"
      component={InstancesHome}
    />
    <ProtectedRoute
      isAuthenticated={parsed ? parsed.email.endsWith('@dexcom.com') : false}
      authenticationPath={'/404'}
      path={'/contactus'}
      component={ContactUs}
    />
    <Route path="/faq" component={FAQ} />
    <Route path="/404" component={NotFoundPage} />
    <Redirect to="/404" />
  </Switch>
)
