import { Theme } from '@material-ui/core'
import { NewStudy } from '@models/api.models'
import {
  required,
  validateContactInstructions,
  validateLength,
  validateOption,
} from '@lib/validate'
import {
  validateSiteAdmin,
  validateSiteCountry,
  validateSiteLabel,
} from '@lib/site/validate'
import {
  StaffErrors,
  validateDexcomStaff,
  validateStaffRoles,
} from '@models/staff.models'

export type SiteCreateErrors = {
  label: string
  country: string
  siteAdministrator: string
  contactInstructions: string
  staff: StaffErrors[]
}

export type StudyErrors = {
  type: string
  basicInfo: string
  name: string
  protocolId: string
  protocolTitle: string
  dexcomAdministrator: string
  siteInfo: string
  sites: SiteCreateErrors[]
}

export type StudyFormState = {
  values: NewStudy
  errors: StudyErrors
}

function validateStudyType(studyType: string): string {
  // required
  let error = required(studyType)
  if (!error) {
    error = validateOption(
      ['Dexcom-Sponsored', 'Dexcom External Research (IIS)'],
      studyType
    )
  }

  return error
}

function validateBasicInfo(
  name: string,
  protocolId: string,
  protocolTitle: string
): string {
  if (!name && !protocolId && !protocolTitle) {
    return 'Must specify at least Name, Protocol ID, or Protocol Title'
  }
  return ''
}

function validateStudyName(name: string): string {
  const error = validateLength(30, name)
  return error
}

function validateProtocolId(protocolId: string): string {
  const error = validateLength(15, protocolId)
  return error
}

function validateProtocolTitle(protocolTitle: string): string {
  const error = validateLength(300, protocolTitle)
  return error
}

export function validateStudyStep(
  activeStep: number,
  study: NewStudy
): [boolean, StudyErrors] {
  let valid = true
  const errors: StudyErrors = {
    type: '',
    basicInfo: '',
    name: '',
    protocolId: '',
    protocolTitle: '',
    dexcomAdministrator: '',
    siteInfo: '',
    sites: [],
  }

  if (activeStep === 0) {
    errors.type = validateStudyType(study.type)
    valid = valid && errors.type === ''

    errors.basicInfo = validateBasicInfo(
      study.name,
      study.protocolId,
      study.protocolTitle
    )
    valid = valid && errors.basicInfo === ''

    errors.name = validateStudyName(study.name)
    valid = valid && errors.name === ''

    errors.protocolId = validateProtocolId(study.protocolId)
    valid = valid && errors.protocolId === ''

    errors.protocolTitle = validateProtocolTitle(study.protocolTitle)
    valid = valid && errors.protocolTitle === ''
  } else if (activeStep === 1) {
    errors.siteInfo =
      study.sites.length === 0 ? 'Must specify at least one site' : ''
    valid = valid && errors.siteInfo === ''

    for (let i = 0; i < study.sites.length; i++) {
      const site = study.sites[i]
      const siteErrors: SiteCreateErrors = {
        label: '',
        country: '',
        siteAdministrator: '',
        contactInstructions: '',
        staff: [],
      }

      siteErrors.label = validateSiteLabel(
        site.label,
        study.sites.slice(0, i).map((site) => site.label)
      )
      valid = valid && siteErrors.label === ''

      siteErrors.country = validateSiteCountry(site.country)
      valid = valid && siteErrors.country === ''

      siteErrors.siteAdministrator = validateSiteAdmin(site.siteAdministrator)
      valid = valid && siteErrors.siteAdministrator === ''

      errors.sites.push(siteErrors)
    }
  } else if (activeStep === 2) {
    errors.dexcomAdministrator = validateDexcomStaff(study.dexcomAdministrator)
    valid = valid && errors.dexcomAdministrator === ''

    for (let i = 0; i < study.sites.length; i++) {
      const site = study.sites[i]
      const siteErrors: SiteCreateErrors = {
        label: '',
        country: '',
        siteAdministrator: '',
        contactInstructions: '',
        staff: [],
      }

      siteErrors.contactInstructions = validateContactInstructions(
        site.contactInstructions
      )
      valid = valid && siteErrors.contactInstructions === ''

      for (let j = 0; j < site.staff.length; j++) {
        const staff = site.staff[j]
        const staffErrors: StaffErrors = {
          email: '',
          roles: '',
        }

        staffErrors.email = validateDexcomStaff(staff.email)
        valid = valid && staffErrors.email === ''

        staffErrors.roles = validateStaffRoles(staff.roles)
        valid = valid && staffErrors.roles === ''

        siteErrors.staff.push(staffErrors)
      }

      errors.sites.push(siteErrors)
    }
  }
  return [valid, errors]
}

export enum StatusEnum {
  CREATED = 'created',
  READY = 'ready',
  IN_PROGRESS = 'in progress',
  CHECK_DATA = 'check data',
  COMPLETED = 'completed',
  ERROR = 'error',
}

export const statusMap = new Map<StatusEnum, number>()
statusMap.set(StatusEnum.CHECK_DATA, 1)
statusMap.set(StatusEnum.CHECK_DATA, 2)
statusMap.set(StatusEnum.CREATED, 3)
statusMap.set(StatusEnum.IN_PROGRESS, 4)
statusMap.set(StatusEnum.READY, 5)
statusMap.set(StatusEnum.COMPLETED, 6)

export const statusStyle = (status: StatusEnum, theme: Theme) => {
  switch (status.toLowerCase()) {
    case StatusEnum.CREATED:
      return {
        backgroundColor: theme.statusCreated.backgroundColor,
        color: theme.statusCreated.color,
      }
    case StatusEnum.READY:
      return {
        backgroundColor: theme.statusReady.backgroundColor,
        color: theme.statusReady.color,
      }
    case StatusEnum.IN_PROGRESS:
      return {
        backgroundColor: theme.statusInProgress.backgroundColor,
        color: theme.statusInProgress.color,
      }
    case StatusEnum.CHECK_DATA:
      return {
        backgroundColor: theme.statusCheckData.backgroundColor,
        color: theme.statusCheckData.color,
      }
    case StatusEnum.COMPLETED:
      return {
        backgroundColor: theme.statusCompleted.backgroundColor,
        color: theme.statusCompleted.color,
      }
    case StatusEnum.ERROR:
      return {
        backgroundColor: theme.statusCheckData.backgroundColor,
        color: theme.statusCheckData.color,
      }
    default:
      return {
        color: theme.palette.warning.main,
      }
  }
}

export const subjectStatusStyle = (status: string, theme: Theme) => {
  switch (status.toLowerCase()) {
    case 'active':
      return {
        backgroundColor: theme.statusInProgress.backgroundColor,
        color: theme.statusInProgress.color,
      }
    case 'archived':
      return {
        color: theme.palette.info.main,
      }
    case 'inactive':
      return {
        backgroundColor: theme.statusCompleted.backgroundColor,
        color: theme.statusCompleted.color,
      }
    case 'pending':
    case 'created':
      return {
        backgroundColor: theme.statusReady.backgroundColor,
        color: theme.statusReady.color,
      }
    default:
      return {
        color: theme.palette.info.main,
      }
  }
}

export const metricsStatusStyle = (status: string) => {
  switch (status) {
    case 'active':
      return {
        backgroundColor: 'mintcream',
        color: '#fff',
      }
    case 'paused':
      return {
        backgroundColor: 'lightyellow',
        color: '#fff',
      }
    case 'broken':
      return {
        backgroundColor: 'lightcoral',
        color: '#fff',
      }
    default:
      return {
        backgroundColor: 'lightgray',
        color: '#fff',
      }
  }
}

export const loremIpsum = `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`
