import { IInstanceConfig } from '@models/index'
import { useCallback } from 'react'
import { instanceConfigUpdateAction } from '../actions'
import { useGlobalState } from './useGlobalState'

const useInstanceConfig = () => {
  const { state, dispatch } = useGlobalState()

  const setInstanceConfig = useCallback((config: IInstanceConfig) => {
    dispatch(instanceConfigUpdateAction(config))
  }, [dispatch])

  return {
    instanceConfig: state.config.instanceConfig,
    setInstanceConfig
  }
}

export default useInstanceConfig
