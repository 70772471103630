import React from 'react'
import { Grid, Typography } from '@material-ui/core'

const NotFoundPage: React.FC = () => (
  <Grid
    container
    data-testid="NotFoundPage"
    justifyContent="center"
    style={{
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      textAlign: 'center',
    }}
  >
    <Grid item xs={12}>
      <Typography variant="h1" display="inline">
        4
        <Typography variant="h1" color="primary" display="inline">
          0
        </Typography>
        4
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <Typography variant="h4">Page Not Found</Typography>
    </Grid>
  </Grid>
)

export default NotFoundPage
