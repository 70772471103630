export type ClientConfig = {
  isStudyBlinded: boolean
  isMealPhotoAllowed: boolean
  isUnitsMmol: boolean
  glucoseTargetRangeLower: number
  glucoseTargetRangeUpper: number
  targetRangeGoalPercentage: number
  trendGraphHeight: number
  contactInfo: string
  isHighAlertsEnabled: boolean
  isHighAlertsAudible: boolean
  highAlertThreshold: number
  isLowAlertsEnabled: boolean
  isLowAlertsAudible: boolean
  lowAlertThreshold: number
}

export type ClientConfigErrors = {
  isStudyBlinded: string
  contactInfo: string
  isHighAlertsEnabled: string
  isHighAlertsAudible: string
  highAlertThreshold: string
  isLowAlertsEnabled: string
  isLowAlertsAudible: string
  lowAlertThreshold: string
  isMealPhotoAllowed: string
  isUnitsMmol: string
  glucoseTargetRangeLower: string
  glucoseTargetRangeUpper: string
  targetRangeGoalPercentage: string
  trendGraphHeight: string
}

export type ClientConfigValidation = {
  values: ClientConfig
  errors: ClientConfigErrors
}

export const emptyClientConfig: ClientConfig = {
  isStudyBlinded: true,
  isMealPhotoAllowed: false,
  isUnitsMmol: false,
  glucoseTargetRangeLower: 0,
  glucoseTargetRangeUpper: 0,
  targetRangeGoalPercentage: 0,
  trendGraphHeight: 0,
  contactInfo: '',
  isHighAlertsEnabled: false,
  isHighAlertsAudible: false,
  highAlertThreshold: 0,
  isLowAlertsEnabled: false,
  isLowAlertsAudible: false,
  lowAlertThreshold: 0,
}

export const emptyClientConfigErrors: ClientConfigErrors = {
  isStudyBlinded: '',
  contactInfo: '',
  isHighAlertsEnabled: '',
  isHighAlertsAudible: '',
  highAlertThreshold: '',
  isLowAlertsEnabled: '',
  isLowAlertsAudible: '',
  lowAlertThreshold: '',
  isMealPhotoAllowed: '',
  isUnitsMmol: '',
  glucoseTargetRangeLower: '',
  glucoseTargetRangeUpper: '',
  targetRangeGoalPercentage: '',
  trendGraphHeight: '',
}
