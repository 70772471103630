import React, { FunctionComponent } from 'react'
import { Grid, Typography, useTheme } from '@material-ui/core'

type EmptyInfoContainerProps = {
  infoText: string
}

export const EmptyInfoContainer: FunctionComponent<EmptyInfoContainerProps> = ({
  infoText,
}) => {
  const theme = useTheme()
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      style={{
        minHeight: '90vh',
        backgroundColor: theme.palette.background.default,
      }}
    >
      <Grid
        item
        xs={12}
        style={{
          width: '100%',
          height: '35vh',
          textAlign: 'center',
        }}
      >
        <Typography
          variant={'h4'}
          style={{ color: theme.palette.text.secondary }}
        >
          {infoText}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default EmptyInfoContainer
