import { StudyData } from '@models/data.models'
import { StudyAction, StudyActionTypes } from '../actions/study.actions'

export type StudyState = {
  [id: string]: StudyData
}

export default (state: StudyState, action: StudyAction): StudyState => {
  switch (action.type) {
    case StudyActionTypes.SET_ALL_STUDIES:
      return action.payload.reduce((acc, er) => {
        acc[er.id] = er
        return acc
      }, {} as StudyState)
    case StudyActionTypes.SET_STUDY:
      return { ...state, [action.payload.id]: action.payload }
    case StudyActionTypes.REMOVE_STUDY: {
      delete state[action.payload]
      return { ...state }
    }
    default:
      return state
  }
}
