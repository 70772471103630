import React from 'react'
import { SiteTabsView, SiteTabsViewProps } from '@components/Site/SiteTabsView'
import { SubjectProvider } from '@providers/subject/subject.provider'
import { useParams } from 'react-router-dom'
import { StudyActions } from '@models/access.models'
import useAccess from '@providers/access/useAccess'
import { AccessProvider } from '@providers/access/access.provider'
import { MetricsProvider } from '@providers/metrics/metrics.provider'

const withSubjects = <T extends SiteTabsViewProps>(Component: React.ComponentType<T>) => (props: T) => {
    const access = useAccess()

    if (access.permissions.includes(StudyActions.VIEW_SUBJECTS)) {
        return (
            <SubjectProvider siteId={props.siteId}>
                <Component { ...props } />
            </SubjectProvider>
        )
    }

    return <Component { ...props } />
}

const withMetrics = <T extends SiteTabsViewProps>(Component: React.ComponentType<T>) => (props: T) => {
    const access = useAccess()

    if (access.permissions.includes(StudyActions.MONITOR_DEVICES)) {
        return (
            <MetricsProvider siteId={props.siteId}>
                <Component {...props} />
            </MetricsProvider>
        )
    }

    return <Component { ...props } />
}

const ConnectedSiteDetails = withMetrics(withSubjects(SiteTabsView))

// Connected Component
export default () => {
    const { studyId, siteId } = useParams<{ studyId: string; siteId: string }>()

    return (
        <AccessProvider resourceId={siteId}>
            <ConnectedSiteDetails siteId={siteId} studyId={studyId} />
        </AccessProvider>
    )
}
