import { get, post, put, del } from '@lib/utils/http'
import {
  AddParticipantResponse,
  IParticipantRecord,
  IDashboardUserDetails,
  IAcknowledgement,
  IInstanceDetails,
  IInstanceConfig,
  IEventRule,
  IDefaultEventRule,
  IStudyDetails,
  ITermsDetails,
  IRoleDetail,
  ISubjectDetails,
  TermsUpdateParams,
  TermsCreateBySiteParams,
} from '@models/index'
import {
  alarmUrl,
  allParticipantsUrl,
  configUrl,
  exportParticipantsUrl,
  participantUrl,
  resetPasswordUrl,
  instancesUrl,
  instanceUrl,
  usersUrl,
  userUrl,
  eventUrl,
  studiesUrl,
  studyUrl,
  rolesUrl,
  termsUrl,
  siteUrl,
  API_URL,
} from '@config/resourceUrls'
import { ConsentMap, SiteConfig, Study, StudySubject } from '@models/api.models'
import { INewSubject } from '@components/Subject/useAddSubject'

export const addParticipant = async (
  token: string,
  body: unknown
): Promise<AddParticipantResponse> => {
  const resp = await post<AddParticipantResponse>(participantUrl, body, token)
  if (!resp.parsedBody) {
    throw new Error('Expected response body, got undefined')
  }
  return resp.parsedBody
}

interface ActivateBody {
  code: string
}

export const activateParticipant = async (
  token: string,
  id: string,
  body: ActivateBody
): Promise<IParticipantRecord> => {
  const resp = await post<IParticipantRecord>(
    `${participantUrl}/${id}/activate`,
    body,
    token
  )
  if (!resp.parsedBody) {
    throw new Error('Expected response body, got undefined')
  }

  return resp.parsedBody
}

export const reactivateParticipant = async (
  token: string,
  participantId: string
): Promise<void> => {
  await put<IParticipantRecord>(
    `${participantUrl}/${participantId}/reactivate`,
    {},
    token
  )
}

export const acknowledgeAlarm = async (
  token: string,
  participantId: string,
  alarmId: string
): Promise<IAcknowledgement> => {
  const resp = await post<IAcknowledgement>(
    `${alarmUrl}/acknowledge`,
    { participantId, alarmId },
    token
  )

  if (!resp.parsedBody) {
    throw new Error('Expected response body, got undefined')
  }
  return resp.parsedBody
}

export const dischargeParticipant = async (
  token: string,
  participantId: string
): Promise<void> => {
  await put<IParticipantRecord>(
    `${participantUrl}/${participantId}/discharge`,
    {},
    token
  )
}

export const addDashboardUser = async (
  token: string,
  username: string,
  password: string,
  roleId: string
): Promise<IDashboardUserDetails> => {
  const reqBody = {
    username: username,
    password: password,
    roleIds: [roleId],
  }

  const resp = await post<IDashboardUserDetails>(userUrl, reqBody, token)
  if (!resp.parsedBody) {
    throw new Error('Expected response body, got undefined')
  }
  return resp.parsedBody
}

export const getDashboardUsers = async (
  token: string
): Promise<IDashboardUserDetails[]> => {
  const resp = await get<IDashboardUserDetails[]>(usersUrl, token)
  if (!resp.parsedBody) {
    throw new Error('Expected response body, got undefined')
  }
  return resp.parsedBody
}

export const inactivateParticipant = async (
  token: string,
  participantId: string
): Promise<void> => {
  await put<IParticipantRecord>(
    `${participantUrl}/${participantId}/inactivate`,
    {},
    token
  )
}

export const addInstance = async (
  token: string,
  instanceId: string,
  adminUsername: string,
  password: string,
  voyager?: boolean
): Promise<IInstanceDetails> => {
  const reqBody = {
    adminUsername: adminUsername,
    password: password,
    instanceId: instanceId,
    isVoyager: voyager,
  }
  const resp = await post<IInstanceDetails>(instanceUrl, reqBody, token)
  if (!resp.parsedBody) {
    throw new Error('Expected response body, got undefined')
  }
  return resp.parsedBody
}

export const deleteInstance = async (
  token: string,
  instanceId: string
): Promise<void> => {
  await del(instanceUrl, instanceId, token)
}

export const getInstances = async (
  token: string
): Promise<IInstanceDetails[]> => {
  const resp = await get<IInstanceDetails[]>(instancesUrl, token)
  if (!resp.parsedBody) {
    throw new Error('Expected response body, got undefined')
  }
  return resp.parsedBody
}

export const saveInstanceConfig = async (
  token: string,
  instanceConfig: IInstanceConfig
): Promise<void> => {
  await put<IInstanceConfig>(configUrl, instanceConfig, token)
}

export const saveSiteConfig = async (
  token: string,
  siteConfig: SiteConfig
): Promise<void> => {
  await put<SiteConfig>(
    `${siteUrl}/${siteConfig.siteId}/config`,
    siteConfig,
    token
  )
}

export const submitComment = async (
  participantId: string,
  comment: string,
  token: string
): Promise<void> => {
  await put<void>(
    `${participantUrl}/${participantId}/comment`,
    { comment: comment },
    token
  )
}

export const updateMetadata = async (
  participantId: string,
  value: string,
  token: string
): Promise<void> => {
  await put<void>(
    `${participantUrl}/${participantId}/metadata`,
    { value: value },
    token
  )
}

export const getAllParticipants = async (
  token: string
): Promise<IParticipantRecord[]> => {
  const resp = await get<IParticipantRecord[]>(allParticipantsUrl, token)
  if (!resp.parsedBody) {
    throw new Error('Expected response body, got undefined')
  }
  return resp.parsedBody
}

export const exportParticipants = async (
  token: string,
  participantIds: string[]
): Promise<string[][]> => {
  const resp = await post<string[][]>(
    exportParticipantsUrl,
    participantIds,
    token
  )
  if (!resp.parsedBody) {
    throw new Error('Expected response body, got undefined')
  }
  return resp.parsedBody
}

export const resetUserPassword = async (
  userId: string,
  password: string,
  token: string
): Promise<void> => {
  const reqBody = {
    password: password,
  }
  await put<void>(`${userUrl}/${userId}/resetPassword`, reqBody, token)
}

export const resetPassword = async (
  password: string,
  token: string
): Promise<void> => {
  const reqBody = {
    password: password,
  }
  await put<void>(resetPasswordUrl, reqBody, token)
}

export const saveEventRule = async (
  token: string,
  eventRule: IEventRule
): Promise<void> => {
  await put<IEventRule>(eventUrl, eventRule, token)
}

export const insertEventRule = async (
  token: string,
  eventRule: IDefaultEventRule
): Promise<IEventRule> => {
  const resp = await post<IEventRule>(eventUrl, eventRule, token)
  if (!resp.parsedBody) {
    throw new Error('Expected response body, got undefined')
  }
  return resp.parsedBody
}

export const deleteEventRule = async (
  token: string,
  id: string
): Promise<void> => {
  await del(eventUrl, id, token)
}

export const getStudies = async (token: string): Promise<Study[]> => {
  const resp = await get<Study[]>(studiesUrl, token)
  if (!resp.parsedBody) {
    throw new Error('Expected response body, got undefined')
  }
  return resp.parsedBody
}

export const createStudy = async (
  token: string,
  studyName: string,
  studyType: string,
  protocolId: string,
  studyAdmin: string
): Promise<IStudyDetails> => {
  const hydratedStudy = {
    name: studyName,
    type: studyType,
    protocolId: protocolId,
    dexcomAdministrator: studyAdmin,
  }

  const resp = await post<IStudyDetails>(studyUrl, hydratedStudy, token)
  if (!resp.parsedBody) {
    throw new Error('Expected response body, got undefined')
  }
  return resp.parsedBody
}

export const updateStudy = async (
  token: string,
  study: IStudyDetails
): Promise<void> => {
  await put<IStudyDetails>(studyUrl, study, token)
}

export const getConsents = async (
  token: string,
  studyId: string,
  siteId: string
): Promise<ConsentMap> => {
  const resp = await get<ConsentMap>(
    `${studyUrl}/${studyId}/site/${siteId}/consents`,
    token
  )

  if (!resp.parsedBody) {
    throw new Error('Expected response body, got undefined')
  }
  return resp.parsedBody
}

export const getTermsBySiteId = async (
  token: string,
  siteId: string
): Promise<ITermsDetails> => {
  const resp = await get<ITermsDetails>(`${siteUrl}/${siteId}/terms`, token)

  if (!resp.parsedBody) {
    throw new Error('Expected response body, got undefined')
  }
  return resp.parsedBody
}

export const updateTerms = async (
  token: string,
  terms: TermsUpdateParams
): Promise<ITermsDetails> => {
  const resp = await put<ITermsDetails>(`${termsUrl}`, terms, token)
  if (!resp.parsedBody) {
    throw new Error('Expected response body, got undefined')
  }
  return resp.parsedBody
}

export const createSiteTerms = async (
  token: string,
  terms: TermsCreateBySiteParams
): Promise<ITermsDetails> => {
  const resp = await post<ITermsDetails>(`${termsUrl}`, terms, token)
  if (!resp.parsedBody) {
    throw new Error('Expected response body, got undefined')
  }
  return resp.parsedBody
}

export const getInstanceRoles = async (
  token: string,
  instanceType: string
): Promise<IRoleDetail[]> => {
  const resp = await get<IRoleDetail[]>(`${rolesUrl}/${instanceType}`, token)

  if (!resp.parsedBody) {
    throw new Error('Expected response body, got undefined')
  }
  return resp.parsedBody
}

export const addSubject = async (
  token: string,
  siteId: string,
  body: INewSubject
): Promise<StudySubject> => {
  const formattedUrl = `${siteUrl}/${siteId}/subject`
  const resp = await post<StudySubject>(formattedUrl, body, token)
  if (!resp.parsedBody) {
    throw new Error('Expected response body, got undefined')
  }
  return resp.parsedBody
}

export const getStudySiteSubjects = async (
  token: string,
  studyId: string,
  siteId: string
): Promise<ISubjectDetails[]> => {
  const formattedUrl = `${studyUrl}/${studyId}/site/${siteId}/subject/all`
  console.log(formattedUrl)
  const resp = await get<ISubjectDetails[]>(formattedUrl, token)
  if (!resp.parsedBody) {
    throw new Error('Expected response body, got undefined')
  }
  return resp.parsedBody
}
