import React from 'react'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Divider from '@material-ui/core/Divider'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import { Button, makeStyles, useTheme } from '@material-ui/core'
import { IComment, IEgvEventRecord } from '@models/index'
import { justDate, justTime } from '@lib/utils/dates'
import useCombinedEvents from '@providers/data/hooks/useCombinedEvents'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '40px',
  },
  title: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: theme.palette.text.secondary,
  },
  action: {
    alignSelf: 'center',
    marginTop: 0,
  },
  event: {
    fontWeight: 'bold',
    borderRadius: '20px',
    padding: '10px 20px 10px 20px',
  },
  content: {
    padding: 0,
  },
  cell: {
    paddingTop: 10,
    paddingBottom: 10,
  },
}))

type CommentsCardProps = {
  onAdd: () => void
  comments: IComment[]
  events: IEgvEventRecord[]
}

export default function CommentsCard(props: CommentsCardProps) {
  const theme = useTheme()
  const classes = useStyles(theme)
  const combinedEvents = useCombinedEvents(props.comments, props.events)

  return (
    <Card>
      <CardHeader
        classes={{
          root: classes.root,
          title: classes.title,
          action: classes.action,
        }}
        title="Comments"
        action={
          <Button onClick={props.onAdd} color="primary" variant="contained">
            Add
          </Button>
        }
      />
      <Divider />
      <CardContent
        classes={{ root: classes.content }}
        style={{ maxHeight: 415, overflow: 'auto' }}
      >
        <Table style={{ width: '100%' }}>
          <TableHead classes={{ root: theme.palette.background.paper }}>
            <TableRow>
              <TableCell classes={{ root: classes.cell }} width="100px">
                Date
              </TableCell>
              <TableCell classes={{ root: classes.cell }} width="100px">
                Time
              </TableCell>
              <TableCell classes={{ root: classes.cell }}>Comment</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {combinedEvents.map((comment, i) => (
              <TableRow key={i}>
                <TableCell>{justDate(comment.date)}</TableCell>
                <TableCell>{justTime(comment.date)}</TableCell>
                <TableCell>
                  {comment.type === 'comment' ? (
                    comment.text
                  ) : (
                    <span style={comment.color} className={`${classes.event}`}>
                      {' '}
                      {comment.text.charAt(0).toUpperCase() +
                        comment.text.slice(1)}
                    </span>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  )
}
