import { useEffect, useState } from 'react'
import { getSiteConfig } from '@lib/data/siteConfig.data'
import { useKeycloak } from '@providers/auth'
import { ClientConfig } from '@models/clientConfig.models'

type ClientConfigState = {
  clientConfig: ClientConfig | null
  fetching: boolean
}

export default (studyId: string, siteId: string) => {
  const { token } = useKeycloak()
  const [state, setState] = useState<ClientConfigState>({
    clientConfig: null,
    fetching: false,
  })

  useEffect(() => {
    let subscribed = true
    setState({
      clientConfig: null,
      fetching: true,
    })

    getSiteConfig(token, siteId)
      .then((siteConfig) => {
        if (!subscribed) return
        setState({
          clientConfig: siteConfig,
          fetching: false,
        })
      })
      .catch((err) => {
        console.log(err)
        if (!subscribed) return
        setState({
          clientConfig: null,
          fetching: false,
        })
      })

    return () => {
      subscribed = false
    }
  }, [studyId, siteId])

  const setConfig = (config: ClientConfig) => {
    setState((prevState) => {
      return {
        ...prevState,
        clientConfig: config,
      }
    })
  }

  return {
    clientConfig: state.clientConfig,
    fetching: state.fetching,
    setConfig,
  }
}
