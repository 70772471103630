import React, { useEffect } from 'react'
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
  useTheme,
} from '@material-ui/core'
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined'
import { PermissionsHeader } from '@components/Staff/PermissionsControls'
import useDashboardStyles from '@components/GlobalStyles'
import { EditAndSaveButtons } from '@components/EditAndSaveButtons'
import useSite from '@lib/hooks/useSite'
import EmptyInfoContainer from '@components/EmptyInfoContainer'
import useAccess from '@providers/access/useAccess'
import useStaffManagement from '@components/Staff/useStaffManagement'
import EditableStaffRow from './EditableStaffRow'
import ReadonlyStaffRow from './ReadonlyStaffRow'
import { Role } from '@models/access.models'
import useCancellable from '@lib/hooks/useCancellable'
import { StaffList } from '@models/staff.models'
import { getStaffList } from '@lib/data/access.data'
import { useKeycloak } from '@providers/auth'
import Spinner from '@components/Spinner/Spinner'
import { dexcomStaffRoles, siteStaffRoles } from '@models/access.models'

type StaffCardViewProps = {
  siteId: string
  cardTitle: string
  staffType: string
}

type StaffCardViewState = {
  canEdit: boolean
  canSave: boolean
  disableEditAndSave: boolean
  permissionsOptions: Record<string, Role>
}

const SiteStaffCardView: React.FunctionComponent<StaffCardViewProps> = (
  props
) => {
  const theme = useTheme()
  const classes = useDashboardStyles(theme)
  const site = useSite(props.siteId)
  const access = useAccess()
  const staffManagement = useStaffManagement()
  const { token } = useKeycloak()
  const staffList = useCancellable<Record<string, StaffList>>(() =>
    getStaffList(token, props.siteId)
  )
  const [state, setState] = React.useState<StaffCardViewState>({
    canEdit: false,
    canSave: false,
    disableEditAndSave: true,
    permissionsOptions:
      props.staffType === 'dexcomStaff' ? dexcomStaffRoles : siteStaffRoles,
  })

  useEffect(() => {
    if (access) {
      const userCanEdit =
        access.permissions.includes('manage_users') ||
        access.permissions.includes('manage_roles') ||
        access.permissions.includes('special_access')

      setState((prevState) => ({
        ...prevState,
        disableEditAndSave: !userCanEdit,
      }))
    }
  }, [])

  useEffect(() => {
    if (staffList.data) {
      const staff = Object.values(staffList.data[props.staffType])
      staffManagement.actions.setAllStaff(staff)
    }
  }, [staffList.data])

  const handleStaffFieldChange = (
    staffIndex: number,
    field: string,
    value: string
  ) => {
    staffManagement.actions.staffFieldChange(staffIndex, field, value)
    setState((prevState) => ({ ...prevState, canSave: true }))
  }

  const handleStaffRoleChange = (
    staffIndex: number,
    name: string,
    enabled: boolean
  ) => {
    staffManagement.actions.staffRoleChange(staffIndex, name, enabled)
    setState((prevState) => ({ ...prevState, canSave: true }))
  }

  const handleAddStaff = () => {
    staffManagement.actions.addStaff()
    setState((prevState) => ({ ...prevState, canSave: true }))
  }

  const handleRemoveStaff = (staffIndex: number) => () => {
    staffManagement.actions.removeStaff(staffIndex)
    setState((prevState) => ({ ...prevState, canSave: true }))
  }

  const handleEditClicked = () => {
    setState({
      ...state,
      canEdit: !state.canEdit,
      canSave: false,
    })
  }

  const handleCancelClicked = () => {
    staffManagement.actions.resetStaff()
    setState({
      ...state,
      canEdit: false,
      canSave: false,
    })
  }

  const handleSaveClicked = () => {
    setState({
      ...state,
      canEdit: false,
      canSave: false,
    })
  }

  if (state.disableEditAndSave) {
    return <EmptyInfoContainer infoText="Access Denied" />
  }
  if (!site || !site.data) return <EmptyInfoContainer infoText="No Site Info" />

  return (
    <Card>
      <CardHeader
        title={props.cardTitle}
        classes={{
          root: classes.cardRoot,
          title: classes.cardTitle,
          action: classes.cardAction,
        }}
        color="textSecondary"
        action={
          !state.disableEditAndSave && (
            <EditAndSaveButtons
              disabledButtons={state.disableEditAndSave}
              canEdit={state.canEdit}
              canSave={state.canSave}
              onEdit={handleEditClicked}
              onSave={handleSaveClicked}
              onCancel={handleCancelClicked}
              editButtonText="Edit"
            />
          )
        }
      />
      <Divider />
      <CardContent>
        {staffManagement.errors && staffManagement.errors.length > 0 && (
          <Grid
            container
            justifyContent="center"
            alignItems={'center'}
            style={{
              textAlign: 'center',
            }}
          >
            <Grid item xs={12}>
              <Typography variant={'h5'} color="error">
                {staffManagement.errors}
              </Typography>
            </Grid>
          </Grid>
        )}

        {staffManagement.values.length === 0 &&
          (state.canEdit ? (
            <Grid
              container
              justifyContent="center"
              alignItems={'center'}
              style={{
                textAlign: 'center',
                minHeight: '10vh',
              }}
            >
              <Grid item xs>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<PersonAddOutlinedIcon />}
                  onClick={handleAddStaff}
                >
                  Add Staff
                </Button>
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              justifyContent="center"
              alignItems={'center'}
              style={{
                textAlign: 'center',
                minHeight: '10vh',
              }}
            >
              <Grid item xs={12}>
                <Typography
                  variant={'h5'}
                  style={{ color: theme.palette.primary.main }}
                >
                  No {props.cardTitle}
                </Typography>
                <Typography variant={'body2'} color="textSecondary">
                  Site - {site.data.label}
                </Typography>
              </Grid>
            </Grid>
          ))}
        {staffManagement.values.length > 0 && (
          <React.Fragment>
            <Grid container style={{ marginBottom: '10pt' }}>
              <Grid container item alignItems="center">
                <Grid item xs={6} md={8}></Grid>
                <Grid
                  container
                  item
                  xs={6}
                  md={4}
                  style={{ textAlign: 'center' }}
                >
                  <PermissionsHeader definitions={state.permissionsOptions} />
                </Grid>
              </Grid>
            </Grid>

            {staffManagement.values.map((staff, staffIdx) => {
              return state.canEdit ? (
                <EditableStaffRow
                  key={staffIdx}
                  staffIdx={staffIdx}
                  staffCount={staffManagement.values.length}
                  staff={staff}
                  staffErrors={staffManagement.errors[staffIdx]}
                  permissionOptions={state.permissionsOptions}
                  onAddStaff={handleAddStaff}
                  onRemoveStaff={handleRemoveStaff(staffIdx)}
                  onStaffFieldChange={handleStaffFieldChange}
                  onStaffRoleChange={handleStaffRoleChange}
                />
              ) : (
                <ReadonlyStaffRow
                  key={staffIdx}
                  staff={staff}
                  permissionOptions={state.permissionsOptions}
                />
              )
            })}
          </React.Fragment>
        )}
        {staffList.fetching && <Spinner />}
      </CardContent>
    </Card>
  )
}

export default SiteStaffCardView
