import {
  required,
  validateDexcomEmail,
  validateEmail,
  validateLength,
} from '@lib/validate'

export type StaffManagementState = {
  staff: SiteStaff[]
  staffErrors: StaffErrors[]
}

export type StaffList = Record<string, SiteStaff>

export type SiteStaff = {
  email: string
  roles: string[]
}

export type StaffErrors = {
  email: string
  roles: string
}

export function validateDexcomStaff(dexcomAdministrator: string): string {
  let error = required(dexcomAdministrator)

  if (!error) {
    error = validateLength(320, dexcomAdministrator)
  }

  if (!error) {
    error = validateEmail(dexcomAdministrator)
  }

  if (!error) {
    error = validateDexcomEmail(dexcomAdministrator)
  }

  return error
}

export function validateStaffRoles(roles: string[]): string {
  if (roles.length === 0) {
    return 'Must assign a role'
  }

  return ''
}
