import React, { useState } from 'react'
import { MuiThemeProvider, responsiveFontSizes } from '@material-ui/core'
import CssBaseline from '@material-ui/core/CssBaseline'
import { themeCreator } from './themeService'

export const ThemeContext = React.createContext((_themeName: string): void => {
  //Inital State
})

const ThemeProvider: React.FC = (props) => {
  // Read current theme from localStorage or maybe from an api
  const curThemeName = localStorage.getItem('appTheme') || 'lightTheme'

  // State to hold the selected theme name
  const [themeName, _setThemeName] = useState(curThemeName)

  // Get the theme object by theme name
  let theme = themeCreator(themeName)
  theme = responsiveFontSizes(theme)

  const setThemeName = (themeName: string): void => {
    localStorage.setItem('appTheme', themeName)
    _setThemeName(themeName)
  }

  return (
    <ThemeContext.Provider value={setThemeName}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        {props.children}
      </MuiThemeProvider>
    </ThemeContext.Provider>
  )
}

export default ThemeProvider
