import React, { useMemo, useCallback } from 'react'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import Grid from '@material-ui/core/Grid'
import {
  DataGrid,
  GridCellParams,
  GridColumns,
  GridValueFormatterParams,
  GridValueGetterParams,
} from '@material-ui/data-grid'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'

import Spinner from '@components/Spinner/Spinner'
import { useKeycloak } from '@providers/auth'
import useInstanceConfig from '@providers/data/hooks/useInstanceConfig'
import { formatTime } from '@lib/utils/dates'

import useDataExport from './useDataExport'

function ExportData(): JSX.Element {
  const { token } = useKeycloak()
  const { instanceConfig } = useInstanceConfig()
  const dataExport = useDataExport(token)
  const handleSelect = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const id = event.target.getAttribute('data-id')
      dataExport.toggleSelect(id as string)
    },
    [dataExport]
  )

  const columnsDefinition = useMemo(() => {
    if (!instanceConfig) {
      return []
    }
    const columns: GridColumns = [
      {
        field: 'selected',
        headerName: 'Selected',
        width: 140,
        renderCell: (params: GridCellParams) => {
          const selected = params.value as boolean
          return (
            <Checkbox
              inputProps={
                {
                  'data-id': params.id,
                } as React.InputHTMLAttributes<HTMLInputElement>
              }
              checked={selected}
              onChange={handleSelect}
              color="primary"
            />
          )
        },
      },
    ]
    instanceConfig.metas.forEach((meta, i) => {
      columns.push({
        field: meta.displayname,
        width: i === 0 ? 225 : 160,
        headerName: meta.displayname,
        valueGetter: (params: GridValueGetterParams) => {
          const metadata = params.getValue(params.id, 'metas') as string[]
          if (!metadata) return ''
          return metadata[i]
        },
      })
    })
    columns.push({
      field: 'timeInRange',
      headerName: 'Time In Range',
      width: 170,
      valueFormatter: (params: GridValueFormatterParams) => {
        const timeInRange = params.value as number
        return formatTime(timeInRange)
      },
    }),
      columns.push({
        field: 'hypoTime',
        headerName: 'Hypo Time',
        width: 160,
        valueFormatter: (params: GridValueFormatterParams) => {
          const hypoTime = params.value as number
          return formatTime(hypoTime)
        },
      }),
      columns.push({
        field: 'hypoEventCount',
        headerName: 'Hypo Events',
        width: 160,
      }),
      columns.push({
        field: 'hyperTime',
        headerName: 'Hyper Time',
        width: 160,
        valueFormatter: (params: GridValueFormatterParams) => {
          const hyperTime = params.value as number
          return formatTime(hyperTime)
        },
      }),
      columns.push({
        field: 'hyperEventCount',
        headerName: 'Hyper Events',
        width: 170,
      }),
      columns.push({
        field: 'usage',
        headerName: 'Total Usage',
        width: 150,
        valueFormatter: (params: GridValueFormatterParams) => {
          const usage = params.value as number
          return formatTime(usage)
        },
      })

    return columns
  }, [instanceConfig, handleSelect])

  if (!instanceConfig) {
    return <Spinner />
  }

  return (
    <Grid item xs={12} alignItems="center">
      <Card>
        <CardHeader
          title="Export Patient Data"
          action={
            <Button
              color="primary"
              variant="contained"
              style={{
                textTransform: 'initial',
                width: 100,
              }}
              onClick={dataExport.exportSelected}
              disabled={dataExport.selectedCount === 0}
            >
              Export
            </Button>
          }
        />
        <Divider />
        <ListItem>
          <label>
            To export patient(s) data, check the box by their name, then click{' '}
            <b>Export.</b>
          </label>
        </ListItem>
        <CardContent>
          <DataGrid
            rows={dataExport.participants}
            columns={columnsDefinition}
            hideFooter
            density="compact"
            autoHeight
          />
        </CardContent>
      </Card>
    </Grid>
  )
}

export default ExportData
