import { ConsentMap } from '@models/api.models'
import { StudyData } from '@models/data.models'
import {
  EGVRecord,
  IAcknowledgement,
  IAlarm,
  IDashboardUserDetails,
  IParticipantRecord,
  IInstanceDetails,
  IInstanceConfig,
  IUserConfig,
  IEventRule,
  ISubjectDetails,
  ITermsDetails,
  IRoleDetail,
} from '@models/index'
import { CUSTOM_SORT_OPTIONS } from '@models/sortOptions.models'
import { StudyAction } from './actions/study.actions'
import { StudySiteAction } from './actions/studySite.actions'

export enum ActionTypes {
  ALL_EGVS = 'ALL_EGVS',
  ALL_ACKNOWLEDGEMENTS = 'ALL_ACKNOWLEDGEMENTS',
  NEW_EGVS = 'NEW_EGVS',
  NEW_ACKNOWLEDGEMENT = 'NEW_ACKNOWLEDGEMENT',
  ALARM_UPDATE = 'ALARM_UPDATE',
  PARTICIPANT_UPDATE = 'PARTICIPANT_UPDATE',
  INSTANCE_CONFIG_UPDATE = 'INSTANCE_CONFIG_UPDATE',
  USER_CONFIG_UPDATE = 'USER_CONFIG_UPDATE',
  SET_TOAST = 'SET_TOAST',
  SET_ERROR_TOAST = 'SET_ERROR_TOAST',
  HIDE_TOAST = 'HIDE_TOAST',
  TICK = 'TICK',
  SET_SORT_BY = 'SET_SORT_BY',
  TRIGGER_ALARM_ON = 'TRIGGER_ALARM_ON',
  TRIGGER_ALARM_OFF = 'TRIGGER_ALARM_OFF',
  SHOW_DISCHARGE_DIALOG = 'SHOW_DISCHARGE_DIALOG',
  HIDE_DISCHARGE_DIALOG = 'HIDE_DISCHARGE_DIALOG',
  SHOW_ACCESS_CODE_DIALOG = 'SHOW_ACCESS_CODE_DIALOG',
  HIDE_ACCESS_CODE_DIALOG = 'HIDE_ACCESS_CODE_DIALOG',
  ALL_INSTANCES = 'ALL_INSTANCES',
  NEW_INSTANCE = 'NEW_INSTANCE',
  DELETE_INSTANCE = 'DELETE_INSTANCE',
  ALL_USERS = 'ALL_USERS',
  NEW_USER = 'NEW_USER',
  ALL_EVENT_RULES = 'ALL_EVENT_RULES',
  DELETE_EVENT_RULE = 'DELETE_EVENT_RULE',
  UPDATE_EVENT_RULE = 'UPDATE_EVENT_RULE',
  TERMS = 'TERMS',
  ROLES = 'ROLES',
  CONSENTS = 'CONSENTS',
}

interface ITriggerAlarmOn {
  type: ActionTypes.TRIGGER_ALARM_ON
  payload: string
}

interface ITriggerAlarmOff {
  type: ActionTypes.TRIGGER_ALARM_OFF
  payload: string
}

interface ITickAction {
  type: ActionTypes.TICK
  payload: number
}

interface IAllAcknowledgementsAction {
  type: ActionTypes.ALL_ACKNOWLEDGEMENTS
  payload: IAcknowledgement[]
}

interface INewAcknowledgementAction {
  type: ActionTypes.NEW_ACKNOWLEDGEMENT
  payload: IAcknowledgement
}

interface IAlarmUpdateAction {
  type: ActionTypes.ALARM_UPDATE
  payload: Record<string, IAlarm[]>
}

interface IInstanceConfigUpdateAction {
  type: ActionTypes.INSTANCE_CONFIG_UPDATE
  payload: IInstanceConfig
}

interface IParticipantUpdateAction {
  type: ActionTypes.PARTICIPANT_UPDATE
  payload: Array<IParticipantRecord>
}

interface IAllEgvsAction {
  type: ActionTypes.ALL_EGVS
  payload: Record<string, EGVRecord[]>
}

interface INewEgvsAction {
  type: ActionTypes.NEW_EGVS
  payload: Record<string, EGVRecord[]>
}

export interface IUserConfigUpdateAction {
  type: ActionTypes.USER_CONFIG_UPDATE
  payload: IUserConfig
}

export interface ISetToastAction {
  type: ActionTypes.SET_TOAST
  payload: {
    message: string
    severity: 'success' | 'warning' | 'error' | 'info'
  }
}

export interface ISetErrorToastAction {
  type: ActionTypes.SET_ERROR_TOAST
  payload: string
}

export interface IHideToastAction {
  type: ActionTypes.HIDE_TOAST
  payload: undefined
}

export interface ISetSortBy {
  type: ActionTypes.SET_SORT_BY
  payload: CUSTOM_SORT_OPTIONS
}

export interface IShowDischargeDialog {
  type: ActionTypes.SHOW_DISCHARGE_DIALOG
  payload: string
}

export interface IHideDischargeDialog {
  type: ActionTypes.HIDE_DISCHARGE_DIALOG
}
export interface IShowAccessCodeDialog {
  type: ActionTypes.SHOW_ACCESS_CODE_DIALOG
  payload: ISubjectDetails
}

export interface IHideAccessCodeDialog {
  type: ActionTypes.HIDE_ACCESS_CODE_DIALOG
}
export interface IAllInstancesAction {
  type: ActionTypes.ALL_INSTANCES
  payload: IInstanceDetails[]
}
export interface INewInstanceAction {
  type: ActionTypes.NEW_INSTANCE
  payload: IInstanceDetails
}
export interface IDeleteInstanceAction {
  type: ActionTypes.DELETE_INSTANCE
  payload: string
}
export interface IAllUsersAction {
  type: ActionTypes.ALL_USERS
  payload: IDashboardUserDetails[]
}
export interface INewUserAction {
  type: ActionTypes.NEW_USER
  payload: IDashboardUserDetails
}

export interface IAllEventRulesAction {
  type: ActionTypes.ALL_EVENT_RULES
  payload: IEventRule[]
}

export interface IDeleteEventRuleAction {
  type: ActionTypes.DELETE_EVENT_RULE
  payload: string
}

export interface IUpdateEventRuleAction {
  type: ActionTypes.UPDATE_EVENT_RULE
  payload: IEventRule
}

export interface ITermsAction {
  type: ActionTypes.TERMS
  payload: ITermsDetails
}
export interface IInstanceRolesAction {
  type: ActionTypes.ROLES
  payload: IRoleDetail[]
}

export interface IConsentsAction {
  type: ActionTypes.CONSENTS
  payload: ConsentMap
}

export type Action =
  | StudyAction
  | ITickAction
  | IAlarmUpdateAction
  | IUserConfigUpdateAction
  | INewEgvsAction
  | IAllEgvsAction
  | IParticipantUpdateAction
  | ISetToastAction
  | ISetErrorToastAction
  | IHideToastAction
  | ISetSortBy
  | IShowDischargeDialog
  | IHideDischargeDialog
  | IShowAccessCodeDialog
  | IHideAccessCodeDialog
  | IAllAcknowledgementsAction
  | INewAcknowledgementAction
  | ITriggerAlarmOn
  | ITriggerAlarmOff
  | IAllUsersAction
  | INewUserAction
  | IAllInstancesAction
  | INewInstanceAction
  | IDeleteInstanceAction
  | IInstanceConfigUpdateAction
  | IInstanceRolesAction
  | IAllEventRulesAction
  | IUpdateEventRuleAction
  | IDeleteEventRuleAction
  | ITermsAction
  | StudySiteAction
  | IConsentsAction

export const alarmOn = (participantId: string): ITriggerAlarmOn => ({
  type: ActionTypes.TRIGGER_ALARM_ON,
  payload: participantId,
})

export const alarmOff = (participantId: string): ITriggerAlarmOff => ({
  type: ActionTypes.TRIGGER_ALARM_OFF,
  payload: participantId,
})

export const tickAction = (timestamp: number): ITickAction => ({
  type: ActionTypes.TICK,
  payload: timestamp,
})

export const alarmUpdateAction = (
  alarms: Record<string, IAlarm[]>
): IAlarmUpdateAction => ({
  type: ActionTypes.ALARM_UPDATE,
  payload: alarms,
})

export const instanceConfigUpdateAction = (
  instanceConfig: IInstanceConfig
): IInstanceConfigUpdateAction => ({
  type: ActionTypes.INSTANCE_CONFIG_UPDATE,
  payload: instanceConfig,
})

export const updateEventRuleAction = (
  eventRule: IEventRule
): IUpdateEventRuleAction => ({
  type: ActionTypes.UPDATE_EVENT_RULE,
  payload: eventRule,
})

export const deleteEventRuleAction = (id: string): IDeleteEventRuleAction => ({
  type: ActionTypes.DELETE_EVENT_RULE,
  payload: id,
})

export const allEventRulesAction = (
  eventRules: IEventRule[]
): IAllEventRulesAction => ({
  type: ActionTypes.ALL_EVENT_RULES,
  payload: eventRules,
})

export const participantUpdateAction = (
  participants: Array<IParticipantRecord>
): IParticipantUpdateAction => ({
  type: ActionTypes.PARTICIPANT_UPDATE,
  payload: participants,
})

export const allEgvsAction = (
  egvs: Record<string, EGVRecord[]>
): IAllEgvsAction => ({
  type: ActionTypes.ALL_EGVS,
  payload: egvs,
})

export const newEgvsAction = (
  egvs: Record<string, EGVRecord[]>
): INewEgvsAction => ({
  type: ActionTypes.NEW_EGVS,
  payload: egvs,
})

export const allAcknowledgementsAction = (
  acknowledgements: IAcknowledgement[]
): IAllAcknowledgementsAction => ({
  type: ActionTypes.ALL_ACKNOWLEDGEMENTS,
  payload: acknowledgements,
})

export const newAcknowledgementAction = (
  acknowledgement: IAcknowledgement
): INewAcknowledgementAction => ({
  type: ActionTypes.NEW_ACKNOWLEDGEMENT,
  payload: acknowledgement,
})

export const setUserConfig = (
  userConfig: IUserConfig
): IUserConfigUpdateAction => ({
  type: ActionTypes.USER_CONFIG_UPDATE,
  payload: userConfig,
})

export const setToast = (
  message: string,
  severity: 'success' | 'warning' | 'error' | 'info'
): ISetToastAction => ({
  type: ActionTypes.SET_TOAST,
  payload: {
    message,
    severity,
  },
})

export const setErrorToast = (message: string): ISetErrorToastAction => ({
  type: ActionTypes.SET_ERROR_TOAST,
  payload: message,
})

export const hideToast = (): IHideToastAction => ({
  type: ActionTypes.HIDE_TOAST,
  payload: undefined,
})

export const setSortBy = (sortBy: CUSTOM_SORT_OPTIONS): ISetSortBy => ({
  type: ActionTypes.SET_SORT_BY,
  payload: sortBy,
})

export const showDischargeDialog = (id: string): IShowDischargeDialog => ({
  type: ActionTypes.SHOW_DISCHARGE_DIALOG,
  payload: id,
})

export const hideDischargeDialog = (): IHideDischargeDialog => ({
  type: ActionTypes.HIDE_DISCHARGE_DIALOG,
})

export const showAccessCodeDialog = (
  subject: ISubjectDetails
): IShowAccessCodeDialog => ({
  type: ActionTypes.SHOW_ACCESS_CODE_DIALOG,
  payload: subject,
})

export const hideAccessCodeDialog = (): IHideAccessCodeDialog => ({
  type: ActionTypes.HIDE_ACCESS_CODE_DIALOG,
})

export const allInstancesAction = (
  instances: IInstanceDetails[]
): IAllInstancesAction => ({
  type: ActionTypes.ALL_INSTANCES,
  payload: instances,
})

export const newInstanceAction = (
  instance: IInstanceDetails
): INewInstanceAction => ({
  type: ActionTypes.NEW_INSTANCE,
  payload: instance,
})

export const deleteInstanceAction = (
  instanceId: string
): IDeleteInstanceAction => ({
  type: ActionTypes.DELETE_INSTANCE,
  payload: instanceId,
})

export const allUsersAction = (
  users: IDashboardUserDetails[]
): IAllUsersAction => ({
  type: ActionTypes.ALL_USERS,
  payload: users,
})

export const newUserAction = (user: IDashboardUserDetails): INewUserAction => ({
  type: ActionTypes.NEW_USER,
  payload: user,
})

export const termsAction = (terms: ITermsDetails): ITermsAction => ({
  type: ActionTypes.TERMS,
  payload: terms,
})

export const instanceRolesAction = (
  roles: IRoleDetail[]
): IInstanceRolesAction => ({
  type: ActionTypes.ROLES,
  payload: roles,
})

export const consentsAction = (consents: ConsentMap): IConsentsAction => ({
  type: ActionTypes.CONSENTS,
  payload: consents,
})
