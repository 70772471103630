import { API_URL } from '@config/resourceUrls'
import { get } from '@lib/utils/http'
import { ExportSubjectResponse } from '@models/api.models'

export const exportSubject = async (
  token: string,
  siteId: string,
  subjectId: string
): Promise<ExportSubjectResponse> => {
  const resp = await get<ExportSubjectResponse>(
    `${API_URL}/site/${siteId}/subject/${subjectId}/export`,
    token
  )
  if (!resp.parsedBody) {
    throw new Error('Expected response body, got undefined')
  }
  return resp.parsedBody
}
