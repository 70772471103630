import { StatusEnum, statusMap } from '@components/Study/models'
import * as StudyApi from '@lib/data/study.data'
import { StudySiteData } from '@models/data.models'
import { useKeycloak } from '@providers/auth'
import { useCallback, useMemo, useState } from 'react'
import useToast from './useToast'

const useSites = () => {
  const [fetching, setFetching] = useState(false)
  const { token } = useKeycloak()
  const { createToast } = useToast()
  const [sites, setSites] = useState<StudySiteData[]>([])

  const fetchStudySites = useCallback(
    (studyId: string) => {
      setFetching(true)
      return fetchSites(studyId)
        .then((sites) => {
          setSites(sites)
          return sites
        })
        .catch((e) => {
          console.error(e)
          createToast('Failed to load sites', 'error')
        })
        .finally(() => {
          setFetching(false)
        })
    },
    [token]
  )

  const fetchSites = useCallback(
    async (studyId: string) => {
      const sites = await StudyApi.getSites(studyId, token)
      return sites.map((site) => ({
        id: site.id,
        label: site.label,
        region: site.region,
        status: site.status as StatusEnum,
        studyId: site.studyId,
        subjectCount: site.subjectCount,
        dexcomContactInstructions: site.dexcomContactInstructions,
        siteAdmin: site.siteAdministrator,
        actions: ['Export Data'],
      }))
    },
    [token]
  )

  const sortedSites = useMemo(() => {
    return sites.sort(sortBySiteStatus || sortBySiteLabel)
  }, [sites])

  return { sites: sortedSites, fetchStudySites, fetching }
}

const sortBySiteStatus = (a: StudySiteData, b: StudySiteData): number => {
  const aStatus = statusMap.get(a.status) || 0
  const bStatus = statusMap.get(b.status) || 0

  return aStatus === bStatus ? 0 : aStatus > bStatus ? 1 : -1
}

const sortBySiteLabel = (a: StudySiteData, b: StudySiteData): number => {
  const aLabel = a.label
  const bLabel = b.label

  return aLabel > bLabel ? 1 : aLabel < bLabel ? -1 : 0
}

export default useSites
