import React from 'react'
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
  useTheme,
} from '@material-ui/core'
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined'
import { IRoleDetail } from '@models/index'
import { NewStudy } from '@models/api.models'
import useDashboardStyles from '@components/GlobalStyles'

type StudySummaryProps = {
  study: NewStudy
}

const expectedRoles: IRoleDetail[] = [
  {
    id: 'faked1',
    name: 'monitor-devices',
    formattedName: 'Monitor Devices',
    instanceType: 'voyager',
  },
  {
    id: 'faked2',
    name: 'export-data',
    formattedName: 'Export Data',
    instanceType: 'voyager',
  },
  {
    id: 'faked3',
    name: 'manage-roles',
    formattedName: 'Manage Roles',
    instanceType: 'voyager',
  },
]

const StudySummary: React.FunctionComponent<StudySummaryProps> = ({
  study,
}) => {
  const theme = useTheme()
  const classes = useDashboardStyles(theme)

  return (
    <React.Fragment>
      <Grid container item xs={12} spacing={2} id="studySummaryBasicInfo">
        <Grid item xs={12}>
          <Card style={{ width: '100%' }}>
            <CardHeader
              title="Basic Information"
              classes={{
                root: classes.cardRoot,
                title: classes.cardTitle,
                action: classes.cardAction,
              }}
            />
            <Divider />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4} style={{ marginTop: '10pt' }}>
                  <Typography variant="caption" color="textSecondary">
                    Study Type
                  </Typography>
                  <Typography variant="body2" color="textPrimary">
                    {study.type || '--'}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} style={{ marginTop: '10pt' }}>
                  <Typography variant="caption" color="textSecondary">
                    Protocol ID
                  </Typography>
                  <Typography variant="body2" color="textPrimary">
                    {study.protocolId || '--'}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} style={{ marginTop: '10pt' }}>
                  <Typography variant="caption" color="textSecondary">
                    Name
                  </Typography>
                  <Typography variant="body2" color="textPrimary">
                    {study.name || '--'}
                  </Typography>
                </Grid>
                <Grid item xs={12} style={{ marginTop: '10pt' }}>
                  <Typography variant="caption" color="textSecondary">
                    Protocol Title
                  </Typography>
                  <Typography variant="body2" color="textPrimary">
                    {study.protocolTitle || '--'}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid container item xs={12} id="studySummaryDexcomStudyLead">
          <Card style={{ width: '100%' }}>
            <CardHeader
              title="Study Lead"
              classes={{
                root: classes.cardRoot,
                title: classes.cardTitle,
                action: classes.cardAction,
              }}
            />
            <Divider />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} style={{ marginTop: '10pt' }}>
                  <Typography variant="body2" color="textPrimary">
                    {study.dexcomAdministrator || '--'}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {study.sites.map((site) => (
          <Grid
            container
            item
            xs={12}
            key={site.label}
            id={site.label + 'studySummarySiteInfo'}
          >
            <Card style={{ width: '100%' }}>
              <CardHeader
                title={`Site ${site.label || '--'}`}
                classes={{
                  root: classes.cardRoot,
                  title: classes.cardTitle,
                  action: classes.cardAction,
                }}
              />
              <Divider />
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4} style={{ marginTop: '10pt' }}>
                    <Typography variant="caption" color="textSecondary">
                      Country
                    </Typography>
                    <Typography variant="body2" color="textPrimary">
                      {site.country || '--'}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={8} style={{ marginTop: '10pt' }}>
                    <Typography variant="caption" color="textSecondary">
                      Site Admin
                    </Typography>
                    <Typography variant="body2" color="textPrimary">
                      {site.siteAdministrator || '--'}
                    </Typography>
                  </Grid>
                  {site.staff.length > 0 && (
                    <Grid
                      container
                      item
                      xs
                      style={{ marginTop: '20pt' }}
                      justifyContent="space-around"
                    >
                      <Grid container item xs={10}>
                        <Grid container item xs={12} alignItems="center">
                          <Grid item xs={12} md={6}>
                            <Typography variant="caption" color="textSecondary">
                              Staff Member
                            </Typography>
                          </Grid>
                          <Grid
                            container
                            item
                            xs={12}
                            md={6}
                            style={{ textAlign: 'center' }}
                            alignItems="center"
                          >
                            {expectedRoles.map((role) => (
                              <Grid item xs={4} key={role + 'label'}>
                                <Typography
                                  variant="caption"
                                  color="textSecondary"
                                >
                                  {role.formattedName}
                                </Typography>
                              </Grid>
                            ))}
                          </Grid>
                        </Grid>
                        {site.staff.map((staff) => (
                          <Grid
                            container
                            item
                            xs={12}
                            key={'staff' + staff.email}
                            alignItems="center"
                          >
                            <Grid item xs={12} md={6}>
                              <Typography variant="caption" color="textPrimary">
                                {staff.email}
                              </Typography>
                            </Grid>
                            <Grid
                              container
                              item
                              xs={12}
                              md={6}
                              style={{ textAlign: 'center' }}
                            >
                              {expectedRoles.map((role) => (
                                <Grid
                                  item
                                  xs={4}
                                  key={role + 'label'}
                                  alignItems="center"
                                >
                                  {staff.roles.includes(role.name) && (
                                    <CheckOutlinedIcon
                                      style={{
                                        color: theme.palette.secondary.dark,
                                      }}
                                    />
                                  )}
                                </Grid>
                              ))}
                            </Grid>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  )}
                  <Grid item xs={12} style={{ marginTop: '10pt' }}>
                    <Typography variant="caption" color="textSecondary">
                      Contact Instructions
                    </Typography>
                    <Typography variant="body2" color="textPrimary">
                      {site.contactInstructions || '--'}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </React.Fragment>
  )
}
export default StudySummary
