import { MetricsSummary } from "@models/metrics.models"

export enum Actions {
    SET_METRICS = 'SET_METRICS'
}

type SetMetricsAction = {
    type: Actions.SET_METRICS,
    payload: MetricsSummary[]
}

export type Action = 
    | SetMetricsAction

export const setMetricsAction = (summaries: MetricsSummary[]) => ({
    type: Actions.SET_METRICS,
    payload: summaries
})