import { ActionTypes, Action } from '../actions'

export interface ToastState {
  message: string
  severity: 'success' | 'warning' | 'error' | 'info'
  show: boolean
}

export default (state: ToastState, action: Action): ToastState => {
  switch (action.type) {
    case ActionTypes.SET_TOAST:
      return {
        message: action.payload.message,
        severity: action.payload.severity,
        show: true,
      }
    case ActionTypes.HIDE_TOAST:
      return { ...state, show: false }
    default:
      return state
  }
}
