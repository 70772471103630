import { useCallback } from 'react'
import { Socket } from 'socket.io-client'
import { IAcknowledgement, IAlarm } from '@models/index'
import useAlarm from '@providers/data/hooks/useAlarm'
import useWebsocket from './Websocket'

const IO_NAME = '/alarms'
export default () => {
    const { setAlarms, setAllAcknowledgements } = useAlarm()
    const register = useCallback((socket: Socket) => {
        socket.on('ALARM', (data: Record<string, IAlarm[]>) => {
            setAlarms(data)
        })

        socket.on('ACKNOWLEDGEMENT', (data: IAcknowledgement[]) => {
            setAllAcknowledgements(data)
        })
    }, [setAlarms, setAllAcknowledgements])
    useWebsocket(IO_NAME, register)
}
