import React from 'react'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogActions from '@material-ui/core/DialogActions'
import CloseIcon from '@material-ui/icons/Close'
import {
  Button,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Typography,
  useTheme,
} from '@material-ui/core'

import useDashboardStyles from '@components/GlobalStyles'

type DeleteEventDialogProps = {
  show: boolean
  onCancel: () => void
  onDelete: () => void
}

const DeleteEventDialog = (props: DeleteEventDialogProps) => {
  const theme = useTheme()
  const classes = useDashboardStyles(theme)

  return (
    <Dialog
      open={props.show}
      onClose={props.onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <MuiDialogTitle>
        <Typography style={{ color: theme.palette.text.secondary }}>
          Delete Event
        </Typography>
        <IconButton
          className={classes.closeButton}
          aria-label="close"
          onClick={props.onCancel}
        >
          <CloseIcon />
        </IconButton>
      </MuiDialogTitle>
      <Divider />
      <Grid container justify="space-around" alignItems={'center'}>
        <Grid
          item
          xs={9}
          style={{ marginTop: 10, minHeight: 30, textAlign: 'center' }}
        >
          Are you sure you want to delete this event?
        </Grid>
      </Grid>

      <MuiDialogActions>
        <Button onClick={props.onCancel} color="default" variant="outlined">
          Cancel
        </Button>
        <Button
          onClick={props.onDelete}
          color="primary"
          variant="contained"
          autoFocus
        >
          Delete
        </Button>
      </MuiDialogActions>
    </Dialog>
  )
}

export default DeleteEventDialog
