import React from 'react'
import { useHistory } from 'react-router'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogActions from '@material-ui/core/DialogActions'
import CloseIcon from '@material-ui/icons/Close'
import {
  Button,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Typography,
  useTheme,
} from '@material-ui/core'

import useDashboardStyles from '@components/GlobalStyles'
import useDischarge from '@providers/data/hooks/useDischarge'
import useParticipantActions from '@providers/data/hooks/useDataRequests'

const DischargeDialog: React.FunctionComponent = () => {
  const { open, id, hideDialog } = useDischarge()
  const { discharge } = useParticipantActions()
  const history = useHistory()
  const theme = useTheme()
  const classes = useDashboardStyles(theme)

  const handleClose = () => {
    hideDialog()
  }
  const handleAction = () => {
    discharge(id)
    handleClose()
    history.push('/')
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <MuiDialogTitle>
        <Typography style={{ color: theme.palette.text.secondary }}>
          Discharge Patient
        </Typography>
        <IconButton
          className={classes.closeButton}
          aria-label="close"
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </MuiDialogTitle>
      <Divider />
      <Grid container justify="space-around" alignItems={'center'}>
        <Grid
          item
          xs={9}
          style={{ marginTop: 10, minHeight: 30, textAlign: 'center' }}
        >
          Are you sure you want to discharge this patient?
        </Grid>
      </Grid>

      <MuiDialogActions>
        <Button onClick={handleClose} color="default" variant="outlined">
          Cancel
        </Button>
        <Button
          onClick={handleAction}
          color="primary"
          variant="contained"
          autoFocus
        >
          Discharge
        </Button>
      </MuiDialogActions>
    </Dialog>
  )
}

export default DischargeDialog
