import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Grid, Paper, Typography, useTheme } from '@material-ui/core'
import { SiteViewEnum } from '@models/index'
import { StatusEnum } from './models'
import Spinner from '@components/Spinner/Spinner'
import EditableProtocolTitle from './EditableProtocolTitle'
import { SiteList } from '@components/Site/SiteList'
import useStudy from '@providers/data/hooks/useStudy'
import useSites from '@providers/data/hooks/useSites'
import useUserConfig from '@providers/data/hooks/useUserConfig'
import { useKeycloak } from '@providers/auth'
import BreadcrumbNav from '@components/Site/BreadcrumbNav'
import { EmptyInfoContainer } from '@components/EmptyInfoContainer'

export default function StudyDetails() {
  const history = useHistory()
  const { studyId } = useParams<{ studyId: string }>()
  const { setUserConfigSiteView: setUserConfigStudyView } = useUserConfig()
  const { study, fetching: studyFetch, error } = useStudy(studyId)
  const { sites, fetching: sitesFetch, fetchStudySites } = useSites()
  const { keycloak } = useKeycloak()

  useEffect(() => {
    fetchStudySites(studyId)
  }, [studyId])

  const handleStudySiteClicked = (siteId: string) => {
    const url = `/study/${studyId}/site/${siteId}`

    const site = sites.find((site) => site.id === siteId)

    if (site) {
      site.status.toLowerCase() === StatusEnum.CREATED
        ? setUserConfigStudyView(SiteViewEnum.SETTINGS_VIEW)
        : setUserConfigStudyView(SiteViewEnum.SUBJECTS_VIEW)
    }

    history.push(url)
  }

  const handleRemoveStudySite = () => {
    console.log('Study site remove clicked')
  }

  if (error) {
    history.push('/404')
  }

  if (studyFetch || sitesFetch) {
    return <Spinner />
  }

  if (!study) {
    return (
      <React.Fragment>
        <BreadcrumbNav studyId={studyId} studyName={'Unknown'} />
        <EmptyInfoContainer infoText="Study Information Unavailable" />
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <BreadcrumbNav
          studyId={studyId}
          studyName={study.name || study.protocolId}
        />

        <Grid container item xs={12} id="studyDetailsArea">
          <EditableProtocolTitle
            protocolTitle={study.protocolTitle}
            canEdit={keycloak.hasRealmRole('study-lead')}
          />
        </Grid>
        <Grid container item xs={12} id="studySitesListDetailsArea">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h4" color="textSecondary">
                Sites
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Paper elevation={1}>
                <SiteList
                  sites={sites}
                  onDelete={handleRemoveStudySite}
                  onStudySiteClick={handleStudySiteClicked}
                />
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
