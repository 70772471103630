import { IDashboardUserDetails } from '@models/index'
import { Action, ActionTypes } from '../actions'

export default (
  state: IDashboardUserDetails[],
  action: Action
): IDashboardUserDetails[] => {
  switch (action.type) {
    case ActionTypes.ALL_USERS: {
      return [...action.payload]
    }
    case ActionTypes.NEW_USER: {
      return [...state, action.payload]
    }
    default:
      return state
  }
}
