export enum IndicatorStatus {
    OK,
    WARNING,
    ERROR,
    UNKNOWN
}

export type ComposedSubject = {
    id: string
    label: string
    status: string
    indicator: IndicatorStatus
    lastEgv: string
    firstEgv: string
    sessionStart: string
    sessionEnd: string
}