import { useCallback, useState } from 'react'
import { useKeycloak } from '@providers/auth'
import useToast from '@providers/data/hooks/useToast'
import { addParticipant } from '@lib/fetch-requests'
import { INewParticipant } from '@models/form.models'

export default () => {
  const { token } = useKeycloak()
  const { createToast } = useToast()
  const [addParticipantFormOpen, setAddParticipantFormOpen] = useState(false)

  const createParticipant = useCallback(
    (data: INewParticipant) =>
      addParticipant(token, data)
        .then((participant) => participant.id)
        .catch((e) => {
          console.log(e)
          createToast('Error - Could not create participant', 'error')
          throw e
        }),
    [token, createToast]
  )

  return {
    createParticipant,
    addParticipantFormOpen,
    setAddParticipantFormOpen,
  }
}
