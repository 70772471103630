export function required(value: string): string {
  if (!value) {
    return 'Required'
  }

  return ''
}

export function validateOption(options: string[], value: string): string {
  if (!options.includes(value)) {
    return 'Invalid'
  }

  return ''
}

export function validateLength(maxLength: number, value: string): string {
  if (value.length > maxLength) {
    return `Cannot exceed ${maxLength} characters`
  }

  return ''
}

export function validateEmail(email: string): string {
  const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/

  if (!emailRegex.test(email)) {
    return 'Must be an email address'
  }

  return ''
}

export function validateDexcomEmail(email: string): string {
  if (!email.endsWith('@dexcom.com')) {
    return "Must end in '@dexcom.com'"
  }

  return ''
}

export function validateContactInstructions(instructions: string): string {
  const error = validateLength(500, instructions)
  return error
}
