import { StudySubject } from "@models/api.models"
import { Action, Actions } from "./subject.actions"

export type SubjectState = {
    subjects: Record<string, StudySubject>
}

const reduceSubjects = (state: Record<string, StudySubject>, subjects: StudySubject[]): Record<string, StudySubject> => {
    const newState = { ...state }
    subjects.forEach(s => {
        newState[s.id] = s
    })
    return newState
}

export default (state: SubjectState, action: Action): SubjectState => {
    switch (action.type) {
        case Actions.SET_SUBJECTS:
            return {
                ...state,
                subjects: reduceSubjects(state.subjects, action.payload)
            }
        default:
            return state
    }
}