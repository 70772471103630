import { Action, ActionTypes } from "../actions";

export interface DischargeState {
  id: string
  showDialog: boolean
}

export default (state: DischargeState, action: Action): DischargeState => {
  switch(action.type){
    case ActionTypes.SHOW_DISCHARGE_DIALOG:
      return { id: action.payload, showDialog: true }
    case ActionTypes.HIDE_DISCHARGE_DIALOG:
      return { id: '', showDialog: false }
    default: 
      return state
  }
}