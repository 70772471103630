import { useContext, useEffect } from 'react'
import { useKeycloak } from '@providers/auth'
import { addInstance, getInstances, deleteInstance } from '@lib/fetch-requests'
import { IInstanceDetails } from '@models/index'
import { initializeSandbox } from '@lib/sandbox'

import {
  allInstancesAction,
  newInstanceAction,
  deleteInstanceAction,
} from '../actions'
import { DataContext } from '../data.provider'
import useToast from './useToast'

interface InstancesData {
  instances: IInstanceDetails[]
  newInstance: (
    instanceId: string,
    adminUsername: string,
    password: string,
    sandbox?: boolean,
    voyager?: boolean
  ) => void
  removeInstance: (instanceId: string) => void
}

const useInstances = (): InstancesData => {
  const ctx = useContext(DataContext)

  if (!ctx) {
    throw new Error(
      'useInstances hook must be used inside DataProvider context'
    )
  }

  const { state, dispatch } = ctx
  const { token } = useKeycloak()
  const { createToast } = useToast()

  useEffect(() => {
    getInstances(token)
      .then((allInstances) => {
        dispatch(allInstancesAction(allInstances))
      })
      .catch((e) => {
        console.error(e)
        createToast('Failed To Load Instances', 'error')
      })
    // eslint-disable-next-line
  }, [])

  const newInstance = (
    instanceId: string,
    adminUsername: string,
    password: string,
    sandbox?: boolean,
    voyager?: boolean
  ) => {
    addInstance(token, instanceId, adminUsername, password, voyager)
      .then((instance) => {
        createToast('Instance Created')
        dispatch(newInstanceAction(instance))
        console.log(instance)
        return instance
      })
      .then(async (instance) => {
        if (sandbox) {
          createToast('Seeding Sandbox Instance Data')
          await initializeSandbox(instance.instanceId, token)
          createToast('Complete Sandbox Initialization')
        }
        return instance
      })
      .catch((e) => {
        console.log(e)
        createToast('Instance Create Failed', 'error')
      })
  }

  const removeInstance = (instanceId: string) => {
    deleteInstance(token, instanceId)
      .then(() => {
        dispatch(deleteInstanceAction(instanceId))
        createToast('Instance Deleted')
      })
      .catch((e) => {
        console.log(e)
        createToast('Failed to delete instance', 'error')
      })
  }

  return {
    instances: state.instances,
    newInstance,
    removeInstance,
  }
}

export default useInstances
