import { useTheme } from '@material-ui/core'
import React from 'react'
import {
  ResponsiveContainer,
  ScatterChart,
  ReferenceArea,
  Dot,
  Scatter,
  XAxis,
  YAxis,
  Rectangle,
  Tooltip,
} from 'recharts'
import TIME_RANGE from './TimeRange'
import { formatDate } from '@lib/utils/dates'
import { grey } from '@material-ui/core/colors'

const formatDateTime = (dateTicks: number): string => {
  let hours = new Date(dateTicks).getHours()
  const ampm = hours >= 12 ? 'PM' : 'AM'
  hours = hours % 12
  hours = hours ? hours : 12
  return `${hours}${ampm}`
}

const getScale = (timeRange: TIME_RANGE): number => {
  switch (timeRange) {
    case TIME_RANGE.RANGE_3_HOURS:
      return 1
    case TIME_RANGE.RANGE_6_HOURS:
      return 2
    case TIME_RANGE.RANGE_12_HOURS:
      return 4
    case TIME_RANGE.RANGE_24_HOURS:
      return 6
    case TIME_RANGE.RANGE_72_HOURS:
      return 10
    default:
      return 0
  }
}

const getXTicks = (timeRange: TIME_RANGE, now: number) => {
  const hourMilli = 60 * 60 * 1000
  const nowJustHour = new Date(now).setMinutes(0, 0, 0)

  let ticks = [now]

  switch (timeRange) {
    case TIME_RANGE.RANGE_3_HOURS: {
      ticks = [nowJustHour - hourMilli * 2, nowJustHour - hourMilli, ...ticks]
      break
    }
    case TIME_RANGE.RANGE_6_HOURS: {
      ticks = [
        nowJustHour - hourMilli * 4,
        nowJustHour - hourMilli * 2,
        ...ticks,
      ]
      break
    }
    case TIME_RANGE.RANGE_12_HOURS: {
      ticks = [
        nowJustHour - hourMilli * 9,
        nowJustHour - hourMilli * 6,
        nowJustHour - hourMilli * 3,
        ...ticks,
      ]
      break
    }
    case TIME_RANGE.RANGE_24_HOURS: {
      ticks = [
        nowJustHour - hourMilli * 18,
        nowJustHour - hourMilli * 12,
        nowJustHour - hourMilli * 6,
        ...ticks,
      ]
      break
    }
    case TIME_RANGE.RANGE_72_HOURS: {
      ticks = [
        nowJustHour - hourMilli * 54,
        nowJustHour - hourMilli * 36,
        nowJustHour - hourMilli * 18,
        ...ticks,
      ]
    }
  }

  return ticks
}

// eslint-disable-next-line
const EgvTooltip = ({ active, payload }: any) => {
  if (active) {
    return (
      <div
        style={{
          backgroundColor: 'white',
          padding: 5,
          border: '1px solid grey',
        }}
      >
        <div>{payload[1].value} mg/dL</div>
        <div>{formatDate(new Date(payload[0].value))}</div>
      </div>
    )
  }

  return <div></div>
}

interface Data {
  value: number
  systemtime: number
}

interface ChartProps {
  timeRange: TIME_RANGE
  data: Data[]
  highThresh: number
  lowThresh: number
}

const Chart: React.FunctionComponent<ChartProps> = ({
  data,
  timeRange,
  highThresh,
  lowThresh,
}) => {
  const now = Date.now()
  const theme = useTheme()

  const tickFormatter = (timeStr: string | undefined) => {
    if (!timeStr) {
      return 'Unknown'
    }

    const time = Number.parseInt(timeStr)
    if (Number.isNaN(time)) {
      return 'Unknown'
    }

    if (time === now) {
      return 'Now'
    }

    return formatDateTime(time)
  }

  return (
    <ResponsiveContainer width="100%" aspect={2}>
      <ScatterChart>
        <ReferenceArea
          y1={highThresh + 1}
          fill="lightyellow"
          shape={<Rectangle radius={[10, 10, 0, 0]} />}
        />
        <ReferenceArea y1={lowThresh + 1} y2={highThresh - 1} />
        <ReferenceArea
          y1={0}
          y2={lowThresh - 1}
          fill="lightsalmon"
          shape={<Rectangle radius={[0, 0, 10, 10]} />}
        />
        <XAxis
          type="number"
          dataKey="systemtime"
          domain={[
            (dataMin: number) => dataMin - 100000 * getScale(timeRange),
            (dataMax: number) => dataMax + 100000 * getScale(timeRange),
          ]}
          ticks={getXTicks(timeRange, now)}
          tickFormatter={tickFormatter}
          axisLine={false}
          style={{ fill: theme.palette.text.primary }}
        />
        <YAxis
          type="number"
          dataKey="value"
          domain={[0, 410]}
          ticks={[40, 100, 200, 300, 400]}
          orientation="right"
          axisLine={false}
          style={{ fill: theme.palette.text.primary }}
        />
        <Tooltip
          wrapperStyle={{ color: grey[900] }}
          isAnimationActive={false}
          cursor={false}
          content={<EgvTooltip />}
        />
        <Scatter
          data={[...data.slice(0, data.length - 1)]}
          shape={<Dot r={2} />}
          isAnimationActive={false}
        />
        <Scatter
          data={[...data.slice(data.length - 1)]}
          shape={<Circle />}
          isAnimationActive={false}
        />
      </ScatterChart>
    </ResponsiveContainer>
  )
}

const Circle: React.FC = (props) => (
  <circle {...props} r={4} stroke="black" strokeWidth={2} fill="white" />
)

export default Chart
