import { useState, useEffect, useCallback } from 'react'
import { REACT_APP_REST_SERVICE_URL } from '@config/env'
import { get } from '@lib/utils/http'
import { useKeycloak } from '@providers/auth'

type Invitation = {
    siteId: string
    studyId: string
    subjectId: string
    qrCode: string
    shortCode: {
        value: string
        expiration: Date
    }
}

type State = {
  invitation: null | Invitation
  status: 'loading' | 'error' | 'ready' | 'refreshing'
  error: string
}

export default (subjectId: string, siteId: string, studyId: string) => {
  const { token } = useKeycloak()
  const [state, setState] = useState<State>({
    invitation: null,
    status: 'ready',
    error: '',
  })

  useEffect(() => {
    let subscribed = true
    
    let url = ''
    if (state.status === 'loading')
        url = `${REACT_APP_REST_SERVICE_URL}/api/study/${studyId}/site/${siteId}/subject/${subjectId}/invitation`
    else if (state.status === 'refreshing')
        url = `${REACT_APP_REST_SERVICE_URL}/api/study/${studyId}/site/${siteId}/subject/${subjectId}/shortCode`
    
    if (url !== '') {
      get<Invitation>(url, token)
        .then((resp) => {
          if (!resp.parsedBody) {
            throw new Error('No response')
          }
          return resp.parsedBody
        })
        .then((invite) => {
          if (subscribed) {
            handleInvitation(invite)
          }
        })
        .catch((e) => {
          if (subscribed) {
            setState((prev) => ({
              ...prev,
              status: 'error',
              error: e.message,
            }))
          }
        })
    }
    return () => {
      subscribed = false
    }
  }, [state.status, subjectId, siteId, studyId, token])

  const fetchInvitation = useCallback(() => {
    setState((prev) => ({
      ...prev,
      status: 'loading',
      error: '',
    }))
  }, [])

  const refreshShortCode = useCallback(() => {
    setState(prev => ({
        ...prev,
        status: 'refreshing',
        error: ''
    }))
  }, [])

  const handleInvitation = (invite: Invitation) => {
    invite.shortCode.expiration = new Date(invite.shortCode.expiration)
    setState((prev) => ({
        ...prev,
        invitation: invite,
        status: 'ready'
    }))
  }

  return {
    status: state.status,
    invitation: state.invitation,
    error: state.error,
    fetchInvitation,
    refreshShortCode,
  }
}
