import { useContext, useEffect } from 'react'
import useToast from './useToast'
import useDataRequests from './useDataRequests'
import { ConsentMap, Consent } from '@models/api.models'
import { DataContext } from '../data.provider'
import { consentsAction } from '../actions'
import { useKeycloak } from '@providers/auth'

interface ConsentState {
  consents: ConsentMap
}
type ConsentProps = {
  studyId: string
  siteId: string
}

const useConsent = (props: ConsentProps): ConsentState => {
  const ctx = useContext(DataContext)

  if (!ctx) {
    throw new Error('useConsent hook must be used inside DataProvider context')
  }

  const { state, dispatch } = ctx
  const { createToast } = useToast()
  const { getConsents } = useDataRequests()

  useEffect(() => {
    getConsents(props.studyId, props.siteId)
      .then((consents) => {
        dispatch(consentsAction(consents))
      })
      .catch((e) => {
        console.log(e)
        createToast('Failed To Load Study Site Consents', 'error')
      })
    // eslint-disable-next-line
  }, [props.studyId, props.siteId])

  return {
    consents: state.consentsState.consents,
  }
}

export default useConsent
