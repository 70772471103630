import {
  DashboardView,
  IAcknowledgement,
  IDashboardUserDetails,
  IInstanceDetails,
  IRoleDetail,
  SiteViewEnum,
} from '@models/index'
import { CUSTOM_SORT_OPTIONS } from '@models/sortOptions.models'
import React, {
  createContext,
  FunctionComponent,
  useEffect,
  useReducer,
} from 'react'
import { Action, tickAction } from './actions'
import { StudyAction } from './actions/study.actions'
import accessCodeReducer, {
  AccessCodeState,
} from './reducers/accesscode.reducer'
import acknowledgementReducer from './reducers/acknowledgement.reducer'
import configReducer, { ConfigState } from './reducers/config.reducer'
import consentsReducer, { ConsentsState } from './reducers/consents.reducer'
import customSortReducer, { SortByState } from './reducers/customSort.reducer'
import dischargeReducer, { DischargeState } from './reducers/discharge.reducer'
import eventRulesReducer, {
  EventRuleState,
} from './reducers/eventRules.reducer'
import instanceReducer from './reducers/instance.reducer'
import instanceRolesReducer from './reducers/instanceRoles.reducer'
import participantDataReducer, {
  ParticipantDataState,
} from './reducers/participantData.reducer'
import studyReducer, { StudyState } from './reducers/study.reducer'
import termsReducer, { TermsState } from './reducers/terms.reducer'
import toastReducer, { ToastState } from './reducers/toast.reducer'
import userReducer from './reducers/user.reducer'

type InitialStateType = {
  participantData: ParticipantDataState
  config: ConfigState
  toast: ToastState
  sortBy: SortByState
  dischargeState: DischargeState
  accessCodeState: AccessCodeState
  acknowledgements: IAcknowledgement[]
  instances: IInstanceDetails[]
  users: IDashboardUserDetails[]
  eventRules: EventRuleState
  studies: StudyState
  terms: TermsState
  instanceRoles: IRoleDetail[]
  consentsState: ConsentsState
}

const initialState: InitialStateType = {
  participantData: {},
  acknowledgements: [],
  config: {
    instanceConfig: undefined,
    userConfig: {
      dashboardView: DashboardView.LIST_VIEW,
      studyView: SiteViewEnum.SUBJECTS_VIEW,
    },
  },
  toast: {
    message: '',
    severity: 'success',
    show: false,
  },
  sortBy: CUSTOM_SORT_OPTIONS.TIME_OUT_OF_RANGE,
  dischargeState: {
    id: '',
    showDialog: false,
  },
  accessCodeState: {
    subjectId: '',
    siteId: '',
    studyId: '',
    showDialog: false,
  },
  instances: [],
  users: [],
  eventRules: {},
  studies: {},
  terms: {
    terms: { id: '', studyId: '', terms: '', created: '', updated: '' },
  },
  instanceRoles: [],
  consentsState: {
    consents: {
      title: '',
      body: '',
      consentList: [{ id: '', label: '', subHeading: '' }],
    },
  },
}

const reducer = (
  {
    participantData,
    config,
    toast,
    sortBy,
    dischargeState,
    accessCodeState,
    acknowledgements,
    instances,
    users,
    eventRules,
    studies,
    terms,
    instanceRoles,
    consentsState,
  }: InitialStateType,
  action: Action
) => {
  return {
    participantData: participantDataReducer(
      participantData,
      config.instanceConfig,
      acknowledgements,
      action
    ),
    config: configReducer(config, action),
    toast: toastReducer(toast, action),
    sortBy: customSortReducer(sortBy, action),
    dischargeState: dischargeReducer(dischargeState, action),
    accessCodeState: accessCodeReducer(accessCodeState, action),
    acknowledgements: acknowledgementReducer(acknowledgements, action),
    instances: instanceReducer(instances, action),
    users: userReducer(users, action),
    eventRules: eventRulesReducer(eventRules, action),
    studies: studyReducer(studies, action as StudyAction),
    terms: termsReducer(terms, action),
    instanceRoles: instanceRolesReducer(instanceRoles, action),
    consentsState: consentsReducer(consentsState, action),
  }
}

const DataProvider: FunctionComponent = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    const interval = setInterval(() => dispatch(tickAction(Date.now())), 60000)
    return () => clearInterval(interval)
  }, [])

  return (
    <DataContext.Provider value={{ state, dispatch }}>
      {children}
    </DataContext.Provider>
  )
}

const DataContext = createContext<{
  state: InitialStateType
  dispatch: React.Dispatch<Action>
}>({
  state: initialState,
  dispatch: () => null,
})

export { DataContext, DataProvider }
