import React from 'react'
import { Button, Grid } from '@material-ui/core'

type EditAndSaveButtonsProps = {
  editButtonText: string
  canSave: boolean
  canEdit: boolean
  onSave: () => void
  onEdit?: (canEdit: boolean) => void
  onCancel?: () => void
  disabledButtons?: boolean
}
export const EditAndSaveButtons: React.FunctionComponent<
  EditAndSaveButtonsProps
> = ({
  editButtonText,
  canSave,
  canEdit,
  onEdit,
  onCancel,
  onSave,
  disabledButtons = false,
}) => {
  const handleEditClicked = () => {
    canEdit = !canEdit
    if (onEdit) {
      onEdit(canEdit)
    }
  }

  const handleCancelClicked = () => {
    canEdit = false
    canSave = false
    if (onCancel) {
      onCancel()
    }
  }

  const handleSaveClicked = () => {
    if (onSave) {
      onSave()
    }
  }

  const disabledSave = !canSave || disabledButtons

  return canEdit ? (
    <Grid container item xs={12} spacing={1}>
      <Grid item xs={6}>
        <Button
          color="secondary"
          variant="outlined"
          style={{
            textTransform: 'initial',
            width: '100%',
          }}
          onClick={handleCancelClicked}
          disabled={disabledButtons}
        >
          Cancel
        </Button>
      </Grid>
      <Grid item xs={6}>
        <Button
          color="primary"
          variant="contained"
          style={{
            textTransform: 'initial',
            width: '100%',
          }}
          onClick={handleSaveClicked}
          disabled={disabledSave}
        >
          Save
        </Button>
      </Grid>
    </Grid>
  ) : (
    <Grid item xs={12}>
      <Button
        color="primary"
        variant="outlined"
        style={{
          textTransform: 'initial',
          width: '100%',
        }}
        onClick={handleEditClicked}
        disabled={disabledButtons}
      >
        {editButtonText}
      </Button>
    </Grid>
  )
}
