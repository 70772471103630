import React, { useEffect, useState } from 'react'
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  useTheme,
} from '@material-ui/core'
import { EditAndSaveButtons } from '@components/EditAndSaveButtons'
import useSiteTerms from '@providers/data/hooks/useSiteTerms'
import useDashboardStyles from '@components/GlobalStyles'

const hasChanged = (current: string, next: string): boolean => {
  return current !== next
}

const validateFields = (data: string) => {
  return data !== ''
}

const valid = (terms: string) => {
  return validateFields(terms)
}

type TermsAndConditionsProps = {
  siteId: string
}

export default (props: TermsAndConditionsProps) => {
  const theme = useTheme()
  const classes = useDashboardStyles(theme)
  const { terms, saveTerms } = useSiteTerms(props.siteId)
  const [state, setState] = useState('')
  const [canSave, setCanSave] = useState(false)
  const [canEdit, setCanEdit] = useState(false)

  useEffect(() => {
    setState(terms.terms)
  }, [terms])

  useEffect(() => {
    if (hasChanged(terms.terms, state) && valid(state)) {
      setCanSave(true)
    } else {
      setCanSave(false)
    }
  }, [state, terms])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState(event.target.value)
  }

  const handleEditClicked = (isEditable: boolean) => {
    setCanEdit(isEditable)
  }

  const handleCancelClicked = () => {
    setCanEdit(false)
    setCanSave(false)
    setState(terms.terms)
  }

  const handleSaveClicked = () => {
    saveTerms(state)
    setCanEdit(false)
    setCanSave(false)
  }

  const helpText = {
    terms: 'Terms cannot be blank.',
  }

  return (
    <Card>
      <CardHeader
        title="Terms"
        classes={{
          root: classes.cardRoot,
          title: classes.cardTitle,
          action: classes.cardAction,
        }}
        action={
          <EditAndSaveButtons
            canEdit={canEdit}
            canSave={canSave}
            onSave={handleSaveClicked}
            onEdit={handleEditClicked}
            onCancel={handleCancelClicked}
            editButtonText="Edit"
          />
        }
      />
      <Divider />
      <CardContent>
        <TextField
          error={!validateFields(state)}
          id="terms"
          value={state}
          variant="outlined"
          multiline
          onChange={handleChange}
          helperText={validateFields(state) ? undefined : helpText.terms}
          style={{ width: '100%' }}
          disabled={!canEdit}
        />
      </CardContent>
    </Card>
  )
}
