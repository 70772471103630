import { MetricsSummary } from "@models/metrics.models"
import { Action, Actions } from "./metrics.actions"

export type MetricsState = {
    metrics: Record<string, MetricsSummary>
}

const reduceMetrics = (state: Record<string, MetricsSummary>, summaries: MetricsSummary[]): Record<string, MetricsSummary> => {
    const newState = { ...state }
    summaries.forEach(s => {
        newState[s.subjectId] = s
    })
    return newState
}

export default (state: MetricsState, action: Action): MetricsState => {
    switch(action.type) {
        case Actions.SET_METRICS:
            return {
                ...state,
                metrics: reduceMetrics(state.metrics, action.payload)
            }
        default:
            return state
    }
}