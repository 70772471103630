import React from 'react'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import CardContent from '@material-ui/core/CardContent'
import useDashboardStyles from '@components/GlobalStyles'
import Spinner from '@components/Spinner/Spinner'
import { EmptyInfoContainer } from '@components/EmptyInfoContainer'
import { useTheme } from '@material-ui/core/styles'
import { getSiteContactInstructions } from '@lib/data/contactInstructions.data'
import { useKeycloak } from '@providers/auth'
import useCancellable from '@lib/hooks/useCancellable'
import { SiteContactInstructionsResponse } from '@models/api.models'

type ContactTabProps = {
  siteId: string
}

export default function ContactTab(props: ContactTabProps) {
  const theme = useTheme()
  const classes = useDashboardStyles(theme)
  const { token } = useKeycloak()
  const contactInstructions = useCancellable<SiteContactInstructionsResponse>(
    () => getSiteContactInstructions(token, props.siteId)
  )

  if (contactInstructions.fetching) {
    return <Spinner />
  }

  if (!contactInstructions.data || contactInstructions.error) {
    return <EmptyInfoContainer infoText="No Contact Instructions Found." />
  }

  return (
    <Card>
      <CardHeader
        title="Contact Instructions"
        classes={{
          root: classes.cardRoot,
          title: classes.cardTitle,
          action: classes.cardAction,
        }}
        color="textSecondary"
      />
      <Divider />
      <CardContent>
        <Grid container spacing={2}>
          <Grid container item xs>
            <Grid item xs>
              {contactInstructions.data.contactInstructions}
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
