import { useEffect, useRef } from "react"

// eslint-disable-next-line
export default (value: any) => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })

  return ref.current
}
