import React from 'react'
import { Button, Grid, Typography } from '@material-ui/core'
import { Role } from '@models/access.models'
import { SiteStaff } from '@models/staff.models'
import { Permissions } from '@components/Staff/PermissionsControls'

type ReadonlyStaffRowProps = {
  staff: SiteStaff
  permissionOptions: Record<string, Role>
}

const ReadonlyStaffRow: React.FunctionComponent<ReadonlyStaffRowProps> = ({
  staff,
  permissionOptions,
}) => {
  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={6} md={8}>
        <Button href={`mailto:${staff.email.trim()}`}>
          <Typography variant="h6" color="textSecondary">
            {staff.email}
          </Typography>
        </Button>
      </Grid>

      <Grid
        container
        item
        xs={6}
        md={4}
        alignItems={'center'}
        justifyContent={'center'}
        style={{ textAlign: 'center' }}
      >
        <Permissions
          disabled={true}
          definitions={permissionOptions}
          permissions={staff.roles}
          handleChangePermission={() => {
            console.log('noop')
          }}
        />
      </Grid>
    </Grid>
  )
}

export default ReadonlyStaffRow
