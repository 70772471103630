import React from 'react'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined'
import DeleteIcon from '@material-ui/icons/Delete'
import { StudyErrors } from './models'
import { NewStudy } from '@models/api.models'
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
  useTheme,
} from '@material-ui/core'
import {
  Permissions,
  PermissionsHeader,
} from '@components/Staff/PermissionsControls'
import { dexcomStaffRoles } from '@models/access.models'

const contactInstructionsTitle = `Site staff will see these contact instructions.

Consider including information such as who to contact for different types of questions (e.g. site lead for equipment and study lead for protocol questions.)

If including phone numbers, consider specifying appropriate times to call.`

type CreateStudyStaffAndContactFormProps = {
  values: NewStudy
  errors: StudyErrors
  onFieldChange: (key: string, value: string) => void
  onSiteFieldChange: (siteIndex: number, key: string, value: string) => void
  onSiteFieldChangeOnAllSites: (key: string, value: string) => void
  onStaffFieldChange: (
    siteIndex: number,
    staffIndex: number,
    key: string,
    value: string
  ) => void
  onStaffRoleChange: (
    siteIndex: number,
    staffIndex: number,
    role: string,
    add: boolean
  ) => void
  onAddStaff: (siteIndex: number) => void
  onRemoveStaff: (siteIndex: number, staffIndex: number) => void
}

const CreateStudyStaffAndContactForm = (
  props: CreateStudyStaffAndContactFormProps
) => {
  const theme = useTheme()
  const handleFieldChange =
    (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value
      props.onFieldChange(field, value)
    }
  const handleSiteFieldChange =
    (index: number, field: string) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value

      if (
        field === 'contactInstructions' &&
        props.values.type === 'Dexcom External Research (IIS)'
      ) {
        props.onSiteFieldChangeOnAllSites(field, value)
      } else {
        props.onSiteFieldChange(index, field, value)
      }
    }

  const handleStaffFieldChange =
    (siteIndex: number, staffIndex: number, field: string) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value
      props.onStaffFieldChange(siteIndex, staffIndex, field, value)
    }

  const handleStaffRoleChange =
    (siteIndex: number, staffIndex: number) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, checked } = event.target
      props.onStaffRoleChange(siteIndex, staffIndex, name, checked)
    }

  const handleAddStaff = (siteIndex: number) => () => {
    props.onAddStaff(siteIndex)
  }

  const handleRemoveStaff = (siteIndex: number, staffIndex: number) => () => {
    props.onRemoveStaff(siteIndex, staffIndex)
  }

  const { values, errors } = props

  return (
    <React.Fragment>
      {errors.siteInfo && (
        <Grid
          container
          justifyContent="center"
          alignItems={'center'}
          style={{
            textAlign: 'center',
            minHeight: '25vh',
          }}
        >
          <Grid item xs={12}>
            <Typography variant={'h5'} color="error">
              {errors.siteInfo}
            </Typography>
          </Grid>
        </Grid>
      )}
      {!errors.siteInfo && (
        <React.Fragment>
          <Grid container justifyContent="center" alignItems={'center'}>
            <Grid item xs={8} style={{ marginTop: 10 }}>
              <TextField
                id="dexcomAdministrator"
                label="Dexcom Study Lead"
                variant="outlined"
                value={values.dexcomAdministrator}
                error={errors.dexcomAdministrator !== ''}
                helperText={errors.dexcomAdministrator}
                title="Please enter the Dexcom study administrator email address.  This must be a Dexcom email address."
                style={{
                  width: '100%',
                  backgroundColor: theme.palette.background.paper,
                }}
                onChange={handleFieldChange('dexcomAdministrator')}
              />
            </Grid>
          </Grid>
          {values.type === 'Dexcom External Research (IIS)' && (
            <Grid container justifyContent="center" alignItems={'center'}>
              <Grid item xs={8} style={{ marginTop: 10 }}>
                <TextField
                  id="contactInstructions"
                  label="Dexcom Study Team Contact Instructions"
                  variant="outlined"
                  multiline
                  rows={8}
                  value={values.sites[0].contactInstructions}
                  error={
                    errors.sites[0] &&
                    errors.sites[0].contactInstructions !== ''
                  }
                  helperText={
                    errors.sites[0] ? errors.sites[0].contactInstructions : ''
                  }
                  title={contactInstructionsTitle}
                  style={{
                    width: '100%',
                    backgroundColor: theme.palette.background.paper,
                  }}
                  onChange={handleSiteFieldChange(0, 'contactInstructions')}
                />
              </Grid>
            </Grid>
          )}
          {values.type === 'Dexcom-Sponsored' && (
            <Grid
              container
              justifyContent="center"
              alignItems={'center'}
              style={{ marginTop: 10 }}
            >
              {values.sites.map((site, idx) => {
                const siteErrors = errors.sites[idx]
                return (
                  <Grid
                    container
                    item
                    xs={8}
                    alignItems={'center'}
                    style={{ marginTop: '10pt' }}
                    key={idx}
                  >
                    <Card
                      style={{
                        backgroundColor: theme.palette.background.paper,
                        marginBottom: 10,
                        width: '100%',
                      }}
                      elevation={1}
                    >
                      <CardHeader title={'Site ' + site.label} />
                      <Divider />

                      <CardContent>
                        {site.staff.length === 0 && (
                          <Grid item xs={12} style={{ textAlign: 'center' }}>
                            <Button
                              startIcon={<PersonAddOutlinedIcon />}
                              variant="contained"
                              onClick={handleAddStaff(idx)}
                              color="primary"
                            >
                              Add Staff
                            </Button>
                          </Grid>
                        )}
                        {site.staff.length > 0 && (
                          <React.Fragment>
                            <Grid container style={{ marginBottom: '10pt' }}>
                              <Grid container item xs alignItems="center">
                                <Grid item xs={9}>
                                  <Typography
                                    variant="body2"
                                    color="textSecondary"
                                  >
                                    Dexcom Study Staff
                                  </Typography>
                                </Grid>
                                <Grid
                                  container
                                  item
                                  xs={3}
                                  style={{ textAlign: 'center' }}
                                >
                                  <PermissionsHeader
                                    definitions={dexcomStaffRoles}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>

                            {site.staff.map((staff, staffIdx) => {
                              const staffErrors = siteErrors
                                ? siteErrors.staff[staffIdx]
                                : undefined
                              return (
                                <React.Fragment key={staffIdx}>
                                  <Grid
                                    container
                                    style={{ marginBottom: '10pt' }}
                                  >
                                    <Grid
                                      container
                                      item
                                      xs={12}
                                      md={9}
                                      alignItems="center"
                                      style={{ width: '100%' }}
                                    >
                                      <Grid item xs={9}>
                                        <TextField
                                          id="email"
                                          label="Email"
                                          variant="outlined"
                                          value={staff.email}
                                          error={
                                            staffErrors &&
                                            staffErrors.email !== ''
                                          }
                                          helperText={
                                            staffErrors ? staffErrors.email : ''
                                          }
                                          title="Please enter the email address of the staff member."
                                          style={{ width: '100%' }}
                                          onChange={handleStaffFieldChange(
                                            idx,
                                            staffIdx,
                                            'email'
                                          )}
                                        />
                                      </Grid>
                                      <Grid item xs={1}>
                                        <IconButton
                                          onClick={handleRemoveStaff(
                                            idx,
                                            staffIdx
                                          )}
                                          size="small"
                                          title="Remove staff member"
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                      </Grid>
                                      {staffIdx === site.staff.length - 1 && (
                                        <Grid item xs={2}>
                                          <Button
                                            variant="contained"
                                            onClick={handleAddStaff(idx)}
                                            color="primary"
                                            size="small"
                                            title="Add a new staff member to this site."
                                          >
                                            <PersonAddOutlinedIcon />
                                          </Button>
                                        </Grid>
                                      )}
                                    </Grid>
                                    <Grid
                                      container
                                      item
                                      xs={12}
                                      md={3}
                                      alignItems={'center'}
                                      justifyContent={'center'}
                                      style={{ textAlign: 'center' }}
                                    >
                                      {staffErrors && staffErrors.roles !== '' && (
                                        <Grid
                                          item
                                          xs={12}
                                          style={{
                                            width: '100%',
                                            textAlign: 'center',
                                          }}
                                        >
                                          <Typography
                                            variant={'h5'}
                                            color="error"
                                          >
                                            {staffErrors.roles}
                                          </Typography>
                                        </Grid>
                                      )}
                                      <Permissions
                                        definitions={dexcomStaffRoles}
                                        permissions={staff.roles}
                                        handleChangePermission={handleStaffRoleChange(
                                          idx,
                                          staffIdx
                                        )}
                                      />
                                    </Grid>
                                  </Grid>
                                </React.Fragment>
                              )
                            })}
                          </React.Fragment>
                        )}

                        <Grid item xs={12} style={{ marginTop: '10pt' }}>
                          <TextField
                            id="contactInstructions"
                            label="Dexcom Study Team Contact Instructions"
                            variant="outlined"
                            multiline
                            rows={8}
                            value={site.contactInstructions}
                            error={
                              siteErrors &&
                              siteErrors.contactInstructions !== ''
                            }
                            helperText={
                              siteErrors ? siteErrors.contactInstructions : ''
                            }
                            title={contactInstructionsTitle}
                            style={{ width: '100%' }}
                            onChange={handleSiteFieldChange(
                              idx,
                              'contactInstructions'
                            )}
                          />
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                )
              })}
            </Grid>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default CreateStudyStaffAndContactForm
