import {
  green,
  grey,
  lightGreen,
  orange,
  red,
  yellow,
} from '@material-ui/core/colors'
import { createTheme } from '@material-ui/core/styles'

const dexcomBlueHex = '#0075C2'
const dexcomGreenHex = '#58A618'

export const lightTheme = createTheme({
  palette: {
    type: 'light',
    background: {
      default: grey[50],
      paper: '#fff', //white
    },
    primary: {
      main: dexcomGreenHex,
    },
    secondary: {
      main: dexcomBlueHex,
    },
    warning: {
      main: orange[600],
    },
    info: {
      main: yellow[900],
    },
    error: {
      main: red[500],
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 750,
      md: 900,
      lg: 1110,
      xl: 1720,
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    button: {
      fontSize: 14,
      textTransform: 'initial',
    },
    h5: {
      fontSize: '1rem',
    },
  },
  oppositePalette: {
    backgroundColor: grey[800],
    colorInverted: grey[700],
    color: grey[300],
  },
  egvAlert: {
    backgroundColor: red[50],
    borderColor: grey[300],
    color: red[500],
  },
  egvWarn: {
    backgroundColor: yellow[50],
    borderColor: grey[300],
    color: grey[700],
  },
  egvNormal: {
    backgroundColor: lightGreen[100],
    borderColor: grey[300],
    color: grey[700],
  },
  egvInactive: {
    backgroundColor: grey[50],
    borderColor: grey[300],
    color: grey[700],
  },
  inRange: {
    backgroundColor: lightGreen[50],
    borderColor: green[900],
    color: green[900],
  },
  statusCreated: {
    backgroundColor: grey[100],
    borderColor: grey[300],
    color: yellow[900],
  },
  statusReady: {
    backgroundColor: grey[700],
    borderColor: green[900],
    color: dexcomBlueHex,
  },
  statusInProgress: {
    backgroundColor: lightGreen[50],
    borderColor: green[900],
    color: dexcomGreenHex,
  },
  statusCheckData: {
    backgroundColor: red[50],
    borderColor: grey[300],
    color: red[500],
  },
  statusCompleted: {
    backgroundColor: grey[100],
    borderColor: grey[300],
    color: grey[700],
  },
})

declare module '@material-ui/core/styles/createTheme' {
  interface Theme {
    oppositePalette: {
      color: React.CSSProperties['color']
      backgroundColor: React.CSSProperties['backgroundColor']
      colorInverted: React.CSSProperties['color']
    }
    egvAlert: {
      color: React.CSSProperties['color']
      backgroundColor: React.CSSProperties['backgroundColor']
      borderColor: React.CSSProperties['borderColor']
    }
    egvNormal: {
      color: React.CSSProperties['color']
      backgroundColor: React.CSSProperties['backgroundColor']
      borderColor: React.CSSProperties['borderColor']
    }
    egvWarn: {
      color: React.CSSProperties['color']
      backgroundColor: React.CSSProperties['backgroundColor']
      borderColor: React.CSSProperties['borderColor']
    }
    egvInactive: {
      color: React.CSSProperties['color']
      backgroundColor: React.CSSProperties['backgroundColor']
      borderColor: React.CSSProperties['borderColor']
    }
    inRange: {
      color: React.CSSProperties['color']
      backgroundColor: React.CSSProperties['backgroundColor']
      borderColor: React.CSSProperties['borderColor']
    }
    statusCreated: {
      color: React.CSSProperties['color']
      backgroundColor: React.CSSProperties['backgroundColor']
      borderColor: React.CSSProperties['borderColor']
    }
    statusReady: {
      color: React.CSSProperties['color']
      backgroundColor: React.CSSProperties['backgroundColor']
      borderColor: React.CSSProperties['borderColor']
    }
    statusInProgress: {
      color: React.CSSProperties['color']
      backgroundColor: React.CSSProperties['backgroundColor']
      borderColor: React.CSSProperties['borderColor']
    }
    statusCheckData: {
      color: React.CSSProperties['color']
      backgroundColor: React.CSSProperties['backgroundColor']
      borderColor: React.CSSProperties['borderColor']
    }
    statusCompleted: {
      color: React.CSSProperties['color']
      backgroundColor: React.CSSProperties['backgroundColor']
      borderColor: React.CSSProperties['borderColor']
    }
  }
  interface ThemeOptions {
    oppositePalette: {
      color: React.CSSProperties['color']
      backgroundColor: React.CSSProperties['backgroundColor']
      colorInverted: React.CSSProperties['color']
    }
    egvAlert: {
      color: React.CSSProperties['color']
      backgroundColor: React.CSSProperties['backgroundColor']
      borderColor: React.CSSProperties['borderColor']
    }
    egvNormal: {
      color: React.CSSProperties['color']
      backgroundColor: React.CSSProperties['backgroundColor']
      borderColor: React.CSSProperties['borderColor']
    }
    egvWarn: {
      color: React.CSSProperties['color']
      backgroundColor: React.CSSProperties['backgroundColor']
      borderColor: React.CSSProperties['borderColor']
    }
    egvInactive: {
      color: React.CSSProperties['color']
      backgroundColor: React.CSSProperties['backgroundColor']
      borderColor: React.CSSProperties['borderColor']
    }
    inRange: {
      color: React.CSSProperties['color']
      backgroundColor: React.CSSProperties['backgroundColor']
      borderColor: React.CSSProperties['borderColor']
    }
    statusCreated: {
      color: React.CSSProperties['color']
      backgroundColor: React.CSSProperties['backgroundColor']
      borderColor: React.CSSProperties['borderColor']
    }
    statusReady: {
      color: React.CSSProperties['color']
      backgroundColor: React.CSSProperties['backgroundColor']
      borderColor: React.CSSProperties['borderColor']
    }
    statusInProgress: {
      color: React.CSSProperties['color']
      backgroundColor: React.CSSProperties['backgroundColor']
      borderColor: React.CSSProperties['borderColor']
    }
    statusCheckData: {
      color: React.CSSProperties['color']
      backgroundColor: React.CSSProperties['backgroundColor']
      borderColor: React.CSSProperties['borderColor']
    }
    statusCompleted: {
      color: React.CSSProperties['color']
      backgroundColor: React.CSSProperties['backgroundColor']
      borderColor: React.CSSProperties['borderColor']
    }
  }
}
