import React from 'react'
import { Theme, makeStyles, createStyles } from '@material-ui/core'

const useDashboardStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.text.secondary,
      fontSize: 'small',
    },
    inputLabel: {
      color: theme.palette.text.secondary,
      marginLeft: '1pt',
    },
    userTextField: {
      width: '95%',
    },
    siteTextField: {
      width: '95%',
    },
    footer: {
      marginTop: '0.5rem',
      padding: '0.5rem',
      position: 'fixed',
      bottom: '0',
      left: '0',
      width: '100%',
      backgroundColor: '#424242',
      minHeight: '60px',
    },
    cardRoot: {
      height: '39px',
      color: theme.palette.text.secondary,
    },
    cardTitle: {
      textAlign: 'center',
      fontSize: '14px',
      fontWeight: 'bold',
    },
    cardTitleLeft: {
      textAlign: 'left',
      fontSize: '14px',
      fontWeight: 'bold',
    },
    cardAction: {
      alignSelf: 'center',
      marginTop: 0,
    },
  })
)
export default useDashboardStyles
