export const timeSpan = (startDateTicks: number, endDateTicks: number): string => {
  const timeSpanTicks = endDateTicks - startDateTicks
  if (timeSpanTicks < 0) {
    return '0m'
  }
  const oneMinuteMilli = 1000 * 60
  const timeSpanMinutes = Math.floor(timeSpanTicks / oneMinuteMilli)
  if (timeSpanMinutes < 1) {
    return '<1m'
  }
  if (timeSpanMinutes < 60) {
    return `${timeSpanMinutes}m`
  }
  const timeSpanHours = Math.floor(timeSpanMinutes / 60)
  if (timeSpanHours < 24) {
    return `${timeSpanHours}h`
  }
  const timeSpanDays = Math.floor(timeSpanHours / 24)
  return `${timeSpanDays}d`
}

// Returns just the date as MM/DD/YYYY
export const justDate = (timestamp: Date) => {
  const month = timestamp.getMonth() + 1
  const day = timestamp.getDate()
  const year = timestamp.getFullYear()

  return `${month < 10 ? '0' + month : month}/${day < 10 ? '0' + day : day}/${year}`
}

// Returns just the time as HH:MM AM/PM
export const justTime = (timestamp: Date) => {
  let hour = timestamp.getHours()
  const ampm = hour >= 12 ? 'PM' : 'AM'
  hour = hour % 12
  hour = hour ? hour : 12

  const minutes = timestamp.getMinutes()

  return `${hour}:${minutes < 10 ? '0' + minutes : minutes} ${ampm}`
}

// Format: MM/DD/YYYY at HH:MM AM/PM
export const formatDate = (date: Date): string => {
  return justDate(date) + ' at ' + justTime(date)
}

// Format: Minutes to #d #h #m
export const formatTime = (minutes: number): string => {
  if (minutes < 60) {
    return `0d 0h ${minutes}m`
  }
  let hours = (minutes / 60) | 0
  minutes = Math.trunc(minutes % 60)
  if (hours < 24) {
    return `0d ${hours}h ${minutes}m`
  }
  const days = (hours / 24) | 0
  hours = Math.trunc(hours % 24)
  return `${days}d ${hours}h ${minutes}m`
}