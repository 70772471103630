import { pingSubject } from '@lib/data/ping.data'
import useKeycloak from '@providers/auth/useKeycloak'
import useToast from '@providers/data/hooks/useToast'

export default function useSubjectPing() {
  const { createToast } = useToast()
  const { token } = useKeycloak()

  const ping = (siteId: string, subjectId: string) => {
    pingSubject(token, siteId, subjectId)
      .then(() => {
        createToast('Ping Sent')
      })
      .catch((e) => {
        if (e.message === 'HTTP call failed because Not Found') {
          createToast('Subject Not Connected', 'warning')
        } else {
          createToast('Ping Failed', 'error')
        }
      })
  }

  return {
    ping,
  }
}
