import { useCallback } from 'react'
import { useGlobalState } from './useGlobalState'
import { EGVRecord, IParticipantRecord } from '@models/index'
import { allEgvsAction, newEgvsAction, participantUpdateAction } from '../actions'

export default () => {
    const { state, dispatch } = useGlobalState()

    const setParticipants = useCallback((participants: IParticipantRecord[]) => {
        dispatch(participantUpdateAction(participants))
    }, [dispatch])

    const setAllEgvs = useCallback((egvs: Record<string, EGVRecord[]>) => {
      dispatch(allEgvsAction(egvs))
    }, [dispatch])

    const setNewEgvs = useCallback((egvs: Record<string, EGVRecord[]>) => {
      dispatch(newEgvsAction(egvs))
    }, [dispatch])

    const { participantData } = state

    return {
        participantData,
        setParticipants,
        setAllEgvs,
        setNewEgvs
    }
}
