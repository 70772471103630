import { useCallback } from "react"
import { Socket } from "socket.io-client"
import { MetricsSummary } from "@models/metrics.models"
import useWebsocket from './Websocket'

const IO_NAME = '/metrics'
type MetricsSocketParams = {
    siteId: string
    setMetrics: (summaries: MetricsSummary[]) => void
}
export default ({ siteId, setMetrics }: MetricsSocketParams) => {
    const register = useCallback((socket: Socket) => {
        socket.on('METRICS', (summaries: MetricsSummary[]) => {
            setMetrics(summaries)
        })
    }, [setMetrics])

    useWebsocket(`${IO_NAME}:${siteId}`, register)
}