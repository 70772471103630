import { Action, ActionTypes } from '../actions'

export interface AccessCodeState {
  subjectId: string
  siteId: string
  studyId: string
  showDialog: boolean
}

export default (state: AccessCodeState, action: Action): AccessCodeState => {
  switch (action.type) {
    case ActionTypes.SHOW_ACCESS_CODE_DIALOG:
      return {
        subjectId: action.payload.id,
        studyId: action.payload.studyId,
        siteId: action.payload.siteId,
        showDialog: true,
      }
    case ActionTypes.HIDE_ACCESS_CODE_DIALOG:
      return {
        subjectId: '',
        siteId: '',
        studyId: '',
        showDialog: false,
      }
    default:
      return state
  }
}
