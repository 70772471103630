import React from 'react'
import { useTheme } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import CardContent from '@material-ui/core/CardContent'

export default function ContactUs() {
  const theme = useTheme()

  return (
    <React.Fragment>
      <Grid container style={{ marginTop: '10pt' }}>
        <Grid item xs={9} sm={10} lg={11}>
          <Typography
            variant="h4"
            style={{
              color: theme.oppositePalette.colorInverted,
            }}
          >
            Contact Us
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item sm={12} md={6}>
          <Card elevation={1}>
            <CardHeader
              title="Web Portal Administrator"
              action={
                <Button
                  color="primary"
                  variant="contained"
                  style={{ textTransform: 'initial' }}
                >
                  Button
                </Button>
              }
            />
            <Divider />
            <CardContent>
              <div>Contact John Smith at johns@dexcom.com or 858-123-4567</div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card elevation={1}>
            <CardHeader
              title="Dexcom Study Staff"
              action={
                <Button
                  color="primary"
                  variant="contained"
                  style={{ textTransform: 'initial' }}
                >
                  Button
                </Button>
              }
            />
            <Divider />
            <CardContent>
              <div>Contact John Smith at johns@dexcom.com or 858-123-4567</div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card elevation={1}>
            <CardHeader
              title="Site Staff"
              action={
                <Button
                  color="primary"
                  variant="contained"
                  style={{ textTransform: 'initial' }}
                >
                  Button
                </Button>
              }
            />
            <Divider />
            <CardContent>
              <div>Contact John Smith at johns@dexcom.com or 858-123-4567</div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
