/*eslint-disable  react-hooks/exhaustive-deps */
/*eslint-disable  @typescript-eslint/explicit-module-boundary-types */
import * as React from 'react'
import { useEffect, useState, useRef } from 'react'
import _ from 'lodash'

import {
  AuthClientEvent,
  AuthClientTokens,
  IAuthClient,
  IAuthClientError,
  IAuthClientInitOptions,
  IAuthContextProps,
} from './context'

export type AuthProviderState = {
  initialized: boolean
  isAuthenticated: boolean
  isLoading: boolean
}

export interface IAuthProviderProps<T extends IAuthClient> {
  authClient: T
  autoRefreshToken?: boolean
  initOptions?: IAuthClientInitOptions
  isLoadingCheck?: (authClient: T) => boolean
  LoadingComponent: JSX.Element
  onEvent?: (eventType: AuthClientEvent, error?: IAuthClientError) => void
  onTokens?: (token: AuthClientTokens) => void
  children?: React.ReactNode
}

export const createAuthProvider = <T extends IAuthClient>(
  AuthContext: React.Context<IAuthContextProps<T>>
) => {
  const defaultInitOptions: IAuthClientInitOptions = {
    onLoad: 'login-required',
    pkceMethod: 'S256',
    checkLoginIframe: false, // disables 5 second auto logout (possible Bug)
  }

  const initialState: AuthProviderState = {
    initialized: false,
    isAuthenticated: false,
    isLoading: true,
  }

  return (props: IAuthProviderProps<T>) => {
    const [state, setState] = useState<AuthProviderState>(initialState)
    const prevAuthClient = useRef<IAuthClient>({} as IAuthClient)
    const prevInitOptions = useRef<IAuthClientInitOptions>()

    useEffect(() => {
      if (
        props.authClient !== prevAuthClient.current ||
        !_.isEqual(props.initOptions, prevInitOptions.current)
      ) {
        // init new client
        reset()
        init()
      }

      prevAuthClient.current = props.authClient
      prevInitOptions.current = props.initOptions
    }, [props.authClient, props.initOptions])

    const reset = () => {
      // deregister handlers
      prevAuthClient.current.onReady = undefined
      prevAuthClient.current.onAuthSuccess = undefined
      prevAuthClient.current.onAuthError = undefined
      prevAuthClient.current.onAuthRefreshSuccess = undefined
      prevAuthClient.current.onAuthRefreshError = undefined
      prevAuthClient.current.onAuthLogout = undefined
      prevAuthClient.current.onTokenExpired = undefined
      prevAuthClient.current.login = () => Promise.reject()

      // reset state
      setState(initialState)
    }

    const init = () => {
      const { initOptions, authClient } = props

      authClient.onReady = updateState('onReady')
      authClient.onAuthSuccess = updateState('onAuthSuccess')
      authClient.onAuthError = onError('onAuthError')
      authClient.onAuthRefreshSuccess = updateState('onAuthRefreshSuccess')
      authClient.onAuthRefreshError = onError('onAuthRefreshError')
      authClient.onAuthLogout = updateState('onAuthLogout')
      authClient.onTokenExpired = refreshToken('onTokenExpired')

      authClient
        .init({ ...defaultInitOptions, ...initOptions })
        .catch(onError('onInitError'))
    }

    const onError = (event: AuthClientEvent) => (error?: IAuthClientError) => {
      const { onEvent, authClient } = props
      if (event === 'onAuthRefreshError') {
        // if there is an error using the refresh token, send the user to login screen
        authClient.logout()
      }
      onEvent && onEvent(event, error)
    }

    const updateState = (event: AuthClientEvent) => () => {
      const { authClient, onEvent, onTokens, isLoadingCheck } = props
      const {
        initialized: prevInitialized,
        isAuthenticated: prevAuthenticated,
        isLoading: prevLoading,
      } = state

      onEvent && onEvent(event)

      const isLoading = isLoadingCheck ? isLoadingCheck(authClient) : false

      const isAuthenticated = isUserAuthenticated(authClient)

      // Notify token listener
      const { idToken, refreshToken, token } = authClient
      onTokens && onTokens({ idToken, refreshToken, token })

      // Prevent double-refresh if state has not changed
      if (
        !prevInitialized ||
        isAuthenticated !== prevAuthenticated ||
        isLoading !== prevLoading
      ) {
        setState({
          initialized: true,
          isAuthenticated,
          isLoading,
        })
      }
    }

    const refreshToken = (event: AuthClientEvent) => () => {
      const { autoRefreshToken, authClient, onEvent } = props

      onEvent && onEvent(event)

      if (autoRefreshToken !== false) {
        authClient.updateToken(5)
      }
    }

    const { children, authClient, LoadingComponent } = props
    const { initialized, isLoading, isAuthenticated } = state

    if (!initialized || !isAuthenticated || isLoading) {
      return LoadingComponent
    }

    if (authClient.tokenParsed) {
      return (
        <AuthContext.Provider value={{ initialized, authClient }}>
          {children}
        </AuthContext.Provider>
      )
    }
    return LoadingComponent
  }
}

const isUserAuthenticated = (authClient: IAuthClient) =>
  !!authClient.idToken && !!authClient.token

export default createAuthProvider
