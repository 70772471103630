import React from 'react'
import { Grid } from '@material-ui/core'
import GranularConsents from './GranularConsents'
import TermsAndConditions from './TermsAndConditions'

type SpecialAccessProps = {
  studyId: string
  siteId: string
}

export const SpecialAccess: React.FC<SpecialAccessProps> = ({
  studyId,
  siteId,
}) => {
  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <GranularConsents studyId={studyId} siteId={siteId} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TermsAndConditions siteId={siteId} />
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default SpecialAccess
