import { useReducer } from 'react'
import { StudyErrors, StudyFormState, validateStudyStep } from './models'
import studyReducer from './reducer'
import { AllActions } from './actions'
import { NewStudy } from '@models/api.models'
import { useKeycloak } from '@providers/auth'

const initialStudy: NewStudy = {
  type: '',
  name: '',
  protocolId: '',
  protocolTitle: '',
  dexcomAdministrator: '',
  sites: [
    {
      label: '',
      country: '',
      siteAdministrator: '',
      contactInstructions: '',
      staff: [],
    },
  ],
}

const initialErrors: StudyErrors = {
  type: '',
  basicInfo: '',
  name: '',
  protocolId: '',
  protocolTitle: '',
  dexcomAdministrator: '',
  siteInfo: '',
  sites: [],
}

const initialState: StudyFormState = {
  values: initialStudy,
  errors: initialErrors,
}

const useCreateStudy = () => {
  const { parsed } = useKeycloak()
  const [state, dispatch] = useReducer(studyReducer, {
    ...initialState,
    values: {
      ...initialState.values,
      dexcomAdministrator: parsed.email,
    },
  })

  const fieldChange = (key: string, value: string) => {
    dispatch({ type: AllActions.FIELD_CHANGE, payload: { key, value } })

    //side-effect for switching to external study type
    if (key === 'type' && value === 'Dexcom External Research (IIS)') {
      dispatch({ type: AllActions.CLEAR_INTERNAL_FIELDS })
    }
  }

  const siteFieldChange = (siteIndex: number, key: string, value: string) => {
    dispatch({
      type: AllActions.SITE_FIELD_CHANGE,
      payload: { siteIndex, key, value },
    })
  }

  const siteFieldChangeOnAllSites = (key: string, value: string) => {
    dispatch({
      type: AllActions.SITE_FIELD_CHANGE_ON_ALL_SITES,
      payload: { key, value },
    })
  }

  const staffFieldChange = (
    siteIndex: number,
    staffIndex: number,
    key: string,
    value: string
  ) => {
    dispatch({
      type: AllActions.STAFF_FIELD_CHANGE,
      payload: { siteIndex, staffIndex, key, value },
    })
  }

  const staffRoleChange = (
    siteIndex: number,
    staffIndex: number,
    role: string,
    add: boolean
  ) => {
    const action = add
      ? AllActions.STAFF_ADD_ROLE
      : AllActions.STAFF_REMOVE_ROLE
    dispatch({ type: action, payload: { siteIndex, staffIndex, role } })
  }

  const addSite = () => {
    dispatch({ type: AllActions.ADD_SITE })
  }

  const removeSite = (siteIndex: number) => {
    dispatch({ type: AllActions.REMOVE_SITE, payload: { siteIndex } })
  }

  const addStaff = (siteIndex: number) => {
    dispatch({ type: AllActions.ADD_STAFF, payload: { siteIndex } })
  }

  const removeStaff = (siteIndex: number, staffIndex: number) => {
    dispatch({
      type: AllActions.REMOVE_STAFF,
      payload: { siteIndex, staffIndex },
    })
  }

  const reset = () => {
    dispatch({ type: AllActions.RESET, payload: initialState })
  }

  const resetErrors = () => {
    dispatch({ type: AllActions.SET_ERRORS, payload: initialErrors })
  }

  const validateStep = (activeStep: number) => {
    const [valid, errors] = validateStudyStep(activeStep, state.values)
    dispatch({ type: AllActions.SET_ERRORS, payload: errors })
    return valid
  }

  return {
    values: state.values,
    errors: state.errors,
    actions: {
      fieldChange,
      siteFieldChange,
      siteFieldChangeOnAllSites,
      staffFieldChange,
      staffRoleChange,
      addSite,
      removeSite,
      addStaff,
      removeStaff,
      reset,
      resetErrors,
      validateStep,
    },
  }
}

export default useCreateStudy
