import React from 'react'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import TIME_RANGE from './TimeRange'

interface TimeRangePickerProps {
  value: TIME_RANGE
  setValue: (range: TIME_RANGE) => void
}

const TimeRangePicker: React.FunctionComponent<TimeRangePickerProps> = ({
  value,
  setValue,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const range = Number.parseInt(event.target.value)
    setValue(range)
  }

  return (
    <FormControl component="fieldset">
      <RadioGroup row value={value} onChange={handleChange}>
        <FormControlLabel
          value={TIME_RANGE.RANGE_3_HOURS}
          control={<Radio color="primary" />}
          label="3h"
        />
        <FormControlLabel
          value={TIME_RANGE.RANGE_6_HOURS}
          control={<Radio color="primary" />}
          label="6h"
        />
        <FormControlLabel
          value={TIME_RANGE.RANGE_12_HOURS}
          control={<Radio color="primary" />}
          label="12h"
        />
        <FormControlLabel
          value={TIME_RANGE.RANGE_24_HOURS}
          control={<Radio color="primary" />}
          label="24h"
        />
        <FormControlLabel
          value={TIME_RANGE.RANGE_72_HOURS}
          control={<Radio color="primary" />}
          label="72h"
        />
      </RadioGroup>
    </FormControl>
  )
}

export default TimeRangePicker
