import React, { useState } from 'react'
import Divider from '@material-ui/core/Divider'
import {
  Button,
  Grid,
  IconButton,
  Typography,
  useTheme,
} from '@material-ui/core'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogActions from '@material-ui/core/DialogActions'
import CloseIcon from '@material-ui/icons/Close'
import useDashboardStyles from '@components/GlobalStyles'

type CommentFormProps = {
  onSubmit: (comment: string) => void
  onCancel: () => void
}

export default function CommentForm(props: CommentFormProps) {
  const theme = useTheme()
  const classes = useDashboardStyles(theme)
  const [comment, setComment] = useState('')
  const handleInput = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value
    setComment(value)
  }

  const submit = () => {
    props.onSubmit(comment)
    setComment('')
  }

  return (
    <React.Fragment>
      <MuiDialogTitle>
        <Typography style={{ color: theme.palette.text.secondary }}>
          Add a Comment
        </Typography>
        <IconButton
          className={classes.closeButton}
          aria-label="close"
          onClick={props.onCancel}
        >
          <CloseIcon />
        </IconButton>
      </MuiDialogTitle>
      <Divider />
      <Grid container justify="space-around" alignItems={'center'}>
        <Grid item xs={9} style={{ marginTop: 10 }}>
          <textarea
            style={{ resize: 'none', width: '100%', height: 200 }}
            placeholder="Enter Comment Here. Max. 250 characters"
            onChange={handleInput}
            maxLength={250}
          >
            {comment}
          </textarea>
        </Grid>
      </Grid>
      <MuiDialogActions>
        <Button
          onClick={props.onCancel}
          style={{ marginRight: 10 }}
          variant="outlined"
        >
          Cancel
        </Button>
        <Button onClick={submit} color="primary" variant="contained">
          Add
        </Button>
      </MuiDialogActions>
    </React.Fragment>
  )
}
