import { useCallback, useEffect } from 'react'
import { Socket } from 'socket.io-client'
import useParticipants from '../data/hooks/useParticipants'
import useWebsocket from './Websocket'

const IO_NAME = '/participants'
export default () => {
    const { setParticipants, setAllEgvs, setNewEgvs } = useParticipants()
    const register = useCallback((socket: Socket) => {
        socket.on('PARTICIPANT', setParticipants)
        socket.on('ALL_EGVS', setAllEgvs)
        socket.on('NEW_EGVS', setNewEgvs)
    }, [setParticipants, setAllEgvs, setNewEgvs])

    useWebsocket(IO_NAME, register)
}
