import * as PasswordService from 'generate-password'

export const generatePassword = (): string => {
  const password = PasswordService.generate({
    length: 10,
    numbers: true,
    symbols: true,
    lowercase: true,
    uppercase: true,
    excludeSimilarCharacters: true,
    strict: true,
  })

  return password
}
