import { useMemo, CSSProperties } from 'react'
import {
  grey,
  lightGreen,
  green,
  lightBlue,
  blue,
  purple,
} from '@material-ui/core/colors'
import { Theme } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import { IEgvEventRecord, IComment, IEventRule } from '@models/index'
import { egvStatusStyle } from '@lib/egv'
import useCustomEvents from './useCustomEvents'

const getEventColor = (
  type: string,
  rules: IEventRule[],
  theme: Theme
): CSSProperties => {
  if (type === 'high') {
    return egvStatusStyle('High', theme)
  }

  if (type === 'low') {
    return egvStatusStyle('Low', theme)
  }

  for (let i = 0; i < rules.length; i++) {
    if (type === rules[i].name) {
      switch (rules[i].color) {
        case 'green':
          return {
            backgroundColor: lightGreen[50],
            borderColor: green[900],
            color: green[900],
          }
        case 'purple':
          return {
            backgroundColor: purple[50],
            borderColor: purple[900],
            color: purple[500],
          }
        case 'blue':
          return {
            backgroundColor: lightBlue[100],
            borderColor: blue[300],
            color: blue[700],
          }
        default:
          return {
            backgroundColor: grey[50],
            borderColor: grey[300],
            color: grey[700],
          }
      }
    }
  }
  return {
    backgroundColor: grey[50],
    borderColor: grey[300],
    color: grey[700],
  }
}

export default (comments: IComment[], events: IEgvEventRecord[]) => {
  const { rules } = useCustomEvents()
  const theme = useTheme()

  const combinedEvents = useMemo(() => {
    const combined = comments.map((c) => ({
      type: 'comment',
      text: c.body,
      date: c.date,
      color: {},
    }))
    events.forEach((e) => {
      combined.push({
        type: 'event',
        text: e.type,
        date: e.startdate,
        color: getEventColor(e.type, rules, theme),
      })
    })
    return combined.sort((a, b) => b.date.getTime() - a.date.getTime())
  }, [comments, events, rules, theme])

  return combinedEvents
}
