import React from 'react'
import Grid from '@material-ui/core/Grid'
import Checkbox from '@material-ui/core/Checkbox'
import Typography from '@material-ui/core/Typography'
import { allRoles, Role } from '@models/access.models'

type PermissionsProps = {
  definitions: Record<string, Role>
  permissions: string[]
  disabled?: boolean
  handleChangePermission: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const Permissions = (props: PermissionsProps) => (
  <>
    {Object.keys(props.definitions).map((d) => (
      <Grid key={d} item xs={4}>
        <Checkbox
          checked={props.permissions.includes(d)}
          onChange={props.handleChangePermission}
          name={d}
          title={props.definitions[d].description}
          disabled={props.disabled}
        />
      </Grid>
    ))}
  </>
)

type PermissionsHeaderProps = {
  definitions: Record<string, Role>
}

export const PermissionsHeader = (props: PermissionsHeaderProps) => (
  <>
    {' '}
    {Object.keys(props.definitions).map((d) => (
      <Grid key={d} item xs={4}>
        <Typography variant="body2" color="textSecondary">
          {allRoles[d].name}
        </Typography>
      </Grid>
    ))}
  </>
)
