import React, { createContext, FunctionComponent, useEffect, useState } from 'react'
import { useKeycloak } from '@providers/auth'
import * as AccessApi from '@lib/data/access.data'


type AccessProviderProps = {
    resourceId: string
}

type AccessState = {
    permissions: string[]
    fetching: boolean
    error: string
}

export const initialState: AccessState = {
    permissions: [],
    fetching: false,
    error: ''
}

const AccessContext = createContext<AccessState>(initialState)

const AccessProvider: FunctionComponent<AccessProviderProps> = ({ resourceId, children }) => {
    const { token } = useKeycloak()
    const [state, setState] = useState<AccessState>(initialState)

    useEffect(() => {
        let subscribed = true

        AccessApi.getAccessList(resourceId, token)
            .then((permissions) => {
                if (!subscribed) return
                setState({ ...state, permissions, fetching: false })
            })
            .catch((error) => {
                if (!subscribed) return
                setState({ ...state, error, fetching: false })
            })
  
      return () => {
        subscribed = false
      }
    }, []) // run only on initial mount

    return (
        <AccessContext.Provider value={state}>
            {children}
        </AccessContext.Provider>
    )
}

export { AccessContext, AccessProvider }