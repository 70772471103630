import { StudyErrors, StudyFormState } from './models'
import { Action as StaffAction } from '@components/Staff/actions'

export enum Action {
  FIELD_CHANGE = 'FIELD_CHANGE',
  SITE_FIELD_CHANGE = 'SITE_FIELD_CHANGE',
  SITE_FIELD_CHANGE_ON_ALL_SITES = 'SITE_FIELD_CHANGE_ON_ALL_SITES',
  ADD_SITE = 'ADD_SITE',
  REMOVE_SITE = 'REMOVE_SITE',
  SET_ERRORS = 'SET_ERRORS',
  RESET = 'RESET',
  CLEAR_INTERNAL_FIELDS = 'CLEAR_INTERNAL_FIELDS',
}

export const AllActions = {
  ...Action,
  ...StaffAction,
}

type FieldChange = {
  type: Action.FIELD_CHANGE
  payload: { key: string; value: string }
}
type SiteFieldChange = {
  type: Action.SITE_FIELD_CHANGE
  payload: { siteIndex: number; key: string; value: string }
}
type SiteFieldChangeOnAllSites = {
  type: Action.SITE_FIELD_CHANGE_ON_ALL_SITES
  payload: { key: string; value: string }
}
type StaffFieldChange = {
  type: StaffAction.STAFF_FIELD_CHANGE
  payload: { siteIndex: number; staffIndex: number; key: string; value: string }
}
type StaffRemoveRole = {
  type: StaffAction.STAFF_REMOVE_ROLE
  payload: { siteIndex: number; staffIndex: number; role: string }
}
type StaffAddRole = {
  type: StaffAction.STAFF_ADD_ROLE
  payload: { siteIndex: number; staffIndex: number; role: string }
}
type AddStaff = { type: StaffAction.ADD_STAFF; payload: { siteIndex: number } }
type RemoveStaff = {
  type: StaffAction.REMOVE_STAFF
  payload: { siteIndex: number; staffIndex: number }
}
type AddSite = { type: Action.ADD_SITE }
type RemoveSite = { type: Action.REMOVE_SITE; payload: { siteIndex: number } }
type SetErrors = { type: Action.SET_ERRORS; payload: StudyErrors }
type Reset = { type: Action.RESET; payload: StudyFormState }
type ClearInternalFields = { type: Action.CLEAR_INTERNAL_FIELDS }

export type ActionType =
  | FieldChange
  | SiteFieldChange
  | SiteFieldChangeOnAllSites
  | StaffFieldChange
  | StaffAddRole
  | StaffRemoveRole
  | AddStaff
  | RemoveStaff
  | AddSite
  | RemoveSite
  | SetErrors
  | Reset
  | ClearInternalFields
