import { useCallback } from 'react'
import * as Actions from '../actions'
import { ToastState } from '../reducers/toast.reducer'
import { useGlobalState } from './useGlobalState'

interface ToastData {
  toast: ToastState
  createToast: (
    message: string,
    severity?: 'warning' | 'error' | 'info'
  ) => void
  hideToast: () => void
}

const useToast = (): ToastData => {
  const { state, dispatch } = useGlobalState()

  const createToast = useCallback(
    (message: string, severity?: 'warning' | 'error' | 'info') => {
      if (severity) {
        dispatch(Actions.setToast(message, severity))
      } else {
        dispatch(Actions.setToast(message, 'success'))
      }
    },
    [dispatch]
  )

  const hideToast = () => {
    dispatch(Actions.hideToast())
  }

  return {
    toast: state.toast,
    createToast,
    hideToast,
  }
}

export default useToast
