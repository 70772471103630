import React, { useState } from 'react'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import InputAdornment from '@material-ui/core/InputAdornment'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import SaveRoundedIcon from '@material-ui/icons/SaveRounded'
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded'
import { IEventFilter, IEventRule } from '@models/index'
import {
  IconButton,
  createStyles,
  makeStyles,
  useTheme,
  useMediaQuery,
} from '@material-ui/core'

type CustomEventProps = {
  customEvent: IEventRule
  backgroundColor: string
  onDelete: (id: string) => void
  onsave: (event: IEventRule) => void
}

type Range = {
  operator: string
  value: number[]
}

const useStyles = makeStyles(
  createStyles({
    duration: {
      width: 125,
    },
    threshold: {
      width: 145,
    },
    operator: {
      width: 125,
      height: 40,
      textTransform: 'initial',
      marginLeft: '0.75em',
    },
    spacerAndRight: {
      width: '5%',
      marginRight: '1em',
    },
    spacerAndLeft: {
      width: '5%',
      marginLeft: '1em',
    },
    ruleLine: {
      marginTop: '1em',
    },
  })
)

const validateName = (name: string) => {
  return name !== ''
}

const validateDurationIn = (durationIn: number) => {
  return durationIn > 0
}

const validateDurationOut = (durationOut: number) => {
  return durationOut >= 5
}

export const validateThreshold = (operator: string, threshold: number[]) => {
  switch (operator) {
    case 'above':
      return threshold[0] >= 38 && threshold[0] <= 400
    case 'below':
      return threshold[0] >= 40 && threshold[0] <= 402
    case 'between':
      return (
        threshold[0] >= 38 &&
        threshold[0] <= 400 &&
        threshold[1] <= 402 &&
        threshold[1] >= threshold[0] + 2
      )
    default:
      return false
  }
}

const getRange = (filters: IEventFilter[]): Range => {
  if (filters.length > 2 || filters.length < 1) {
    return { operator: '', value: [] }
  }
  if (filters.length === 2) {
    return { operator: 'between', value: [filters[0].value, filters[1].value] }
  }
  return {
    operator: filters[0].operator === '>' ? 'above' : 'below',
    value: [filters[0].value],
  }
}

const getFilters = (range: Range): IEventFilter[] => {
  if (range.operator === 'between') {
    return [
      { operator: '>', value: range.value[0] },
      { operator: '<', value: range.value[1] },
    ]
  }
  return [
    { operator: range.operator === 'above' ? '>' : '<', value: range.value[0] },
  ]
}

export default function CustomEvent(props: CustomEventProps) {
  const theme = useTheme()
  const classes = useStyles(theme)
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'))
  const [openOperator, setOperatorOpen] = useState(false)
  const [openColor, setColorOpen] = useState(false)
  const [state, setState] = useState({
    changed: false,
    name: props.customEvent.name,
    durationIn: props.customEvent.durationIn,
    durationOut: props.customEvent.durationOut,
    filters: getRange(props.customEvent.filters),
    color: props.customEvent.color,
  })

  const handleStringChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => {
      return {
        ...prevState,
        [event.target.id]: event.target.value,
        changed: true,
      }
    })
  }

  const handleIntChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => {
      return {
        ...prevState,
        [event.target.id]: parseInt(event.target.value),
        changed: true,
      }
    })
  }

  const handleOperatorChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setState((prevState) => {
      return {
        ...prevState,
        filters: {
          ...prevState.filters,
          operator: event.target.value as string,
          value:
            event.target.value === 'between'
              ? [prevState.filters.value[0], prevState.filters.value[0] + 2]
              : [prevState.filters.value[0]],
        },
        changed: true,
      }
    })
  }

  const handleColorChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setState((prevState) => {
      return {
        ...prevState,
        color: event.target.value as string,
        changed: true,
      }
    })
  }

  const handleThresholdChange =
    (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = parseInt(event.target.value)
      setState((prevState) => {
        return {
          ...prevState,
          filters: {
            ...prevState.filters,
            value:
              prevState.filters.operator === 'between'
                ? index === 1
                  ? [prevState.filters.value[0], value]
                  : [value, prevState.filters.value[1]]
                : [value],
          },
          changed: true,
        }
      })
    }

  const handleOperatorClose = () => {
    setOperatorOpen(false)
  }

  const handleColorClose = () => {
    setColorOpen(false)
  }

  const handleOperatorOpen = () => {
    setOperatorOpen(true)
  }

  const handleColorOpen = () => {
    setColorOpen(true)
  }

  const save = () => {
    setState((prevState) => {
      return {
        ...prevState,
        changed: false,
      }
    })
    props.onsave({
      id: props.customEvent.id,
      siteId: props.customEvent.siteId,
      name: state.name,
      durationIn: state.durationIn,
      durationOut: state.durationOut,
      filters: getFilters(state.filters),
      color: state.color,
    } as IEventRule)
  }

  const helpText = {
    durationIn: 'Event duration must be greater than 0 minutes.',
    durationOut: 'Cannot be less than 5 minutes.',
    secondThreshold: `Must be between ${
      state.filters.value[0] + 2
    } and 402 mg/dL`,
    aboveThreshold: 'Must be between 38 mg/dL and 400 mg/dL',
    belowThreshold: 'Must be between 40 mg/dL and 402 mg/dL',
    eventName: 'Event name cannot be blank',
  }

  return (
    <React.Fragment>
      <Grid
        container
        spacing={1}
        style={{ backgroundColor: props.backgroundColor }}
      >
        <Grid container item xs={8} md={9} xl={10}>
          <Grid item xs={12}>
            <TextField
              label="Event Name"
              error={!validateName(state.name)}
              id="name"
              value={state.name}
              size="small"
              variant="standard"
              type="text"
              onChange={handleStringChange}
              helperText={
                validateName(state.name) ? undefined : helpText.eventName
              }
            />
          </Grid>
          <Grid container item xs={12}>
            <Grid xs={12} xl={5} className={classes.ruleLine}>
              <TextField
                label="Duration"
                error={!validateDurationIn(state.durationIn)}
                className={classes.duration}
                id="durationIn"
                value={state.durationIn}
                size="small"
                variant="outlined"
                type="number"
                onChange={handleIntChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">mins</InputAdornment>
                  ),
                }}
                helperText={
                  validateDurationIn(state.durationIn)
                    ? undefined
                    : helpText.durationIn
                }
              />
              <Select
                id="operator"
                open={openOperator}
                value={state.filters.operator}
                onClose={handleOperatorClose}
                onOpen={handleOperatorOpen}
                variant="outlined"
                className={classes.operator}
                onChange={handleOperatorChange}
              >
                <MenuItem value="above">Above</MenuItem>
                <MenuItem value="below">Below</MenuItem>
                <MenuItem value="between">Between</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} xl={3} className={classes.ruleLine}>
              <TextField
                label="Threshold 1"
                error={
                  !validateThreshold(
                    state.filters.operator,
                    state.filters.value
                  )
                }
                className={classes.threshold}
                id="filters"
                value={state.filters.value[0]}
                size="small"
                variant="outlined"
                type="number"
                onChange={handleThresholdChange(0)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">mg/dL</InputAdornment>
                  ),
                }}
                helperText={
                  validateThreshold(state.filters.operator, state.filters.value)
                    ? undefined
                    : state.filters.operator === 'above' ||
                      state.filters.operator === 'between'
                    ? helpText.aboveThreshold
                    : helpText.belowThreshold
                }
              />
              {state.filters.operator === 'between' && isSmallScreen ? (
                <span className={classes.spacerAndLeft}>and</span>
              ) : (
                ''
              )}
            </Grid>
            {state.filters.operator === 'between' ? (
              <Grid xs={12} xl={4} className={classes.ruleLine}>
                {!isSmallScreen ? (
                  <span className={classes.spacerAndRight}>and</span>
                ) : (
                  ''
                )}
                <TextField
                  label="Threshold 2"
                  error={
                    !validateThreshold(
                      state.filters.operator,
                      state.filters.value
                    )
                  }
                  className={classes.threshold}
                  id="filters"
                  value={state.filters.value[1]}
                  size="small"
                  variant="outlined"
                  type="number"
                  onChange={handleThresholdChange(1)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">mg/dL</InputAdornment>
                    ),
                  }}
                  helperText={
                    validateThreshold(
                      state.filters.operator,
                      state.filters.value
                    )
                      ? undefined
                      : helpText.secondThreshold
                  }
                />
              </Grid>
            ) : (
              <Grid></Grid>
            )}
          </Grid>
          <Grid item xs={12} className={classes.ruleLine}>
            <TextField
              label="Interval Between"
              error={!validateDurationOut(state.durationOut)}
              className={classes.duration}
              id="durationOut"
              value={state.durationOut}
              size="small"
              variant="outlined"
              type="number"
              onChange={handleIntChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">mins</InputAdornment>
                ),
              }}
              helperText={
                validateDurationOut(state.durationOut)
                  ? undefined
                  : helpText.durationOut
              }
            />
            <Select
              id="color"
              open={openColor}
              value={state.color}
              onClose={handleColorClose}
              onOpen={handleColorOpen}
              variant="outlined"
              className={classes.operator}
              onChange={handleColorChange}
            >
              <MenuItem value="purple">Purple</MenuItem>
              <MenuItem value="green">Green</MenuItem>
              <MenuItem value="blue">Blue</MenuItem>
            </Select>
          </Grid>
        </Grid>
        <Grid container item xs={4} md={3} xl={2} alignContent="center">
          <Grid item xs={12}>
            <IconButton
              onClick={save}
              disabled={!state.changed}
              style={{ color: theme.palette.primary.main }}
            >
              <SaveRoundedIcon></SaveRoundedIcon>
            </IconButton>
            <IconButton
              style={{ color: theme.palette.text.secondary }}
              onClick={() => props.onDelete(props.customEvent.id)}
            >
              <DeleteRoundedIcon></DeleteRoundedIcon>
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
