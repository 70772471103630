import { useCallback } from "react"
import { Socket } from 'socket.io-client'
import { StudySubject } from "@models/api.models"
import useWebsocket from './Websocket'

const IO_NAME = '/subjects'
type SubjectSocketParams = {
    siteId: string
    setSubjects: (subjects: StudySubject[]) => void
}
export default ({ siteId, setSubjects }: SubjectSocketParams) => {
    const register = useCallback((socket: Socket) => {
        socket.on('SUBJECTS', (subjects: StudySubject[]) => {
            setSubjects(subjects)
        })
    }, [setSubjects])

    useWebsocket(`${IO_NAME}:${siteId}`, register)
}