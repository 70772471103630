import { hideDischargeDialog, showDischargeDialog } from "../actions"
import { useGlobalState } from "./useGlobalState"

const useDischarge = () => {
  const { state, dispatch } = useGlobalState()

  const showDialog = (id: string) => {
    dispatch(showDischargeDialog(id))
  }

  const hideDialog = () => {
    dispatch(hideDischargeDialog())
  }

  return {
    open: state.dischargeState.showDialog,
    id: state.dischargeState.id,
    showDialog,
    hideDialog
  }
}

export default useDischarge
