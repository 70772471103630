import React, { useCallback } from 'react'
import Grid from '@material-ui/core/Grid'
import SubjectList from './SubjectList'
import { EmptyInfoContainer } from '@components/EmptyInfoContainer'
import useSubjects from '@providers/subject/useSubjects'
import AddSubject from './AddSubject'
import useAccessCode from '@components/AccessCode/useAccessCode'

type SubjectListViewProps = {
  siteId: string
  studyId: string
  siteLabel: string
  showAddSubjectsButton: boolean
}

export const SubjectListView: React.FunctionComponent<SubjectListViewProps> = ({
  siteId,
  studyId,
  siteLabel,
  showAddSubjectsButton,
}) => {
  const subjects = useSubjects()
  const { showAccessCodeDialog } = useAccessCode()

  const handleNewSubject = useCallback(
    (id: string) => {
      showAccessCodeDialog(id, studyId, siteId)
    },
    [studyId, siteId, showAccessCodeDialog]
  )

  if (!subjects) {
    return <EmptyInfoContainer infoText="Subjects Unavailable" />
  }

  return (
    <React.Fragment>
      {showAddSubjectsButton && (
        <AddSubject
          siteId={siteId}
          subjectLabels={subjects.map((s) => s.label)}
          onAddSubject={handleNewSubject}
        />
      )}
      <Grid container item xs={12} spacing={2} id="studySiteSubjectsListArea">
        <Grid item xs={12}>
          <SubjectList
            studyId={studyId}
            siteId={siteId}
            siteLabel={siteLabel}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default SubjectListView
