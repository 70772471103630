import { useEffect, useRef, useState } from 'react'

type CancellablePromise<T> = {
  promise: Promise<T>
  cancel: () => void
}

const makeCancellable = <T>(p: Promise<T>): CancellablePromise<T> => {
  let isCancelled = false
  const wrappedPromise = new Promise<T>((resolve, reject) => {
    p.then((result) => !isCancelled && resolve(result)).catch(
      (err) => !isCancelled && reject(err)
    )
  })

  return {
    promise: wrappedPromise,
    cancel: () => {
      isCancelled = true
    },
  }
}

export default <T>(p: () => Promise<T>) => {
  const [state, setState] = useState<{
    data: T | null
    fetching: boolean
    error: ''
  }>({
    data: null,
    fetching: true,
    error: '',
  })

  const cancellable = useRef<undefined | CancellablePromise<T>>()
  useEffect(() => {
    cancellable.current = makeCancellable(p())
    cancellable.current.promise
      .then((result) => {
        setState((prev) => ({
          ...prev,
          data: result,
          fetching: false,
        }))
      })
      .catch((e) => {
        setState((prev) => ({
          ...prev,
          fetching: false,
          error: e.message,
        }))
      })

    return () => {
      if (cancellable.current) cancellable.current.cancel()
    }
  }, [])

  return state
}
