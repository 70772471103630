import React from 'react'
import { Grid, Card } from '@material-ui/core'
import { IInstanceConfig } from '@models/index'
import Spinner from '@components/Spinner/Spinner'
import GlobalSettings from '@components/Settings/GlobalSettings'
import ParticipantSettings from '@components/Settings/ParticipantSettings'
import AlarmSettings from '@components/Settings/AlarmSettings'
import EventsSettings from '@components/Settings/EventsSettings'
import CustomEventsSettings from '@components/Settings/CustomEventsSettings'
import useInstanceConfig from '@providers/data/hooks/useInstanceConfig'
import useParticipantActions from '@providers/data/hooks/useDataRequests'
import useToast from '@providers/data/hooks/useToast'

function Settings(): JSX.Element {
  const { instanceConfig } = useInstanceConfig()
  const { updateInstanceConfig } = useParticipantActions()
  const { createToast } = useToast()
  const onsave = (settings: IInstanceConfig) => {
    const newConfig = {
      ...instanceConfig,
      ...settings,
    }
    updateInstanceConfig(newConfig)
      .then(() => {
        createToast('Settings Saved Successfully!')
      })
      .catch((e) => {
        console.log(e)
        createToast('Error - Could Not Save Settings', 'error')
      })
  }

  if (!instanceConfig) {
    return <Spinner />
  }

  return (
    <Grid container spacing={2}>
      <Grid item sm={12} md={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card elevation={1}>
              <GlobalSettings {...instanceConfig} onsave={onsave} />
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card elevation={1}>
              <ParticipantSettings
                metas={instanceConfig.metas}
                onsave={onsave}
              />
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card elevation={1}>
              <AlarmSettings {...instanceConfig} onsave={onsave} />
            </Card>
          </Grid>
          <Grid item xs={12}>
            <CustomEventsSettings />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        item
        sm={12}
        md={6}
        alignContent={'flex-start'}
        spacing={2}
      >
        <Grid item xs={12}>
          <Card elevation={1}>
            <EventsSettings {...instanceConfig} onsave={onsave} />
          </Card>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Settings
