import {
  green,
  grey,
  lightGreen,
  orange,
  red,
  yellow,
} from '@material-ui/core/colors'
import { createTheme } from '@material-ui/core/styles'

const dexcomBlueHex = '#0075C2'
const dexcomGreenLogoHex = '#58A618'

export const darkTheme = createTheme({
  palette: {
    type: 'dark',
    primary: {
      main: dexcomGreenLogoHex,
    },
    secondary: {
      main: dexcomBlueHex,
    },
    warning: {
      main: orange[600],
    },
    info: {
      main: yellow[500],
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 750,
      md: 900,
      lg: 1110,
      xl: 1720,
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    button: {
      textTransform: 'initial',
    },
    h5: {
      fontSize: '1rem',
    },
  },
  oppositePalette: {
    backgroundColor: grey[900],
    colorInverted: grey[300],
    color: grey[100],
  },
  egvAlert: {
    backgroundColor: red[500],
    borderColor: red[500],
    color: 'inherit',
  },
  egvWarn: {
    backgroundColor: yellow[900],
    borderColor: yellow[900],
    color: 'inherit',
  },
  egvNormal: {
    backgroundColor: dexcomGreenLogoHex,
    borderColor: dexcomGreenLogoHex,
    color: grey[100],
  },
  egvInactive: {
    backgroundColor: grey[200],
    borderColor: grey[200],
    color: grey[700],
  },
  inRange: {
    backgroundColor: green[700],
    borderColor: green[700],
    color: lightGreen[50],
  },
  statusCreated: {
    backgroundColor: grey[50],
    borderColor: grey[50],
    color: yellow[700],
  },
  statusReady: {
    backgroundColor: grey[50],
    borderColor: dexcomBlueHex,
    color: dexcomBlueHex,
  },
  statusInProgress: {
    backgroundColor: lightGreen[50],
    borderColor: lightGreen[50],
    color: dexcomGreenLogoHex,
  },
  statusCheckData: {
    backgroundColor: red[50],
    borderColor: red[50],
    color: red[500],
  },
  statusCompleted: {
    backgroundColor: grey[700],
    borderColor: grey[300],
    color: grey[100],
  },
})
