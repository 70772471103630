import { downloadCsv } from '@components/ExportData/useDataExport'
import { exportSubject } from '@lib/data/export.data'
import useKeycloak from '@providers/auth/useKeycloak'
import useToast from '@providers/data/hooks/useToast'

export default function useSubjectDataExport() {
  const { createToast } = useToast()
  const { token } = useKeycloak()

  const exportSubjectData = (siteId: string, id: string) => {
    createToast('Generating Report')
    exportSubject(token, siteId, id)
      .then(({ filename, data }) => {
        downloadCsv(`${filename}_${new Date().toISOString()}.csv`, data)
      })
      .catch((e) => {
        console.error(e)
        createToast('Could not export data', 'error')
      })
  }

  return {
    exportSubjectData,
  }
}
