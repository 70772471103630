import { required } from '@lib/validate'
import { ValidationResult } from '@models/api.models'
import {
  ClientConfigErrors,
  ClientConfig,
  emptyClientConfigErrors,
} from '@models/clientConfig.models'

export const validateLowerTargetRange = (
  threshold: number,
  upperTargetRange: number
) => {
  return threshold > 60 && threshold < upperTargetRange
    ? ''
    : `Value must be between 60 and ${upperTargetRange}`
}

export const validateUpperTargetRange = (
  threshold: number,
  lowerTargetRange: number
) => {
  return threshold < 400 && threshold > lowerTargetRange
    ? ''
    : `Value must be between ${lowerTargetRange} and 400`
}

export const validateGoalPercentage = (goalPercentage: number) => {
  return goalPercentage >= 0 && goalPercentage <= 100
    ? ''
    : 'Value must be between 0 and 100'
}

export const validateTrendGraphHeight = (graphHeight: number) => {
  return graphHeight >= 0 && graphHeight <= 400
    ? ''
    : 'Value must be between 0 and 400'
}

export const validateHighThreshold = (threshold: number) => {
  return threshold < 400 ? '' : 'Value must be less than 400'
}

export const validateLowThreshold = (threshold: number) => {
  return threshold > 60 ? '' : 'Value must be greater than 60'
}

export const validateHighAlerts = (values: ClientConfig) => {
  return values.isHighAlertsEnabled
    ? validateHighThreshold(values.highAlertThreshold)
    : ''
}

export const validateLowAlerts = (values: ClientConfig) => {
  return values.isLowAlertsEnabled
    ? validateLowThreshold(values.lowAlertThreshold)
    : ''
}

export const validateContactInformation = (contactInfo: string) => {
  return required(contactInfo)
}

export const validateSiteIsCustomized = (
  values: ClientConfig
): ValidationResult<ClientConfigErrors> => {
  const validateClientConfigResult = validateClientConfig(values)
  const contactInfoErrors = validateContactInformation(values.contactInfo)

  if (!validateClientConfigResult.valid) {
    if (contactInfoErrors !== '') {
      return {
        ...validateClientConfigResult,
        errors: {
          ...validateClientConfigResult.errors,
          contactInfo: contactInfoErrors,
        },
      }
    }
  }

  if (contactInfoErrors !== '') {
    return {
      valid: false,
      errors: {
        ...emptyClientConfigErrors,
        contactInfo: contactInfoErrors,
      },
    }
  }

  return {
    valid: true,
    errors: emptyClientConfigErrors,
  }
}

export const validateClientConfig = (
  clientConfig: ClientConfig
): ValidationResult<ClientConfigErrors> => {
  let valid = true

  const errors: ClientConfigErrors = emptyClientConfigErrors

  errors.glucoseTargetRangeLower = validateLowerTargetRange(
    clientConfig.glucoseTargetRangeLower,
    clientConfig.glucoseTargetRangeUpper
  )
  valid = valid && errors.glucoseTargetRangeLower === ''

  errors.glucoseTargetRangeUpper = validateUpperTargetRange(
    clientConfig.glucoseTargetRangeUpper,
    clientConfig.glucoseTargetRangeLower
  )
  valid = valid && errors.glucoseTargetRangeUpper === ''

  errors.targetRangeGoalPercentage = validateGoalPercentage(
    clientConfig.targetRangeGoalPercentage
  )
  valid = valid && errors.targetRangeGoalPercentage === ''

  errors.trendGraphHeight = validateTrendGraphHeight(
    clientConfig.trendGraphHeight
  )
  valid = valid && errors.trendGraphHeight === ''

  errors.highAlertThreshold = validateHighAlerts(clientConfig)
  valid = valid && errors.highAlertThreshold === ''

  errors.lowAlertThreshold = validateLowAlerts(clientConfig)
  valid = valid && errors.lowAlertThreshold === ''

  return { valid, errors }
}

export const hasChanged = (
  current: ClientConfig | null,
  next: ClientConfig | null
): boolean => {
  if (!current || !next) return false

  return (
    current.isStudyBlinded !== next.isStudyBlinded ||
    current.isMealPhotoAllowed !== next.isMealPhotoAllowed ||
    current.isUnitsMmol !== next.isUnitsMmol ||
    current.glucoseTargetRangeLower !== next.glucoseTargetRangeLower ||
    current.glucoseTargetRangeUpper !== next.glucoseTargetRangeUpper ||
    current.targetRangeGoalPercentage !== next.targetRangeGoalPercentage ||
    current.trendGraphHeight !== next.trendGraphHeight ||
    current.isHighAlertsEnabled !== next.isHighAlertsEnabled ||
    current.isHighAlertsAudible !== next.isHighAlertsAudible ||
    current.isLowAlertsEnabled !== next.isLowAlertsEnabled ||
    current.isLowAlertsAudible !== next.isLowAlertsAudible ||
    current.highAlertThreshold !== next.highAlertThreshold ||
    current.lowAlertThreshold !== next.lowAlertThreshold ||
    current.contactInfo !== next.contactInfo
  )
}
