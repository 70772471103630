import React, { useContext } from 'react'
import { useTheme } from '@material-ui/core/styles'
import { Button, Grid, Typography } from '@material-ui/core'
import { ThemeContext } from '@providers/themes/ThemeProvider'
import { REACT_APP_DASHBOARD_VERSION } from '@config/env'
import useDashboardStyles from '@components/GlobalStyles'

function Footer(): JSX.Element {
  const theme = useTheme()
  const classes = useDashboardStyles(theme)
  const setThemeName = useContext(ThemeContext)
  const year = new Date().getFullYear()

  return (
    <Grid
      container
      item
      xs={12}
      className={classes.footer}
      justifyContent="center"
      alignItems="center"
      style={{
        color: theme.oppositePalette.color,
        background: theme.oppositePalette.backgroundColor,
        textAlign: 'center',
      }}
    >
      <Grid item xs={12} lg={12}>
        <Typography>
          <Button
            color="inherit"
            variant="text"
            onClick={() => setThemeName('lightTheme')}
            style={{
              padding: '0px',
              cursor: 'inherit',
            }}
          >
            &#169; {year} Dexcom Inc.
          </Button>
          <Button
            color="inherit"
            variant="text"
            style={{
              padding: '0px',
              cursor: 'inherit',
            }}
            onClick={() => setThemeName('darkTheme')}
          >
            , 6340 Sequence Dr, San Diego, CA 92121
          </Button>
        </Typography>
      </Grid>
      <Grid item xs={12} lg={12}>
        <Typography>
          CAUTION Investigational device. Limited by Federal (United States) law
          to investigational use.
        </Typography>
      </Grid>
      <Grid item xs={12} lg={12}>
        v{REACT_APP_DASHBOARD_VERSION}
      </Grid>
    </Grid>
  )
}

export default Footer
