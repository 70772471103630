import { Grid } from '@material-ui/core'
import React from 'react'
import { useTheme } from '@material-ui/core/styles'
import ViewHeadline from '@material-ui/icons/ViewHeadline'
import ViewStreamIcon from '@material-ui/icons/ViewStream'
import ViewComfy from '@material-ui/icons/ViewComfy'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup/ToggleButtonGroup'
import ToggleButton from '@material-ui/lab/ToggleButton/ToggleButton'
import useUserConfig from '@providers/data/hooks/useUserConfig'

const DashboardViewToggle: React.FC = () => {
  const theme = useTheme()
  const { userConfig: dashboardView, setUserConfigDashboardView } =
    useUserConfig()

  const handleDashboardView = (
    event: React.MouseEvent<HTMLElement>,
    dashboardView: string
  ) => {
    if (dashboardView) {
      setUserConfigDashboardView(dashboardView)
    }
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <ToggleButtonGroup
          value={dashboardView.dashboardView}
          exclusive
          onChange={handleDashboardView}
          aria-label="text alignment"
          size="small"
          style={{
            backgroundColor: theme.palette.background.paper,
            color: theme.oppositePalette.colorInverted,
          }}
        >
          <ToggleButton value="listView" aria-label="listView aligned">
            <ViewHeadline fontSize="small"></ViewHeadline>
          </ToggleButton>
          <ToggleButton value="cardView" aria-label="cardView aligned">
            <ViewStreamIcon fontSize="small"></ViewStreamIcon>
          </ToggleButton>
          <ToggleButton value="graphView" aria-label="graphView aligned">
            <ViewComfy fontSize="small"></ViewComfy>
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
    </Grid>
  )
}

export default DashboardViewToggle
