import Keycloak from "keycloak-js";
import {
  REACT_APP_DEXCOM_KEYCLOAK_URL,
  REACT_APP_DEXCOM_KEYCLOAK_CLIENT_ID,
  REACT_APP_DEXCOM_KEYCLOAK_REALM,
} from "./env";

const keycloak = Keycloak({
  url: `${REACT_APP_DEXCOM_KEYCLOAK_URL}/auth`,
  realm: REACT_APP_DEXCOM_KEYCLOAK_REALM || '',
  clientId: REACT_APP_DEXCOM_KEYCLOAK_CLIENT_ID ||'',
});

export default keycloak;
