/*eslint-disable  @typescript-eslint/no-non-null-assertion */
/*eslint-disable  @typescript-eslint/explicit-module-boundary-types */
import { useContext } from "react";
import { KeycloakTokenParsed } from "keycloak-js";
import { keycloakContext } from "./keycloakProvider";

export interface ParsedKeycloakToken extends KeycloakTokenParsed {
  active_group: string
  email: string
}

const useKeycloak = () => {
  const ctx = useContext(keycloakContext);

  if (!ctx) {
    throw new Error(
      "useKeycloak hook must be used inside KeycloakProvider context"
    );
  }

  if (!ctx.authClient) {
    throw new Error("authClient has not been assigned to KeycloakProvider");
  }

  const { authClient, initialized } = ctx;

  const logout = () => {
    const location = window.location
    authClient.logout({
      redirectUri: `${location.protocol}//${location.host}`
    })
  }

  return {
    initialized,
    keycloak: authClient,
    parsed: authClient.tokenParsed! as ParsedKeycloakToken,
    token: authClient.token!,
    logout: logout,
  };
};

export default useKeycloak;
