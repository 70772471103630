import React from 'react'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import Divider from '@material-ui/core/Divider'
import TextField from '@material-ui/core/TextField'
import CardContent from '@material-ui/core/CardContent'
import { Grid, useTheme } from '@material-ui/core'
import useDashboardStyles from '@components/GlobalStyles'
import { ClientConfig, ClientConfigErrors } from '@models/clientConfig.models'

type SiteContactInstructionsProps = {
  values: ClientConfig
  errors: ClientConfigErrors
  canEdit: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export default function SiteContactInstructions(
  props: SiteContactInstructionsProps
) {
  const theme = useTheme()
  const classes = useDashboardStyles(theme)
  const { values, errors } = props

  return (
    <Card>
      <CardHeader
        title="Site Contact Instructions"
        classes={{
          root: classes.cardRoot,
          title: classes.cardTitle,
          action: classes.cardAction,
        }}
        color="textSecondary"
      />
      <Divider />
      <CardContent>
        <Grid container spacing={2}>
          <Grid container item xs>
            <Grid item xs>
              <TextField
                id="contactInfo"
                placeholder="Site contact instructions are shown to subjects in the app."
                variant="outlined"
                multiline
                minRows={14}
                style={{ width: '100%' }}
                disabled={!props.canEdit}
                value={values.contactInfo}
                error={errors.contactInfo !== ''}
                helperText={errors.contactInfo}
                onChange={props.onChange}
              />
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
