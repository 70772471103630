import useDashboardStyles from '@components/GlobalStyles'
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core'
import useToast from '@providers/data/hooks/useToast'
import React, { useEffect, useState } from 'react'

type EditableProtocolTitleProps = {
  protocolTitle: string
  canEdit?: boolean
  //   onProtocolTitleChange: (protocolTitle: string) => void
}

const EditableProtocolTitle: React.FunctionComponent<
  EditableProtocolTitleProps
> = ({ protocolTitle, canEdit }) => {
  const theme = useTheme()
  const classes = useDashboardStyles(theme)
  const [canSave, setCanSave] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  const [state, setState] = useState('')
  const { createToast } = useToast()

  useEffect(() => {
    setState(protocolTitle)
  }, [protocolTitle])

  const handleSaveClicked = () => {
    setCanSave(state !== protocolTitle)
    setIsEditMode(false)
    createToast('WE NEED TO Save Protocol Title!', 'warning')
  }

  const handleEditClicked = () => {
    setIsEditMode(true)
  }

  const handleCancelClicked = () => {
    setIsEditMode(false)
    setState(protocolTitle)
  }

  const handleFieldChange =
    (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value
      setCanSave(value !== protocolTitle)
      setState(value)
      console.log(value)
    }

  return (
    <React.Fragment>
      <Card style={{ width: '100%' }}>
        <CardHeader
          title="Protocol Title"
          classes={{
            root: classes.cardRoot,
            title: classes.cardTitle,
            action: classes.cardAction,
          }}
          action={
            canEdit &&
            (isEditMode ? (
              <Grid container spacing={2} justifyContent={'flex-end'}>
                <Grid item xs={6}>
                  <Button
                    color="secondary"
                    variant="outlined"
                    style={{
                      textTransform: 'initial',
                      display: 'inline',
                    }}
                    onClick={handleCancelClicked}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    color="primary"
                    variant="contained"
                    style={{
                      textTransform: 'initial',
                      display: 'inline',
                    }}
                    onClick={handleSaveClicked}
                    disabled={!canSave}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <Button
                color="primary"
                variant="outlined"
                style={{ textTransform: 'initial' }}
                onClick={handleEditClicked}
              >
                Edit
              </Button>
            ))
          }
        />
        <Divider />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {!isEditMode && (
                <Typography
                  variant="body2"
                  color="textPrimary"
                  style={{
                    width: '100%',
                    maxHeight: '160pt',
                  }}
                >
                  {protocolTitle}
                </Typography>
              )}
            </Grid>
            {isEditMode && (
              <TextField
                id="protocolTitle"
                label="Protocol Title"
                variant="outlined"
                multiline
                rows={5}
                value={state}
                error={state === ''}
                helperText={
                  state === '' ? 'Protocol Title cannot be blank.' : ''
                }
                title="Enter the full study name or protocol title.  If could be used later to search for this study."
                style={{ width: '100%' }}
                onChange={handleFieldChange('protocolTitle')}
              />
            )}
          </Grid>
        </CardContent>
      </Card>
    </React.Fragment>
  )
}

export default EditableProtocolTitle
