import React, { useMemo } from 'react'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt'
import RemoveIcon from '@material-ui/icons/Remove'
import { useTheme } from '@material-ui/core'

enum EGV_TREND {
  DOUBLE_UP = 'doubleup',
  FORTY_FIVE_UP = 'fortyfiveup',
  DOUBLE_DOWN = 'doubledown',
  FORTY_FIVE_DOWN = 'fortyfivedown',
  FLAT = 'flat',
  SINGLE_UP = 'singleup',
  SINGLE_DOWN = 'singledown',
}

interface TrendArrowProps {
  trend: string
  big?: boolean
}

const TrendArrow: React.FunctionComponent<TrendArrowProps> = ({
  trend,
  big,
}) => {
  const theme = useTheme()
  const memoizedTrendRate = useMemo(() => {
    switch (trend.toLowerCase()) {
      case EGV_TREND.DOUBLE_UP:
      case EGV_TREND.SINGLE_UP:
        return 270
      case EGV_TREND.DOUBLE_DOWN:
      case EGV_TREND.SINGLE_DOWN:
        return 90
      case EGV_TREND.FORTY_FIVE_UP:
        return 315
      case EGV_TREND.FORTY_FIVE_DOWN:
        return 45
      case EGV_TREND.FLAT:
        return 0
      default:
        return undefined
    }
  }, [trend])

  if (memoizedTrendRate === undefined) {
    return (
      <RemoveIcon
        data-testid="unknown-trendArrow"
        style={{
          transform: `scale(${big ? 2 : 1})`,
          position: 'relative',
          color: big
            ? theme.palette.text.primary
            : theme.palette.text.secondary,
        }}
      />
    )
  }

  return (
    <ArrowRightAltIcon
      data-testid="trendArrow"
      style={{
        transform: `scale(${big ? 2 : 1}) rotate(${memoizedTrendRate}deg)`,
        position: 'relative',
        color: big ? theme.palette.text.primary : theme.palette.text.secondary,
      }}
    />
  )
}

export default TrendArrow
