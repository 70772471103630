import React from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import useTheme from '@material-ui/core/styles/useTheme'
import useDashboardStyles from '@components/GlobalStyles'

interface AddSubjectFormProps {
  label: string
  error: string
  setLabel: (label: string) => void
}

const AddSubjectForm = (props: AddSubjectFormProps) => {
  const theme = useTheme()
  const classes = useDashboardStyles(theme)

  const handleInput = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value
    props.setLabel(value)
  }

  return (
    <Grid container justifyContent="space-around" alignItems={'center'}>
      <Grid item xs={9} style={{ marginTop: 10 }}>
        <TextField
          required
          variant="outlined"
          id={'label'}
          label={'Subject Id'}
          value={props.label}
          onChange={handleInput}
          className={classes.userTextField}
          error={props.error !== ''}
          helperText={props.error}
        />
      </Grid>
    </Grid>
  )
}

export default AddSubjectForm
