import React, { useMemo } from 'react'
import Button from '@material-ui/core/Button'
import { DataGrid, GridCellParams, GridColumns } from '@material-ui/data-grid'
import { IInstanceDetails } from '@models/index'

interface InstancesListViewProps {
  instances: Array<IInstanceDetails>
  onDelete: (id: string) => void
}

export const InstancesListView: React.FunctionComponent<
  InstancesListViewProps
> = ({ instances, onDelete }) => {
  const columnsDefinition = useMemo(() => {
    const columns: GridColumns = [
      {
        field: 'instanceId',
        headerName: 'Instance Id',
        width: 200,
      },
      {
        field: 'title',
        headerName: 'Title',
        width: 200,
      },
      {
        field: 'userCount',
        headerName: 'Staff',
        width: 200,
      },
      {
        field: 'isVoyager',
        headerName: 'Voyager',
        width: 200,
      },
      {
        field: 'delete',
        headerName: 'Delete',
        width: 200,
        renderCell: (params: GridCellParams) => {
          const instanceId = params.getValue(params.id, 'instanceId') as string
          return (
            <Button
              color="primary"
              variant="contained"
              style={{
                textTransform: 'initial',
                width: '90%',
              }}
              onClick={() => onDelete(instanceId)}
            >
              Delete
            </Button>
          )
        },
      },
    ]
    return columns
  }, [onDelete])

  if (instances.length === 0) {
    return <div>No Instances</div>
  }

  return (
    <DataGrid
      rows={instances}
      columns={columnsDefinition}
      hideFooter
      density="compact"
      autoHeight
    />
  )
}
