import React, { useState } from 'react'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogActions from '@material-ui/core/DialogActions'
import CloseIcon from '@material-ui/icons/Close'
import {
  Button,
  Divider,
  Grid,
  IconButton,
  Typography,
  useTheme,
  TextField,
  Dialog,
} from '@material-ui/core'
import { IInstanceConfig } from '@models/index'
import { IParticipantData } from '@models/index'
import useDataRequests from '@providers/data/hooks/useDataRequests'
import useDashboardStyles from '@components/GlobalStyles'

type MetadataFormProps = {
  setEditFormOpen: (isOpen: boolean) => void
  editFormOpen: boolean
  siteConfig: IInstanceConfig
  participantInfo: IParticipantData
}
export default function MetadataFormModal(props: MetadataFormProps) {
  const theme = useTheme()
  const classes = useDashboardStyles(theme)
  const [meta2Value, setMeta2Value] = useState(
    props.participantInfo.metadata[2]
  )
  const { updateMetadataValue } = useDataRequests()

  const handleCancel = () => {
    props.setEditFormOpen(false)
  }
  const handleSubmit = () => {
    updateMetadataValue(props.participantInfo.id, meta2Value).then(() => {
      props.setEditFormOpen(false)
    })
  }
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMeta2Value(event.target.value)
  }

  return (
    <Dialog
      open={props.editFormOpen}
      onClose={handleCancel}
      aria-labelledby="form-dialog-title"
      fullWidth
    >
      <MuiDialogTitle>
        <Typography style={{ color: theme.palette.text.secondary }}>
          Edit Patient
        </Typography>
        <IconButton
          className={classes.closeButton}
          aria-label="close"
          onClick={handleCancel}
        >
          <CloseIcon />
        </IconButton>
      </MuiDialogTitle>
      <Divider />
      <Grid container justifyContent="space-around" alignItems={'center'}>
        <Grid item xs={9} style={{ marginTop: 10 }}>
          <TextField
            size="small"
            variant="outlined"
            id={props.siteConfig.metas[2].displayname}
            label={props.siteConfig.metas[2].displayname}
            value={meta2Value}
            className={classes.userTextField}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      <MuiDialogActions>
        <Button
          onClick={handleCancel}
          style={{ marginRight: 10 }}
          variant="outlined"
        >
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          Submit
        </Button>
      </MuiDialogActions>
    </Dialog>
  )
}
