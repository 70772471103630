import { useEffect, useState } from "react"

export default (fidelity: number) => {
    const [state, setState] = useState(Date.now())

    useEffect(() => {
        const interval = setInterval(() => {
            setState(Date.now())
        }, fidelity)

        return () => {
            clearInterval(interval)
        }
    }, [])

    return state
}