import { StudySubject } from "@models/api.models"

export enum Actions {
    SET_SUBJECTS = 'SET_SUBJECTS'
}

type SetSubjectsAction = {
    type: Actions.SET_SUBJECTS,
    payload: StudySubject[]
}

export type Action = 
    | SetSubjectsAction

export const setSubjectsAction = (subjects: StudySubject[]) => ({
    type: Actions.SET_SUBJECTS,
    payload: subjects
})