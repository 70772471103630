import React from 'react'

interface ClickableParticipantProps {
  pid: string
  onClick: (pid: string) => void
}

const ClickableParticipant: React.FunctionComponent<ClickableParticipantProps> = ({ children, pid, onClick }) => {
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault()
    onClick(pid)
  }

  return (
    <div onClick={handleClick}>
      {children}
    </div>
  )
}

export default ClickableParticipant
