import React, { useCallback, useMemo, useState } from 'react'
import Button from '@material-ui/core/Button'
import useCreateStudy from './useCreateStudy'
import CreateBasicInfoForm from './CreateBasicInfoForm'
import CreateStudySitesForm from './CreateStudySitesForm'
import CreateStudyStaffAndContactForm from './CreateStudyStaffAndContactForm'
import useStudies from '@providers/data/hooks/useStudies'

import {
  DialogContent,
  Grid,
  makeStyles,
  Typography,
  useTheme,
  withStyles,
} from '@material-ui/core'

import { StepIconProps } from '@material-ui/core/StepIcon'
import clsx from 'clsx'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import LocationSearchingOutlinedIcon from '@material-ui/icons/LocationSearchingOutlined'
import ContactMailOutlinedIcon from '@material-ui/icons/ContactMailOutlined'
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined'
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined'
import StepConnector from '@material-ui/core/StepConnector'
import MuiDialogActions from '@material-ui/core/DialogActions'
import StudySummary from './StudySummary'

type StudyWizardProps = {
  onClose: () => void
}
const getSteps = () => {
  return ['Add Basic Info', 'Add Site(s)', 'Add Dexcom Contact', 'Summary']
}

// DEX GREEN 19,125,0
// DEX LIGHT GREEN 188,237,175
// DEX DARK GREEN 0,77,42
const FancyDexcomConnector = withStyles({
  alternativeLabel: {
    top: 18,
  },
  active: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgb(188,237,175) 0%,rgb(19,125,0) 50%,rgb(0,77,42) 100%)',
    },
  },
  completed: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgb(188,237,175) 0%,rgb(19,125,0) 50%,rgb(0,77,42) 100%)',
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector)

const useStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 40,
    height: 40,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundImage:
      'linear-gradient( 136deg, rgb(188,237,175) 0%, rgb(19,125,0) 50%, rgb(0,77,42) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundImage:
      'linear-gradient( 136deg, rgb(188,237,175) 0%, rgb(19,125,0) 50%, rgb(0,77,42) 100%)',
  },
})

function StepIcons(props: StepIconProps) {
  const classes = useStepIconStyles()
  const { active, completed } = props

  const icons: { [index: string]: React.ReactElement } = {
    1: <AssignmentOutlinedIcon />,
    2: <LocationSearchingOutlinedIcon />,
    3: <ContactMailOutlinedIcon />,
    4: <CheckCircleOutlineOutlinedIcon />,
  }

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  )
}

const StudyWizard = (props: StudyWizardProps) => {
  const theme = useTheme()
  const { values, errors, actions } = useCreateStudy()
  const [creating, setCreating] = useState(false)
  const { createStudy, findDuplicates } = useStudies()
  const [confirmStep, setConfirmStep] = useState({
    confirm: false,
    confirmMessage: '',
  })
  const [state, setState] = useState('entry')
  const [activeStep, setActiveStep] = useState(0)
  const steps = getSteps()

  const handleCancel = useCallback(() => {
    if (state === 'exit') {
      setState('entry')
    } else if (state === 'confirmed') {
      setState('entry')
    } else if (state === 'entry') {
      setState('exit')
    }
  }, [state])

  const confirmSubmit = useCallback(() => {
    setCreating(true)
    createStudy(values)
      .then((success) => {
        if (success) {
          actions.reset()
          setState('confirmed')
        }
      })
      .finally(() => {
        setCreating(false)
      })
  }, [createStudy, values, actions])

  const handleSubmit = () => {
    actions.resetErrors()
    const valid = actions.validateStep(activeStep)
    if (valid) {
      confirmSubmit()
    }
  }

  const handleAddSite = () => {
    actions.resetErrors()
    actions.addSite()
  }

  const confirmCancel = () => {
    actions.reset()
    setActiveStep(0)
    props.onClose()
  }

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <CreateBasicInfoForm
            values={values}
            errors={errors}
            onFieldChange={actions.fieldChange}
            confirm={confirmStep.confirm}
            confirmMessage={confirmStep.confirmMessage}
          />
        )
      case 1:
        return (
          <CreateStudySitesForm
            values={values}
            errors={errors}
            onFieldChange={actions.fieldChange}
            onSiteFieldChange={actions.siteFieldChange}
            onAddSite={handleAddSite}
            onRemoveSite={actions.removeSite}
          />
        )
      case 2:
        return (
          <CreateStudyStaffAndContactForm
            values={values}
            errors={errors}
            onFieldChange={actions.fieldChange}
            onSiteFieldChange={actions.siteFieldChange}
            onSiteFieldChangeOnAllSites={actions.siteFieldChangeOnAllSites}
            onStaffFieldChange={actions.staffFieldChange}
            onStaffRoleChange={actions.staffRoleChange}
            onAddStaff={actions.addStaff}
            onRemoveStaff={actions.removeStaff}
          />
        )
      case 3:
        return (
          <Grid container justifyContent="center">
            <Grid item xs={10}>
              <StudySummary study={values} />
            </Grid>
          </Grid>
        )
      default:
        return (
          <Typography variant="h6" color="error">
            Unknown step
          </Typography>
        )
    }
  }

  const specialCheckBasicInfo = useCallback(() => {
    const duplicates = findDuplicates(
      values.name,
      values.protocolId,
      values.protocolTitle
    )
    if (duplicates.length > 0) {
      setConfirmStep({
        confirm: true,
        confirmMessage: 'An existing study was found using the given info.',
      })
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }
  }, [values.protocolId, values.protocolTitle, values.name, findDuplicates])

  const specialChecks = useMemo<Record<number, () => void>>(
    () => ({
      0: specialCheckBasicInfo,
    }),
    [specialCheckBasicInfo]
  )

  const handleNext = useCallback(() => {
    actions.resetErrors()
    if (actions.validateStep(activeStep)) {
      if (confirmStep.confirm) {
        setConfirmStep({ confirm: false, confirmMessage: '' })
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
      } else {
        const specialCheck = specialChecks[activeStep]
        if (specialCheck !== undefined) {
          specialCheck()
        } else {
          setActiveStep((prevActiveStep) => prevActiveStep + 1)
        }
      }
    }
  }, [confirmStep, activeStep, actions, specialChecks])

  const handleBack = useCallback(() => {
    if (confirmStep.confirm) {
      setConfirmStep({
        confirm: false,
        confirmMessage: '',
      })
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1)
    }
  }, [confirmStep])

  if (state === 'exit') {
    return (
      <React.Fragment>
        <DialogContent
          style={{
            backgroundColor: theme.palette.background.default,
          }}
        >
          <Grid
            container
            alignContent={'center'}
            alignItems={'center'}
            style={{ minHeight: '35vh', textAlign: 'center' }}
          >
            <Grid item xs={12}>
              <Typography variant={'subtitle1'} color="secondary">
                Stop Creating The New Study?
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ marginTop: '10pt' }}>
              <Typography variant={'body2'} color="textSecondary">
                You will lose all entered information.
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <MuiDialogActions>
          <Button variant="outlined" onClick={handleCancel}>
            No
          </Button>
          <Button variant="contained" onClick={confirmCancel} color="secondary">
            Yes
          </Button>
        </MuiDialogActions>
      </React.Fragment>
    )
  }

  if (state === 'confirmed') {
    return (
      <React.Fragment>
        <DialogContent
          style={{
            backgroundColor: theme.palette.background.default,
          }}
        >
          <Grid
            container
            alignContent={'center'}
            alignItems={'center'}
            justifyContent="center"
            style={{ minHeight: '35vh' }}
          >
            <Grid
              item
              container
              style={{
                textAlign: 'center',
              }}
            >
              <Grid
                item
                xs={12}
                style={{
                  marginTop: '30pt',
                }}
              >
                <Typography
                  variant={'subtitle1'}
                  style={{ color: theme.palette.secondary.dark }}
                >
                  New Study Added!
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ margin: '10pt' }}>
                <Typography variant={'body2'} color="textSecondary">
                  An email invite will be sent to the study staff to get
                  started.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <MuiDialogActions>
          <Button
            variant="contained"
            disabled={creating}
            onClick={props.onClose}
            color="primary"
          >
            Ok
          </Button>
        </MuiDialogActions>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <DialogContent
        style={{
          backgroundColor: theme.palette.background.default,
        }}
      >
        <Grid
          container
          alignContent={'center'}
          alignItems={'center'}
          style={{ minHeight: '25vh' }}
        >
          <Grid item xs={12}>
            <Stepper
              style={{
                backgroundColor: theme.palette.background.default,
              }}
              alternativeLabel
              activeStep={activeStep}
              connector={<FancyDexcomConnector />}
            >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel StepIconComponent={StepIcons}>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Grid>
          <Grid item xs={12}>
            {getStepContent(activeStep)}
          </Grid>
        </Grid>
      </DialogContent>

      <MuiDialogActions>
        <Button variant="outlined" onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          disabled={activeStep === 0 && !confirmStep.confirm}
          variant="outlined"
          color="secondary"
          onClick={handleBack}
        >
          Back
        </Button>

        {activeStep !== steps.length - 1 && (
          <Button variant="contained" color="primary" onClick={handleNext}>
            Next
          </Button>
        )}
        {activeStep === steps.length - 1 && (
          <Button
            variant="contained"
            onClick={handleSubmit}
            color="primary"
            disabled={creating}
          >
            Confirm
          </Button>
        )}
      </MuiDialogActions>
    </React.Fragment>
  )
}

export default StudyWizard
