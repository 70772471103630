import { ActionType, AllActions } from './actions'
import { SiteCreateErrors, StudyFormState } from './models'
import { NewSite } from '@models/api.models'
import { staffErrorReducer, staffListReducer } from '@components/Staff/reducer'

const siteReducer = (state: NewSite, action: ActionType) => {
  switch (action.type) {
    case AllActions.SITE_FIELD_CHANGE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      }
    case AllActions.ADD_STAFF:
    case AllActions.REMOVE_STAFF:
    case AllActions.STAFF_ADD_ROLE:
    case AllActions.STAFF_REMOVE_ROLE:
    case AllActions.STAFF_FIELD_CHANGE:
      return {
        ...state,
        staff: staffListReducer(state.staff, action),
      }
    case AllActions.CLEAR_INTERNAL_FIELDS:
      return {
        ...state,
        contactInstructions: '',
        staff: [],
      }
    default:
      return state
  }
}

const siteListReducer = (state: NewSite[], action: ActionType) => {
  switch (action.type) {
    case AllActions.ADD_STAFF:
    case AllActions.REMOVE_STAFF:
    case AllActions.STAFF_FIELD_CHANGE:
    case AllActions.STAFF_ADD_ROLE:
    case AllActions.STAFF_REMOVE_ROLE:
    case AllActions.SITE_FIELD_CHANGE: {
      const sites = [...state]
      sites[action.payload.siteIndex] = siteReducer(
        sites[action.payload.siteIndex],
        action
      )
      return sites
    }
    case AllActions.SITE_FIELD_CHANGE_ON_ALL_SITES: {
      const sites = [...state]
      const updatedSites = sites.map((site) => {
        return {
          ...site,
          [action.payload.key]: action.payload.value,
        } as NewSite
      })
      return updatedSites
    }
    case AllActions.ADD_SITE:
      return [
        ...state,
        {
          label: '',
          country: '',
          siteAdministrator: '',
          contactInstructions: '',
          staff: [],
        },
      ]
    case AllActions.REMOVE_SITE: {
      const sites = [...state]
      sites.splice(action.payload.siteIndex, 1)
      return sites
    }
    case AllActions.CLEAR_INTERNAL_FIELDS: {
      return state.map((site) => siteReducer(site, action))
    }
    default:
      return state
  }
}

const siteErrorReducer = (state: SiteCreateErrors[], action: ActionType) => {
  switch (action.type) {
    case AllActions.REMOVE_SITE: {
      const siteErrors = [...state]
      siteErrors.splice(action.payload.siteIndex, 1)
      return siteErrors
    }
    case AllActions.REMOVE_STAFF: {
      const siteErrors = [...state]
      siteErrors[action.payload.siteIndex].staff = staffErrorReducer(
        siteErrors[action.payload.siteIndex].staff,
        action
      )
      return siteErrors
    }
    default:
      return state
  }
}

const reducer = (state: StudyFormState, action: ActionType) => {
  switch (action.type) {
    case AllActions.FIELD_CHANGE:
      return {
        ...state,
        values: {
          ...state.values,
          [action.payload.key]: action.payload.value,
        },
      }
    case AllActions.ADD_STAFF:
    case AllActions.REMOVE_STAFF:
    case AllActions.STAFF_FIELD_CHANGE:
    case AllActions.STAFF_ADD_ROLE:
    case AllActions.STAFF_REMOVE_ROLE:
    case AllActions.SITE_FIELD_CHANGE:
    case AllActions.SITE_FIELD_CHANGE_ON_ALL_SITES:
    case AllActions.ADD_SITE:
    case AllActions.REMOVE_SITE:
    case AllActions.CLEAR_INTERNAL_FIELDS:
      return {
        ...state,
        values: {
          ...state.values,
          sites: siteListReducer(state.values.sites, action),
        },
        errors: {
          ...state.errors,
          sites: siteErrorReducer(state.errors.sites, action),
        },
      }
    case AllActions.SET_ERRORS:
      return {
        ...state,
        errors: action.payload,
      }
    case AllActions.RESET:
      return { ...action.payload }
    default:
      return state
  }
}

export default reducer
