import { useMemo } from 'react'
import useParticipantMap from '@providers/data/hooks/useParticipants'

export const useParticipantById = (id: string) => {
  const { participantData } = useParticipantMap()
  const participant = useMemo(() => {
    return participantData[id]
  }, [id, participantData])
  return participant
}
