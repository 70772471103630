import React, { useMemo } from 'react'
import {
  DataGrid,
  GridCellParams,
  GridColumns,
  GridValueGetterParams,
} from '@material-ui/data-grid'
import { Theme, useTheme } from '@material-ui/core'
import { MENU_OPTION } from '@models/index'
import { StudyActions } from '@models/access.models'
import useAccess from '@providers/access/useAccess'
import ActionMenu from '../ActionMenu/ActionMenu'
import { EmptyInfoContainer } from '@components/EmptyInfoContainer'
import StatusIndicator from './StatusIndicator'
import { ComposedSubject, IndicatorStatus } from './models'
import useSubjects from './useSubjects'

const SimpleColumns: GridColumns = [
  {
    field: 'label',
    headerName: 'Subject Id',
    flex: 1,
  },
]

const ExpandedColumns = (theme: Theme): GridColumns => {
  return [
    {
      field: 'indicator',
      headerName: ' ',
      width: 30,
      renderCell: (params: GridCellParams) => (
        <StatusIndicator
          status={params.value as IndicatorStatus}
          theme={theme}
        />
      ),
    },
    {
      field: 'label',
      headerName: 'Subject Id',
      width: 140,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
    },
    {
      field: 'lastEgv',
      headerName: 'Last EGV Received',
      flex: 1,
    },
    {
      field: 'firstEgv',
      headerName: 'Data Collection Start',
      flex: 1,
    },
    {
      field: 'sessionStart',
      headerName: 'Current Sensor Start',
      flex: 1,
    },
    {
      field: 'sessionEnd',
      headerName: 'Current Sensor End',
      flex: 1,
    },
  ]
}

type SubjectTableProps = {
  subjects: ComposedSubject[]
  columns: GridColumns
}

export const SubjectTable: React.FunctionComponent<SubjectTableProps> = ({
  subjects,
  columns,
}) => {
  const theme = useTheme()
  return (
    <DataGrid
      rows={subjects}
      columns={columns}
      hideFooter
      density="compact"
      autoHeight
      style={{ backgroundColor: theme.palette.background.paper }}
    />
  )
}

interface SubjectListProps {
  studyId: string
  siteId: string
  siteLabel: string
}

const SubjectList: React.FunctionComponent<SubjectListProps> = ({
  studyId,
  siteId,
  siteLabel,
}) => {
  const theme = useTheme()
  const { permissions } = useAccess()
  const userActions = useMemo(() => {
    const actions: string[] = []
    if (permissions.includes(StudyActions.MANAGE_SUBJECTS)) {
      actions.push(MENU_OPTION.ACCESS_CODE)
    }
    if (permissions.includes(StudyActions.EXPORT_DATA)) {
      actions.push(MENU_OPTION.EXPORT_DATA)
    }
    if (permissions.includes(StudyActions.SPECIAL_ACCESS)) {
      actions.push(MENU_OPTION.PING)
    }
    return actions
  }, [permissions])

  const hasAccessToSubjects = useMemo(() => {
    return permissions.includes(StudyActions.VIEW_SUBJECTS)
  }, [permissions])

  const subjects = useSubjects(siteLabel)
  const composedSubjects = useMemo(() => {
    return subjects.map((s) => ({
      ...s,
      actions: userActions,
    }))
  }, [userActions, subjects])
  const columnsDefinition = useMemo(() => {
    const columns = permissions.includes(StudyActions.MONITOR_DEVICES)
      ? ExpandedColumns(theme)
      : SimpleColumns

    if (userActions.length > 0) {
      columns.push({
        field: 'id',
        headerName: 'Actions',
        sortable: false,
        filterable: false,
        hideSortIcons: true,
        flex: 1,
        valueGetter: (params: GridValueGetterParams) => {
          const actions = params.getValue(params.id, 'actions') as string[]
          if (!actions) return []
          return actions
        },
        renderCell: (params: GridCellParams) => (
          <ActionMenu
            id={params.id as string}
            menuOptions={params.value as string[]}
            studyId={studyId}
            siteId={siteId}
          />
        ),
      })
    }

    return columns
  }, [userActions, permissions])

  if (!hasAccessToSubjects) {
    return <EmptyInfoContainer infoText="Access Denied" />
  } else if (subjects.length === 0) {
    return <EmptyInfoContainer infoText="No Subjects Enrolled" />
  }

  return (
    <SubjectTable columns={columnsDefinition} subjects={composedSubjects} />
  )
}

export default SubjectList
