import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { QRCode } from 'react-qrcode-logo'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogActions from '@material-ui/core/DialogActions'
import CloseIcon from '@material-ui/icons/Close'
import SendIcon from '@material-ui/icons/Send'
import {
  Button,
  Dialog,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core'
import useDashboardStyles from '@components/GlobalStyles'
import useAccessCode from '@components/AccessCode/useAccessCode'
import useToast from '@providers/data/hooks/useToast'
import useInvitationService from './useInvitationService'

// https://github.com/gcoro/react-qrcode-logo
let refreshTimeout: NodeJS.Timeout

const AccessCodeDialog = () => {
  const history = useHistory()
  const theme = useTheme()
  const classes = useDashboardStyles(theme)
  const { createToast } = useToast()
  const { open, subjectId, siteId, studyId, hideAccessCodeDialog } =
    useAccessCode()
  const {
    invitation,
    status: inviteStatus,
    fetchInvitation,
    refreshShortCode,
  } = useInvitationService(subjectId, siteId, studyId)
  const [showEmailAddress, setShowEmailAddress] = useState(false)
  const [isQRCode, setIsQRCode] = useState(true)

  const handleClose = () => {
    setShowEmailAddress(false)
    setIsQRCode(true)
    hideAccessCodeDialog()
  }

  const handleEmailButtonClicked = () => {
    setShowEmailAddress(!showEmailAddress)
  }

  const handleSendAccessCode = () => {
    createToast('Access Code sent to participant.  (Not Really...)')
    setShowEmailAddress(!showEmailAddress)
    handleClose()
    history.push('/')
  }

  const handlePrintAccessCode = () => {
    createToast('Access Code printing.  (Not Really...)')
  }

  const handleIsQRCode = () => {
    setIsQRCode(!isQRCode)
  }

  useEffect(() => {
    if (open) {
        fetchInvitation()
    }
  }, [open, fetchInvitation])

  useEffect(() => {
    if (invitation && open) {
        clearTimeout(refreshTimeout)
        let timeout = invitation.shortCode.expiration.getTime() - Date.now()
        if (timeout < 0) {
            timeout = 0
        }
        console.log('setting up timeout', timeout / 1000 / 60)
        refreshTimeout = setTimeout(() => {
            refreshShortCode()
        }, timeout)
    } else if (!open) {
        console.log('clearing refresh timeout')
        clearTimeout(refreshTimeout)
    }
  }, [invitation, open, refreshShortCode])

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      style={{ minHeight: '20%' }}
    >
      <MuiDialogTitle>
        <Typography style={{ color: theme.palette.text.secondary }}>
          Access Code
        </Typography>
        <IconButton
          className={classes.closeButton}
          aria-label="close"
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </MuiDialogTitle>
      <Divider />

      <Grid container alignContent="center" justifyContent="center">
        <Grid
          item
          xs={12}
          style={{ marginTop: 10, textAlign: 'center' }}
          title="Study Access Code"
        >
          <FormControl component="fieldset">
            <RadioGroup
              row
              aria-label="position"
              id="qrCodeRadios"
              name="qrCodeRadios"
              defaultValue="true"
              onChange={handleIsQRCode}
            >
              <FormControlLabel
                value="true"
                control={<Radio color="primary" />}
                label="QR Code"
                labelPlacement="end"
              />
              <FormControlLabel
                value="false"
                control={<Radio color="secondary" />}
                label="Invite Code"
                labelPlacement="end"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={12}
          style={{ marginTop: 10, textAlign: 'center' }}
          title="Subject QR Code"
        >
          {invitation && inviteStatus === 'ready' && (
            isQRCode ? (
                <QRCode
                value={invitation.qrCode}
                qrStyle={'dots'}
                size={200}
                fgColor={theme.palette.text.primary}
                eyeRadius={[
                    {
                    // top/left eye
                    outer: [10, 10, 0, 10],
                    inner: [0, 10, 10, 10],
                    },
                    [10, 10, 10, 0], // top/right eye
                    [10, 0, 10, 10], // bottom/left
                ]}
                />
            ) : (
                <React.Fragment>
                    <Typography
                    variant="h2"
                    style={{ marginTop: '0.5em', marginBottom: '0.5em' }}
                    >
                    {invitation.shortCode.value}
                    </Typography>
                    <Typography variant="body2">
                    Expires at: {invitation.shortCode.expiration.toLocaleTimeString()}
                    </Typography>
                </React.Fragment>
            )
          )}
        </Grid>
        {showEmailAddress && (
          <Grid item xs={9} style={{ marginTop: 10 }}>
            <TextField
              label="Subject Email Address"
              helperText="We do not save this information.  We only use it to send this access code. NonFunctional."
              id="emailaddress-input"
              className={classes.userTextField}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    style={{
                      color: theme.palette.primary.main,
                      cursor: 'pointer',
                    }}
                    title="Click To Send Access Code"
                  >
                    <SendIcon onClick={handleSendAccessCode}></SendIcon>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        )}
      </Grid>

      <MuiDialogActions>
        <Button onClick={handleClose} color="default" variant="outlined">
          Cancel
        </Button>
        <Button
          onClick={handlePrintAccessCode}
          color="secondary"
          variant="contained"
        >
          Print
        </Button>
        {!showEmailAddress && (
          <Button
            onClick={handleEmailButtonClicked}
            color="primary"
            variant="contained"
            autoFocus
            title="Send Access Code to Participant Via Email"
          >
            Email
          </Button>
        )}
      </MuiDialogActions>
    </Dialog>
  )
}

export default AccessCodeDialog
