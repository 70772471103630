import React, { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'
import { StudyActions } from '@models/access.models'
import {
  ClientConfigErrors,
  ClientConfig,
  emptyClientConfig,
  emptyClientConfigErrors,
} from '@models/clientConfig.models'
import {
  hasChanged,
  validateClientConfig,
  validateSiteIsCustomized,
} from '@lib/clientConfig/validate'
import AppAdvancedSettings from '@components/Site/ClientConfigAdvanced'
import ClientConfigCardView from '@components/Site/ClientConfigCardView'
import { EditAndSaveButtons } from '@components/EditAndSaveButtons'
import { EmptyInfoContainer } from '@components/EmptyInfoContainer'
import SiteContactInstructions from '@components/Site/SiteContactInstructions'
import useAccess from '@providers/access/useAccess'
import useParticipantActions from '@providers/data/hooks/useDataRequests'
import useSiteConfig from '@lib/hooks/useClientConfig'
import useToast from '@providers/data/hooks/useToast'
import { SiteConfig } from '@models/api.models'
import { StatusEnum } from '@components/Study/models'
import useSite from '@lib/hooks/useSite'

type SiteConfigProps = {
  studyId: string
  siteId: string
  siteStatus: string
  setSiteStatus: (status: string) => void
}

export type ClientConfigValidationState = {
  values: ClientConfig
  errors: ClientConfigErrors
}

export default function SiteConfig(props: SiteConfigProps) {
  const { createToast } = useToast()
  const { clientConfig, setConfig } = useSiteConfig(props.studyId, props.siteId)
  const { permissions } = useAccess()
  const { updateSiteConfig } = useParticipantActions()

  const [isSaving, setIsSaving] = React.useState(false)
  const [canEdit, setCanEdit] = React.useState(false)
  const [canSave, setCanSave] = React.useState(false)

  const [state, setState] = useState<ClientConfigValidationState>({
    values: emptyClientConfig,
    errors: emptyClientConfigErrors,
  })

  // Load the client config
  useEffect(() => {
    if (clientConfig) {
      setState((prev) => ({ ...prev, values: clientConfig }))
    }
  }, [clientConfig])

  // Watch for changes to the client config
  useEffect(() => {
    if (hasChanged(clientConfig, state.values)) {
      setCanSave(true)
    } else {
      setCanSave(false)
    }
  }, [clientConfig, state])

  const handleIntChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => {
      return {
        ...prevState,
        values: {
          ...prevState.values,
          [event.target.id]: parseInt(event.target.value),
        },
      }
    })
  }

  const handleStrChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => {
      return {
        ...prevState,
        values: {
          ...prevState.values,
          [event.target.id]: event.target.value,
        },
      }
    })
  }

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => {
      return {
        ...prevState,
        values: {
          ...prevState.values,
          [event.target.id]: event.target.checked,
        },
      }
    })
  }

  const handleEditSettingsClicked = (isEditable: boolean) => {
    setCanEdit(isEditable)
  }

  const handleCancelClicked = () => {
    setCanEdit(false)
    setCanSave(false)
    setState((prev) => ({ ...prev, values: clientConfig || emptyClientConfig }))
  }

  const updateClientConfig = () => {
    createToast('Updating Subject Devices.', 'info')
    setIsSaving(true)
    setCanSave(false)
    setCanEdit(false)

    const updatedSiteConfig = {
      ...state.values,
      ...props,
    }

    updateSiteConfig(updatedSiteConfig)
      .then(() => {
        setConfig(updatedSiteConfig)
        setState((prev) => ({ ...prev, errors: emptyClientConfigErrors }))

        createToast('Subject Devices Updated.')
        if (props.siteStatus === StatusEnum.CREATED) {
          props.setSiteStatus(StatusEnum.READY)
          createToast('Site Is Ready For Use.')
        }
      })
      .catch((e) => {
        console.log(e)
        createToast('Error - Could Not Save Config', 'error')
      })
      .finally(() => {
        setIsSaving(false)
      })
  }

  const handleSaveSiteConfig = () => {
    const validationResult =
      props.siteStatus !== StatusEnum.CREATED
        ? validateClientConfig(state.values)
        : validateSiteIsCustomized(state.values)

    if (validationResult.valid) {
      updateClientConfig()
    } else {
      setState((prev) => ({ ...prev, errors: validationResult.errors }))
    }
  }

  if (!state || !state.values || state.values === null) {
    return <EmptyInfoContainer infoText="No Config Found" />
  }

  return (
    <React.Fragment>
      {permissions.includes(StudyActions.MANAGE_SETTINGS) && (
        <Grid container spacing={2} justifyContent={'flex-end'}>
          <Grid item xs={2} lg={2}>
            <EditAndSaveButtons
              canEdit={canEdit}
              canSave={canSave}
              disabledButtons={isSaving}
              onSave={handleSaveSiteConfig}
              onEdit={handleEditSettingsClicked}
              onCancel={handleCancelClicked}
              editButtonText="Edit"
            />
          </Grid>
        </Grid>
      )}

      <Grid container spacing={2}>
        <Grid item xs={12} sm={5} lg={4}>
          <ClientConfigCardView
            values={state.values}
            errors={state.errors}
            canEdit={canEdit}
            onChange={handleIntChange}
            onSwitchChange={handleSwitchChange}
          />
        </Grid>
        {permissions.includes(StudyActions.SPECIAL_ACCESS) && (
          <Grid item xs={12} sm={5} lg={4}>
            <AppAdvancedSettings
              values={state.values}
              errors={state.errors}
              canEdit={canEdit}
              onChange={handleIntChange}
              onSwitchChange={handleSwitchChange}
            />
          </Grid>
        )}
        <Grid item xs={12} sm>
          <SiteContactInstructions
            values={state.values}
            errors={state.errors}
            canEdit={canEdit}
            onChange={handleStrChange}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
