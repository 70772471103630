import { Socket, io } from 'socket.io-client'

export default (
    host: string,
    token: string,
    register: (socket: Socket) => void,
    namespace?: string
): Socket => {
    const url = new URL(host)
    const _socket = io(`${url.origin}${namespace}`, {
        path: url.pathname !== '/' ? `${url.pathname}/socket.io` : '',
        auth: {
            token: token
        }
    })

    register(_socket)
    _socket.on('disconnect', () => {
        _socket.removeAllListeners()
    })
    return _socket
}
