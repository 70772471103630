import { IAcknowledgement, IAlarm } from '@models/index'
import { useCallback } from 'react'
import {
  alarmOn,
  alarmOff,
  alarmUpdateAction,
  allAcknowledgementsAction,
  newAcknowledgementAction,
} from '../actions'
import { useGlobalState } from './useGlobalState'

export default () => {
  const { dispatch } = useGlobalState()

  const setAlarmOn = (id: string) => {
    dispatch(alarmOn(id))
  }

  const setAlarmOff = (id: string) => {
    dispatch(alarmOff(id))
  }

  const setAlarms = useCallback((alarms: Record<string, IAlarm[]>) => {
    dispatch(alarmUpdateAction(alarms))
  }, [dispatch])

  const setAllAcknowledgements = useCallback((acks: IAcknowledgement[]) => {
    dispatch(allAcknowledgementsAction(acks))
  }, [dispatch])

  const setAcknowledgement = useCallback((ack: IAcknowledgement) => {
    dispatch(newAcknowledgementAction(ack))
  }, [dispatch])

  return {
    setAlarmOn,
    setAlarmOff,
    setAlarms,
    setAllAcknowledgements,
    setAcknowledgement
  }
}
