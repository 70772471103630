import { useContext, useEffect } from 'react'
import useToast from './useToast'
import useDataRequests from './useDataRequests'
import { IRoleDetail } from '@models/index'
import { DataContext } from '../data.provider'
import { instanceRolesAction } from '../actions'
import useInstanceConfig from './useInstanceConfig'

interface RolesState {
  roles: IRoleDetail[]
}

const useInstanceRoles = (): RolesState => {
  const ctx = useContext(DataContext)

  if (!ctx) {
    throw new Error(
      'useInstanceRoles hook must be used inside DataProvider context'
    )
  }

  const { state, dispatch } = ctx
  const { createToast } = useToast()
  const { getInstanceRoles } = useDataRequests()
  const { instanceConfig } = useInstanceConfig()

  useEffect(() => {
    // Do better defense.  If no siteConfig, don't do anything.
    if (instanceConfig) {
      const instanceType = instanceConfig?.is_voyager ? 'voyager' : 'hospital'
      getInstanceRoles(instanceType)
        .then((roles) => {
          console.log('roles', roles)
          dispatch(instanceRolesAction(roles))
        })
        .catch((e) => {
          console.log(e)
          createToast('Failed To Load Instance Roles', 'error')
        })
    }

    // eslint-disable-next-line
  }, [])

  return {
    roles: state.instanceRoles,
  }
}

export default useInstanceRoles
