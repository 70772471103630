import React, {
  createContext,
  FunctionComponent,
  useEffect,
  useMemo,
  useState,
} from 'react'
import beep from '@assets/beep.mp3'
import usePrevious from '@lib/hooks/usePrevious'

const BeepProvider: FunctionComponent = ({ children }) => {
  const audio = useMemo(() => new Audio(beep), [])
  const [playing, setPlaying] = useState(false)
  const wasPlaying = usePrevious(playing)

  useEffect(() => {
    if (playing && !wasPlaying) {
      audio.play()
      setTimeout(() => {
        setPlaying(false)
      }, 3000)
    } else if (!playing && wasPlaying) {
      audio.pause()
      audio.currentTime = 0
    }
  }, [audio, playing, wasPlaying])

  const play = () => {
    setPlaying(true)
  }

  return (
    <BeepContext.Provider value={{ beep: play }}>
      {children}
    </BeepContext.Provider>
  )
}

const BeepContext = createContext<{
  beep: () => void
}>({
  beep: () => {
    return
  },
})

export { BeepContext, BeepProvider }
