import React, { useMemo, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { EGVRecord } from '@models/index'
import { TIME_RANGE, TimeRangePicker, Chart } from './Chart'

interface GraphProps {
  egvs: EGVRecord[]
  timePicker?: boolean
  highThresh: number
  lowThresh: number
}

const Graph: React.FunctionComponent<GraphProps> = ({
  egvs,
  timePicker,
  highThresh,
  lowThresh,
}) => {
  const [graphRange, setGraphRange] = useState(TIME_RANGE.RANGE_3_HOURS)
  const data = useMemo(() => {
    const now = Date.now()
    return egvs
      .filter((egv) => new Date(egv.systemtime).getTime() >= now - graphRange)
      .map((egv) => ({
        ...egv,
        systemtime: new Date(egv.systemtime).getTime(),
      }))
  }, [egvs, graphRange])

  return (
    <Grid container>
      {timePicker && (
        <Grid item xs={12}>
          <TimeRangePicker value={graphRange} setValue={setGraphRange} />
        </Grid>
      )}
      <Grid item xs={12}>
        <Chart
          data={data}
          timeRange={graphRange}
          highThresh={highThresh}
          lowThresh={lowThresh}
        />
      </Grid>
    </Grid>
  )
}

export default Graph
