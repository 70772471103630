import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { matchPath, useLocation } from 'react-router-dom'
import useBeep from '@providers/beep/useBeep'
import useAlarm from '@providers/data/hooks/useAlarm'
import { useGlobalState } from '@providers/data/hooks/useGlobalState'
import { IParticipantData } from '@models/index'
import usePrevious from '@lib/hooks/usePrevious'

interface IAlarmEngineProps {
  alarmedParticipants: IParticipantData[]
  selectedParticipant?: string
  triggerAlarm: (id: string) => void
  endAlarm: (id: string) => void
}

export function AlarmEngine(props: IAlarmEngineProps) {
  const { alarmedParticipants, selectedParticipant, triggerAlarm, endAlarm } =
    props

  const visibleParticipants = useMemo(() => {
    if (selectedParticipant !== undefined) {
      return alarmedParticipants
        .filter((p) => p.id === selectedParticipant)
        .map((p) => ({ id: p.id, lastAlarmTrigger: p.lastAlarmTrigger }))
    }

    return alarmedParticipants.map((p) => ({
      id: p.id,
      lastAlarmTrigger: p.lastAlarmTrigger,
    }))
  }, [alarmedParticipants, selectedParticipant])

  return (
    <div>
      {visibleParticipants.map((p) => (
        <ParticipantAlarm
          key={p.id}
          id={p.id}
          lastAlarm={p.lastAlarmTrigger}
          onTrigger={triggerAlarm}
          onEnd={endAlarm}
        />
      ))}
    </div>
  )
}

interface ParticipantAlarmProps {
  id: string
  lastAlarm: number
  onTrigger: (id: string) => void
  onEnd: (id: string) => void
}

function ParticipantAlarm({
  id,
  lastAlarm,
  onTrigger,
  onEnd,
}: ParticipantAlarmProps) {
  const [alarming, setAlarming] = useState(false)
  const prevAlarming = usePrevious(alarming)
  useEffect(() => {
    if (!prevAlarming && alarming) {
      onTrigger(id)
    } else if (prevAlarming && !alarming) {
      onEnd(id)
    }
  }, [prevAlarming, alarming, onTrigger, onEnd, id])

  useEffect(() => {
    let mounted = true
    const THREE_MINUTES = 3 * 60 * 1000
    let timer: NodeJS.Timeout

    const setupTimer = (timeout: number) => {
      return setTimeout(() => {
        setAlarming(true)
        setTimeout(() => {
          setAlarming(false)
          if (mounted) {
            timer = setupTimer(3 * 60 * 1000)
          }
        }, 3000)
      }, timeout)
    }

    const timeSinceLast = Date.now() - lastAlarm
    const firstTimeout =
      timeSinceLast >= THREE_MINUTES ? 0 : THREE_MINUTES - timeSinceLast

    timer = setupTimer(firstTimeout)

    return () => {
      clearTimeout(timer)
      setAlarming(false)
      mounted = false
    }
    // eslint-disable-next-line
  }, [])
  return <></>
}

export default function ConnectedAlarmEngine() {
  const { state } = useGlobalState()
  const { setAlarmOn, setAlarmOff } = useAlarm()
  const location = useLocation()
  const onUserPage = useMemo(() => {
    const match = matchPath(location.pathname, {
      path: '/participant/:id',
      exact: true,
    })

    if (match) {
      return location.pathname.split('/').pop()
    } else {
      return undefined
    }
  }, [location.pathname])
  const beep = useBeep()
  const participants = state.participantData
  const siteConfig = state.config.instanceConfig

  const participantsWithAlarm = useMemo(() => {
    return Object.values(participants).filter((p) => p.alarm)
  }, [participants])

  const triggerAlarm = useCallback(
    (id: string) => {
      if (!siteConfig) {
        return
      }
      setAlarmOn(id)
      const alarm = participants[id].alarms[0]
      if (
        (alarm.type === 'high' && siteConfig.highaudiblealarmsenabled) ||
        (alarm.type === 'low' && siteConfig.lowaudiblealarmsenabled) ||
        (alarm.type === 'check sensor' &&
          siteConfig.signallossaudiblealarmsenabled) ||
        alarm.type === 'urgent low'
      ) {
        beep()
      }
    },
    [siteConfig, participants, setAlarmOn, beep]
  )

  const endAlarm = (id: string) => {
    setAlarmOff(id)
  }

  return (
    <AlarmEngine
      selectedParticipant={onUserPage}
      alarmedParticipants={participantsWithAlarm}
      triggerAlarm={triggerAlarm}
      endAlarm={endAlarm}
    />
  )
}
