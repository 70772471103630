import Spinner from '@components/Spinner/Spinner'
import { Button, Grid, Typography, useTheme } from '@material-ui/core'
import { SiteViewEnum } from '@models/index'
import { useKeycloak } from '@providers/auth'
import useStudies from '@providers/data/hooks/useStudies'
import useSites from '@providers/data/hooks/useSites'
import useUserConfig from '@providers/data/hooks/useUserConfig'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import CreateStudyModalView from './CreateStudyModalView'
import { StatusEnum } from './models'
import { StudiesListView } from './StudiesListView'

export default function StudyHome() {
  const history = useHistory()
  const theme = useTheme()
  const { keycloak } = useKeycloak()
  const [open, setOpen] = React.useState(false)
  const { studies, fetching, fetchStudies } = useStudies()
  const { sites, fetching: fetchingSites, fetchStudySites } = useSites()
  const { setUserConfigSiteView: setUserConfigStudyView } = useUserConfig()

  useEffect(() => {
    fetchStudies()
  }, [])

  const openModal = () => {
    setOpen(true)
  }

  const closeModal = () => {
    setOpen(false)
  }

  const goToStudy = (id: string) => {
    const study = studies.find((study) => study.id === id)
    if (!study) {
      return
    }
    if (
      study.status.toLowerCase() === StatusEnum.CREATED &&
      study.siteCount === 1
    ) {
      setUserConfigStudyView(SiteViewEnum.SETTINGS_VIEW)
      fetchStudySites(id).then((sites) => {
        if (sites) {
          history.push(`/study/${id}/site/${sites[0].id}`)
        }
      })
    } else {
      setUserConfigStudyView(SiteViewEnum.SUBJECTS_VIEW)
      history.push(`/study/${id}`)
    }
  }

  if (fetching) {
    return <Spinner />
  }

  return (
    <React.Fragment>
      <Grid container style={{ marginTop: '10pt' }}>
        <Grid item xs={9} sm={10} lg={11}>
          <Typography
            variant="h4"
            style={{
              color: theme.oppositePalette.colorInverted,
            }}
          >
            Studies
          </Typography>
        </Grid>
        {keycloak.hasRealmRole('study-lead') && (
          <Grid item xs={3} sm={2} lg={1}>
            <Button
              color="primary"
              variant="contained"
              style={{
                textTransform: 'initial',
                width: '100%',
              }}
              onClick={openModal}
            >
              New Study
            </Button>
            <CreateStudyModalView open={open} handleClose={closeModal} />
          </Grid>
        )}

        <Grid
          container
          justifyContent={'center'}
          style={{
            marginBottom: '1em',
            marginTop: '1em',
          }}
        >
          <Grid
            item
            xs={12}
            style={{
              width: '100%',
              backgroundColor: theme.palette.background.paper,
            }}
          >
            <Grid item xs={12} style={{ width: '100%' }}>
              <StudiesListView studies={studies} onStudyClick={goToStudy} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
