import { useContext } from "react"
import { initialState, SubjectContext } from "./subject.provider"

export default () => {
    const ctx = useContext(SubjectContext)
    if (!ctx) {
        console.log('SubjectProvider not mounted')
        return Object.values(initialState.subjects)
    }

    const { state } = ctx

    return Object.values(state.subjects)
}