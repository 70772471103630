import { useEffect, useState } from 'react'
import { Socket } from 'socket.io-client'
import { useKeycloak } from '@providers/auth'
import { REACT_APP_REST_SERVICE_URL } from '@config/env'
import newSocket from './newSocket'

const renewSocket = (socket: Socket, token: string) => {
  socket.off('connect_error')
  socket.on('connect_error', (err) => {
    if (err.message === 'invalid credentials') {
      socket.auth = { token }
      socket.connect()
    }
  })
}

export default (namespace: string, register: (socket: Socket) => void) => {
  const { token } = useKeycloak()
  const [ socket, setSocket ] = useState<Socket | null>(null)

  useEffect(() => {
    return () => {
      if (socket) {
        console.log(`Disconnecting from ${namespace}`)
        socket.disconnect()
      }
    }
  }, [socket, namespace])

  useEffect(() => {
    setSocket(prev => {
      if (prev) renewSocket(prev, token)
      else {
        console.log(`Connecting to ${namespace}`)
        prev = newSocket(REACT_APP_REST_SERVICE_URL, token, register, namespace)
      }
      return prev
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])
}
