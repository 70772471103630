import React, { FunctionComponent, useMemo } from 'react'
import StarIcon from '@material-ui/icons/Star'
import { IndicatorStatus } from './models'
import { Theme, Tooltip } from '@material-ui/core'
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined'
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined'

type Props = {
  status: IndicatorStatus
  theme: Theme
}

const StatusIndicator: FunctionComponent<Props> = ({ status, theme }) => {
  const color = useMemo(() => {
    switch (status) {
      case IndicatorStatus.OK:
        return theme.statusCreated.color //green
      case IndicatorStatus.WARNING:
        return theme.statusCreated.color //orange
      case IndicatorStatus.ERROR:
        return theme.statusCheckData.color //red
      default:
        return theme.statusCompleted.color //grey
    }
  }, [status])

  const titleText = useMemo(() => {
    switch (status) {
      case IndicatorStatus.ERROR:
        return 'Subject is at risk of losing data.'
      default:
        return ''
    }
  }, [status])

  const composedIcon = useMemo(() => {
    switch (status) {
      case IndicatorStatus.WARNING:
        return (
          <Tooltip title={titleText} placement={'top-start'}>
            <ReportProblemOutlinedIcon
              style={{
                color: color,
              }}
            />
          </Tooltip>
        )
      case IndicatorStatus.ERROR:
        return (
          <Tooltip title={titleText} placement={'top-start'}>
            <HighlightOffOutlinedIcon
              style={{
                color: color,
              }}
            />
          </Tooltip>
        )
      default:
        return <span />
    }
  }, [status])

  return composedIcon
}

export default StatusIndicator
