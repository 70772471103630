import React from 'react'
import { createStyles, makeStyles } from '@material-ui/styles'
import { Theme, useTheme } from '@material-ui/core'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogActions from '@material-ui/core/DialogActions'
import CloseIcon from '@material-ui/icons/Close'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'

interface IResetPasswordModalProps {
  open: boolean
  password: string
  onResetPassword: () => void
  onClose: () => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[800],
      fontSize: 'small',
    },
    inputLabel: {
      color: theme.palette.grey[800],
      marginLeft: '1pt',
    },
    userTextField: {
      width: '95%',
    },
  })
)

export default function ResetPasswordModalView({
  open,
  password,
  onResetPassword,
  onClose,
}: IResetPasswordModalProps) {
  const theme = useTheme()
  const classes = useStyles(theme)

  const handleSubmit = (event: React.MouseEvent) => {
    event.preventDefault()
    onResetPassword()
  }

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
        fullWidth
      >
        <MuiDialogTitle>
          <Typography style={{ color: theme.palette.text.secondary }}>
            Reset Password
          </Typography>
          <IconButton
            className={classes.closeButton}
            aria-label="close"
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </MuiDialogTitle>
        <Divider />
        <Grid container justify="space-around" alignItems={'center'}>
          <Grid item xs={9} style={{ marginTop: 10 }}>
            <TextField
              label="Password"
              variant="outlined"
              required
              value={password}
              title="User will reset their password on first login"
              className={classes.userTextField}
            />
          </Grid>
        </Grid>

        <MuiDialogActions>
          <Button onClick={onClose} color="default" variant="outlined">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            Reset Password
          </Button>
        </MuiDialogActions>
      </Dialog>
    </React.Fragment>
  )
}
