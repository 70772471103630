import React from 'react'
import { NavLink } from 'react-router-dom'
import { KeycloakInstance } from 'keycloak-js'
import ContextMenu from '@components/ContextMenu/ContextMenu'
import Logo from '@components/Logo/Logo'
import AddParticipantForm from '@components/ParticipantAdd/AddParticipantForm'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Toolbar from '@material-ui/core/Toolbar'
import PhoneIcon from '@material-ui/icons/Phone'
import ComputerIcon from '@material-ui/icons/Computer'
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import { Theme, useTheme } from '@material-ui/core/styles'
import useAddParticipant from '@components/ParticipantAdd/useAddParticipant'
import useInstanceConfig from '@providers/data/hooks/useInstanceConfig'
import { INewParticipant } from '@models/form.models'
import {
  REACT_APP_DEXCOM_API_CLIENT_ID,
  REACT_APP_DEXCOM_API_URL,
} from '@config/env'
import { useKeycloak } from '@providers/auth'

type MenuProps = {
  keycloak: KeycloakInstance
  theme: Theme
}

const redirectToURL = (participantId: string) => {
  const redirectUri = `${window.location.protocol}//${window.location.host}/participant/add/callback`

  const url = [
    REACT_APP_DEXCOM_API_URL,
    '/v2/oauth2/login',
    '?',
    `client_id=${REACT_APP_DEXCOM_API_CLIENT_ID}`,
    '&',
    `redirect_uri=${redirectUri}`,
    '&',
    'response_type=code',
    '&',
    'scope=offline_access',
    '&',
    `state=${participantId}`,
  ].join('')
  // off we go!
  window.location.replace(url)
}

const HospitalMenu = ({ keycloak, theme }: MenuProps) => (
  <Toolbar
    disableGutters
    style={{
      color: theme.oppositePalette.colorInverted,
    }}
  >
    {keycloak.hasRealmRole('dashboard-monitor-devices') && (
      <Button
        key="dash123"
        to="/"
        exact
        component={NavLink}
        activeStyle={{ color: theme.palette.primary.main }}
        color="inherit"
      >
        <ComputerIcon></ComputerIcon>
        Dashboard
      </Button>
    )}

    {keycloak.hasRealmRole('dashboard-settings-management') && (
      <Button
        key="settings123"
        to="/settings"
        component={NavLink}
        activeStyle={{ color: theme.palette.primary.main }}
        color="inherit"
      >
        <SettingsApplicationsIcon></SettingsApplicationsIcon>
        Settings
      </Button>
    )}
    {keycloak.hasRealmRole('dashboard-export-data') && (
      <Button
        key="data123"
        to="/exportdata"
        component={NavLink}
        activeStyle={{ color: theme.palette.primary.main }}
        color="inherit"
      >
        <ExitToAppIcon></ExitToAppIcon>
        Export
      </Button>
    )}
  </Toolbar>
)

export default function HospitalHeader(): JSX.Element {
  const theme = useTheme()
  const { keycloak } = useKeycloak()
  const { instanceConfig } = useInstanceConfig()
  const {
    createParticipant,
    addParticipantFormOpen,
    setAddParticipantFormOpen,
  } = useAddParticipant()

  const addParticipant = () => {
    setAddParticipantFormOpen(true)
  }

  const cancelAddParticipant = () => {
    setAddParticipantFormOpen(false)
  }

  const submitParticipant = (data: INewParticipant) => {
    createParticipant(data)
      .then((participantId) => {
        setAddParticipantFormOpen(false)
        redirectToURL(participantId)
      })
      .catch(() => undefined)
  }

  if (!instanceConfig) {
    return <></>
  }

  return (
    <React.Fragment>
      <Container maxWidth="xl">
        <Grid container justifyContent="flex-end" alignItems="center">
          <PhoneIcon style={{ fontSize: 14 }} />
          <Typography color="textSecondary" style={{ fontSize: 14 }}>
            Call Technical Support at 1-
            {instanceConfig.supportphone.substr(0, 3)}-
            {instanceConfig.supportphone.substr(3, 3)}-
            {instanceConfig.supportphone.substr(6, 4)}
          </Typography>
        </Grid>
      </Container>
      <Paper
        elevation={3}
        style={{
          marginBottom: 10,
          width: '100%',
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <Container maxWidth="xl">
          <Grid container alignItems="center" justifyContent="space-between">
            <Logo />
            <ContextMenu />
          </Grid>
          <Grid container alignItems="center" justifyContent="space-between">
            <HospitalMenu keycloak={keycloak} theme={theme} />
            <Button
              onClick={addParticipant}
              color="primary"
              variant="contained"
              style={{ textTransform: 'initial' }}
            >
              Add Participant
            </Button>
          </Grid>
        </Container>
      </Paper>
      <Dialog
        open={addParticipantFormOpen}
        onClose={cancelAddParticipant}
        aria-labelledby="form-dialog-title"
        fullWidth
      >
        <AddParticipantForm
          metadata={instanceConfig.metas || []}
          onCancel={cancelAddParticipant}
          onSubmit={submitParticipant}
          isVoyager={instanceConfig.is_voyager}
        />
      </Dialog>
    </React.Fragment>
  )
}
