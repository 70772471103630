import React from 'react'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import { StudyErrors } from './models'
import { NewStudy } from '@models/api.models'
import { Grid, Typography, useTheme } from '@material-ui/core'

type CreateBasicInfoFormProps = {
  values: NewStudy
  errors: StudyErrors
  onFieldChange: (key: string, value: string) => void
  confirm?: boolean
  confirmMessage?: string
}

const CreateBasicInfoForm = (props: CreateBasicInfoFormProps) => {
  const theme = useTheme()
  const handleFieldChange =
    (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value
      props.onFieldChange(field, value)
    }

  const { values, errors } = props

  if (props.confirm) {
    return (
      <Grid
        container
        alignContent={'center'}
        alignItems={'center'}
        style={{ minHeight: '35vh', textAlign: 'center' }}
      >
        <Grid item xs={12}>
          <Typography variant={'subtitle1'} color="secondary">
            Would you like to continue?
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ marginTop: '10pt' }}>
          <Typography variant={'body2'} color="textSecondary">
            { props.confirmMessage }
          </Typography>
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid container justifyContent="center" alignItems={'center'}>
      <Grid
        item
        xs={10}
        md={7}
        style={{
          marginTop: 10,
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <TextField
          id="type"
          label="Type"
          variant="outlined"
          select
          value={values.type}
          error={errors.type !== ''}
          helperText={errors.type}
          onChange={handleFieldChange('type')}
          style={{ width: '100%' }}
        >
          <MenuItem value="Dexcom-Sponsored">{'Dexcom-Sponsored'}</MenuItem>
          <MenuItem value="Dexcom External Research (IIS)">
            {'Dexcom External Research (IIS)'}
          </MenuItem>
        </TextField>
      </Grid>

      {errors.basicInfo && (
        <Grid
          item
          xs={10}
          md={7}
          style={{
            marginTop: 10,
            backgroundColor: theme.palette.background.paper,
          }}
        >
          <Typography variant="caption" color="error">
            {errors.basicInfo}
          </Typography>
        </Grid>
      )}
      <Grid
        item
        xs={10}
        md={7}
        style={{
          marginTop: 10,
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <TextField
          id="protocolId"
          label="Protocol ID"
          variant="outlined"
          value={values.protocolId}
          error={errors.basicInfo !== '' || errors.protocolId !== ''}
          helperText={errors.protocolId}
          title="Enter Protocol ID if one is assigned to the study."
          style={{
            width: '100%',
            backgroundColor: theme.palette.background.paper,
          }}
          onChange={handleFieldChange('protocolId')}
        />
      </Grid>
      <Grid
        item
        xs={10}
        md={7}
        style={{
          marginTop: 10,
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <TextField
          id="name"
          label="Short Name"
          variant="outlined"
          value={values.name}
          error={errors.basicInfo !== '' || errors.name !== ''}
          helperText={errors.name}
          title="Create a short nickname or protocol acronym to be shown in place of the full study title."
          style={{ width: '100%' }}
          onChange={handleFieldChange('name')}
        />
      </Grid>
      <Grid
        item
        xs={10}
        md={7}
        style={{
          marginTop: 10,
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <TextField
          id="protocolTitle"
          label="Protocol Title"
          variant="outlined"
          multiline
          rows={5}
          value={values.protocolTitle}
          error={errors.basicInfo !== '' || errors.protocolTitle !== ''}
          helperText={errors.protocolTitle}
          title="Enter the full study name or protocol title.  If could be used later to search for this study."
          style={{ width: '100%' }}
          onChange={handleFieldChange('protocolTitle')}
        />
      </Grid>
    </Grid>
  )
}

export default CreateBasicInfoForm
