import React, { useMemo } from 'react'
import { DataGrid, GridCellParams, GridColumns } from '@material-ui/data-grid'
import { IDashboardUserDetails, IRoleDetail } from '@models/index'
import Button from '@material-ui/core/Button'
import { Typography } from '@material-ui/core'

interface UsersListViewProps {
  users: Array<IDashboardUserDetails>
  onUserClick: (id: string) => void
}

export const UsersListView: React.FunctionComponent<UsersListViewProps> = ({
  users,
  onUserClick,
}) => {
  const columnsDefinition = useMemo(() => {
    const columns: GridColumns = [
      {
        field: 'username',
        headerName: 'Username',
        width: 200,
      },
    ]
    columns.push({
      field: 'roles',
      headerName: 'Roles',
      width: 200,
      renderCell: (params: GridCellParams) => {
        return (
          <div>
            {params.row.roles.map((role: IRoleDetail) => (
              <Typography variant="body2" key={role.id}>
                {role.formattedName}
              </Typography>
            ))}
          </div>
        )
      },
    })
    columns.push({
      field: 'id',
      headerName: ' ',
      width: 175,
      renderCell: (params: GridCellParams) => {
        if (params.row.isCurrentUser) {
          return <div></div>
        }
        return (
          <Button
            color="primary"
            variant="contained"
            style={{
              textTransform: 'initial',
              width: '100%',
            }}
            onClick={() => {
              onUserClick(params.id as string)
            }}
          >
            Reset Password
          </Button>
        )
      },
    })
    return columns
  }, [onUserClick])

  if (users.length === 0) {
    return <div>No Users</div>
  }

  return (
    <DataGrid
      rows={users}
      columns={columnsDefinition}
      hideFooter
      density="compact"
      autoHeight
    />
  )
}
