import React from 'react'
import {
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined'
import { Role } from '@models/access.models'
import { SiteStaff, StaffErrors } from '@models/staff.models'
import { Permissions } from '@components/Staff/PermissionsControls'

type EditableStaffRowProps = {
  staffIdx: number
  staffCount: number
  staff: SiteStaff
  staffErrors: StaffErrors
  permissionOptions: Record<string, Role>
  onAddStaff: () => void
  onRemoveStaff: (staffIdx: number) => void
  onStaffFieldChange: (staffIndex: number, key: string, value: string) => void
  onStaffRoleChange: (
    staffIndex: number,
    role: string,
    enabled: boolean
  ) => void
}

const EditableStaffRow: React.FunctionComponent<EditableStaffRowProps> = ({
  staffIdx,
  staffCount,
  staff,
  staffErrors,
  permissionOptions,
  onAddStaff,
  onRemoveStaff,
  onStaffFieldChange,
  onStaffRoleChange,
}) => {
  const handleAddStaff = () => () => {
    onAddStaff()
  }

  const handleRemoveStaff = (staffIndex: number) => () => {
    onRemoveStaff(staffIndex)
  }

  const handleStaffFieldChange =
    (staffIndex: number, field: string) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value
      onStaffFieldChange(staffIndex, field, value)
    }
  const handleStaffRoleChange =
    (staffIndex: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, checked } = event.target
      onStaffRoleChange(staffIndex, name, checked)
    }

  return (
    <React.Fragment key={staffIdx}>
      <Grid container style={{ marginBottom: '10pt' }}>
        <Grid
          container
          item
          xs={6}
          md={8}
          alignItems="center"
          style={{ width: '100%' }}
        >
          <Grid item xs={9}>
            <TextField
              id="email"
              label="Email"
              variant="outlined"
              value={staff.email}
              error={staffErrors && staffErrors.email !== ''}
              helperText={staffErrors ? staffErrors.email : ''}
              title="Please enter the email address of the staff member."
              style={{ width: '100%' }}
              onChange={handleStaffFieldChange(staffIdx, 'email')}
            />
          </Grid>
          <Grid item xs={1}>
            <IconButton
              onClick={handleRemoveStaff(staffIdx)}
              size="small"
              title="Remove staff member"
            >
              <DeleteIcon />
            </IconButton>
          </Grid>
          {staffIdx === staffCount - 1 && (
            <Grid item xs={2}>
              <Button
                variant="contained"
                onClick={handleAddStaff()}
                color="primary"
                size="small"
                title="Add a new staff member to this site."
              >
                <PersonAddOutlinedIcon />
              </Button>
            </Grid>
          )}
        </Grid>
        <Grid
          container
          item
          xs={6}
          md={4}
          alignItems={'center'}
          justifyContent={'center'}
          style={{ textAlign: 'center' }}
        >
          {staffErrors && (
            <Grid
              item
              xs={12}
              style={{
                width: '100%',
                textAlign: 'center',
              }}
            >
              <Typography variant={'h5'} color="error">
                {staffErrors.roles}
              </Typography>
            </Grid>
          )}
          <Permissions
            definitions={permissionOptions}
            permissions={staff.roles}
            handleChangePermission={handleStaffRoleChange(staffIdx)}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default EditableStaffRow
