import { StudyData } from '@models/data.models'

export enum StudyActionTypes {
  SET_ALL_STUDIES = 'SET_ALL_STUDIES',
  SET_STUDY = 'SET_STUDY',
  REMOVE_STUDY = 'REMOVE_STUDY',
}

type SetAllStudiesAction = {
  type: StudyActionTypes.SET_ALL_STUDIES
  payload: StudyData[]
}

type SetStudyAction = {
  type: StudyActionTypes.SET_STUDY
  payload: StudyData
}

type RemoveStudyAction = {
  type: StudyActionTypes.REMOVE_STUDY
  payload: string
}

export type StudyAction =
  | SetAllStudiesAction
  | SetStudyAction
  | RemoveStudyAction

export const setAllStudiesAction = (
  studies: StudyData[]
): SetAllStudiesAction => ({
  type: StudyActionTypes.SET_ALL_STUDIES,
  payload: studies,
})

export const setStudyAction = (study: StudyData): SetStudyAction => ({
  type: StudyActionTypes.SET_STUDY,
  payload: study,
})

export const removeStudyAction = (studyId: string): RemoveStudyAction => ({
  type: StudyActionTypes.REMOVE_STUDY,
  payload: studyId,
})
