import { useReducer } from 'react'
import { SiteStaff, StaffManagementState } from '@models/staff.models'
import { staffManagementReducer } from './reducer'
import { Action } from '@components/Staff/actions'

const initialState: StaffManagementState = {
  staff: [],
  staffErrors: [],
}

const useStaffManagement = () => {
  const [state, dispatch] = useReducer(staffManagementReducer, {
    ...initialState,
    staff: [...initialState.staff],
  })

  const addStaff = () => {
    dispatch({ type: Action.ADD_STAFF })
  }

  const removeStaff = (staffIndex: number) => {
    dispatch({
      type: Action.REMOVE_STAFF,
      payload: { staffIndex },
    })
  }

  const resetStaff = () => {
    console.log('resetStaff', initialState.staff)
    dispatch({ type: Action.RESET_STAFF, payload: { ...initialState.staff } })
  }

  const staffFieldChange = (staffIndex: number, key: string, value: string) => {
    dispatch({
      type: Action.STAFF_FIELD_CHANGE,
      payload: { staffIndex, key, value },
    })
  }
  const staffRoleChange = (staffIndex: number, role: string, add: boolean) => {
    const action = add ? Action.STAFF_ADD_ROLE : Action.STAFF_REMOVE_ROLE
    dispatch({ type: action, payload: { staffIndex, role } })
  }

  const setAllStaff = (staff: SiteStaff[]) => {
    dispatch({
      type: Action.SET_ALL_STAFF,
      payload: staff,
    })
  }

  return {
    values: state.staff,
    errors: state.staffErrors,
    actions: {
      addStaff,
      removeStaff,
      resetStaff,
      staffFieldChange,
      staffRoleChange,
      setAllStaff,
    },
  }
}

export default useStaffManagement
