import { useCallback, useEffect, useState } from 'react'
import { useKeycloak } from '@providers/auth'
import { useGlobalState } from './useGlobalState'
import useStudies from './useStudies'
import * as StudyApi from '@lib/data/study.data'
import { StudyData } from '@models/data.models'
import { setStudyAction } from '../actions/study.actions'
import { StatusEnum } from '@components/Study/models'

type StudyState = {
  study: StudyData | undefined
  error: Error | undefined
  fetching: boolean
}

export default (studyId: string) => {
  const [state, setState] = useState<StudyState>({
    study: undefined,
    error: undefined,
    fetching: false,
  })

  const { studies } = useStudies()
  const fetchStudy = useFetchStudy()

  useEffect(() => {
    let subscribed = true

    const boundStudy = studies.find((study) => study.id === studyId)
    if (!boundStudy) {
      setState((state) => ({ ...state, fetching: true }))
      fetchStudy(studyId).then((error) => {
        if (!subscribed) return
        setState({ ...state, error, fetching: false })
      })
    } else {
      setState({
        ...state,
        study: boundStudy,
        error: undefined,
        fetching: false,
      })
    }

    return () => {
      subscribed = false
    }
  }, [studies])
  return state
}

const useFetchStudy = () => {
  const { dispatch } = useGlobalState()
  const { token } = useKeycloak()

  const fetchStudy = useCallback(
    (studyId: string) => {
      return StudyApi.getStudy(studyId, token)
        .then((study) => ({
          id: study.id,
          name: study.name,
          protocolId: study.protocolId,
          protocolTitle: study.protocolTitle,
          studyType: study.type,
          lead: study.lead,
          status: study.status as StatusEnum,
          siteCount: study.siteCount,
          subjectCount: 0,
          actions: ['Export Data'],
        }))
        .then((study) => {
          dispatch(setStudyAction(study))
          return undefined
        })
        .catch((err: Error) => {
          console.error('error in useFetchStudy', err)
          return err
        })
    },
    [token]
  )
  return fetchStudy
}
