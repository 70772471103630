import { useContext } from 'react'
import { AccessContext, initialState } from './access.provider'

export default () => {
    const ctx = useContext(AccessContext)
    if (!ctx) {
        console.log('AccessProvider not mounted')
        return initialState
    }

    return ctx
}
