import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import AddSubjectForm from './AddSubjectForm'
import MuiDialogActions from '@material-ui/core/DialogActions'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Divider from '@material-ui/core/Divider'
import CloseIcon from '@material-ui/icons/Close'
import useTheme from '@material-ui/core/styles/useTheme'
import useDashboardStyles from '@components/GlobalStyles'
import useAddSubject from './useAddSubject'
import { validateId } from '@lib/study/validate'

interface AddSubjectProps {
  siteId: string
  subjectLabels: string[]
  onAddSubject: (id: string) => void
}

const validate = (label: string, subjectLabels: string[]) => {
  let error = validateId(label)
  if (!error && subjectLabels.includes(label)) {
    error = 'Subject Id already in use'
  }
  return error
}

const AddSubject = (props: AddSubjectProps) => {
  const [label, setLabel] = useState('')
  const [error, setError] = useState('')
  const [openDialog, setOpenDialog] = useState(false)
  const theme = useTheme()
  const classes = useDashboardStyles(theme)
  const { createSubject } = useAddSubject()

  const handleShowDialog = () => {
    setOpenDialog(true)
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
  }

  const handleSubmitSubject = () => {
    setError('')
    const error = validate(label, props.subjectLabels)
    if (error) {
      setError(error)
    } else {
      createSubject(props.siteId, { label }).then((id) => {
        props.onAddSubject(id)
        setLabel('')
        setOpenDialog(false)
      })
    }
  }

  return (
    <React.Fragment>
      <Grid container spacing={2} justifyContent={'flex-end'}>
        <Grid item xs={2}>
          <Button
            color="primary"
            variant="contained"
            style={{
              textTransform: 'initial',
              width: '100%',
            }}
            onClick={handleShowDialog}
          >
            Add Subject
          </Button>
        </Grid>
      </Grid>
      <Dialog
        open={openDialog}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
        fullWidth
      >
        <MuiDialogTitle>
          <Typography style={{ color: theme.palette.text.secondary }}>
            Add a New Subject
          </Typography>
          <IconButton
            className={classes.closeButton}
            aria-label="close"
            onClick={handleCloseDialog}
          >
            <CloseIcon />
          </IconButton>
        </MuiDialogTitle>
        <Divider />
        <AddSubjectForm label={label} error={error} setLabel={setLabel} />
        <MuiDialogActions>
          <Button
            style={{ marginRight: 10 }}
            variant="outlined"
            onClick={handleCloseDialog}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handleSubmitSubject}
            disabled={label === ''}
          >
            Submit
          </Button>
        </MuiDialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default AddSubject
