import React from 'react'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import Footer from '@components/Footer/Footer'
import { HospitalRouter, StudyRouter } from '@components/Router/Router'
import { DataProvider } from '@providers/data/data.provider'
import { BeepProvider } from '@providers/beep/beep.provider'
import useToast from '@providers/data/hooks/useToast'
import AlarmEngine from '@providers/alarm/AlarmEngine'
import useInstanceConfig from '@providers/data/hooks/useInstanceConfig'
import Spinner from '@components/Spinner/Spinner'
import { useKeycloak } from '@providers/auth'
import useParticipantWebsocket from '@providers/websocket/participant.websocket'
import useInstanceWebsocket from '@providers/websocket/instance.websocket'
import useAlarmsWebsocket from '@providers/websocket/alarms.websocket'
import VoyagerHeader from '@components/Header/VoyagerHeader'
import HospitalHeader from '@components/Header/HospitalHeader'

const HospitalDashboard = () => {
  const { keycloak } = useKeycloak()
  useParticipantWebsocket()
  useAlarmsWebsocket()
  return (
    <BeepProvider>
      <AlarmEngine />
      <HospitalRouter keycloak={keycloak} />
    </BeepProvider>
  )
}

const StudyDashboard = () => {
  const { keycloak, parsed } = useKeycloak()
  return <StudyRouter keycloak={keycloak} parsed={parsed} />
}

const ToastBar = () => {
  const { toast, hideToast } = useToast()
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={toast.show}
      autoHideDuration={3000}
      onClose={hideToast}
    >
      <MuiAlert elevation={6} variant="filled" severity={toast.severity}>
        {toast.message}
      </MuiAlert>
    </Snackbar>
  )
}

const App = () => {
  useInstanceWebsocket()
  const { instanceConfig } = useInstanceConfig()

  if (!instanceConfig) {
    return <Spinner />
  }

  return (
    <div style={{ marginBottom: 150 }}>
      <ToastBar />
      {instanceConfig.is_voyager ? <VoyagerHeader /> : <HospitalHeader />}
      <Container maxWidth="xl">
        <Grid container>
          {instanceConfig.is_voyager ? (
            <StudyDashboard />
          ) : (
            <HospitalDashboard />
          )}
        </Grid>
      </Container>
      <Footer></Footer>
    </div>
  )
}

// Wrap app in Data Provider HOC
export default (): JSX.Element => (
  <DataProvider>
    <App />
  </DataProvider>
)
