import { IInstanceConfig, IUserConfig } from '@models/index'
import { Action, ActionTypes } from '../actions'

export interface ConfigState {
  instanceConfig?: IInstanceConfig
  userConfig: IUserConfig
}

export default (state: ConfigState, action: Action): ConfigState => {
  switch (action.type) {
    case ActionTypes.USER_CONFIG_UPDATE:
      return { ...state, userConfig: action.payload }
    case ActionTypes.INSTANCE_CONFIG_UPDATE:
      return { ...state, instanceConfig: action.payload }
    default:
      return state
  }
}
