import React, { CSSProperties } from 'react'
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  makeStyles,
  useTheme,
  Typography,
  TextField,
} from '@material-ui/core'
import NotificationsIcon from '@material-ui/icons/Notifications'

import TrendArrow from '@components/TrendArrow/TrendArrow'
import { IParticipantData, IInstanceConfig } from '@models/index'
import { egvStatusStyle } from '@lib/egv'

import ActionMenu from '@components/ActionMenu/ActionMenu'
import useDashboardStyles from '@components/GlobalStyles'

const useStyles = makeStyles((theme) => ({
  '@global': {
    '@keyframes blinker': {
      '50%': {
        opacity: 0,
      },
    },
  },
  animated: {
    animation: '$blinker 1000ms linear infinite',
  },
  participantField: {
    margin: 0,
  },
  egvStyle: {
    fontSize: `${theme.typography.h2.fontSize}`,
    [theme.breakpoints.down('xs')]: {
      fontSize: `${theme.typography.h1.fontSize}`,
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: `${theme.typography.h1.fontSize}`,
    },
  },
}))

interface ParticipantDetailsProps {
  participant: IParticipantData
  siteConfig: IInstanceConfig
  onMetadataUpdate?: () => void
}

const ParticipantDetails: React.FunctionComponent<ParticipantDetailsProps> = ({
  participant,
  siteConfig,
  onMetadataUpdate,
  children,
}) => {
  const theme = useTheme()
  const classes = useStyles(theme)
  const global = useDashboardStyles(theme)

  const statusStyle = egvStatusStyle(participant.status, theme)
  return (
    <Card>
      <CardHeader
        className={participant.alarmTrigger ? classes.animated : ''}
        style={statusStyle}
        classes={{
          root: global.cardRoot,
          title: global.cardTitle,
          action: global.cardAction,
        }}
        avatar={participant.alarm && <NotificationsIcon />}
        action={
          <ActionMenu
            id={participant.id}
            onEdit={onMetadataUpdate}
            menuOptions={
              onMetadataUpdate
                ? ['Edit', ...participant.actions]
                : participant.actions
            }
            color={statusStyle.color?.toString() || theme.palette.text.primary}
            studyId={participant.unit_id}
            siteId={participant.unit_id}
          />
        }
        title={participant.status}
      />
      <CardContent style={{ paddingBottom: '0px' }}>
        <Grid container style={{ height: '100%' }}>
          <Grid container item xs={8}>
            {siteConfig.metas.map((meta, i) => (
              <Grid item xs={12} key={`${participant.id}${meta.displayname}`}>
                <TextField
                  id={meta.displayname}
                  label={meta.displayname}
                  value={participant.metadata[i]}
                  InputProps={{ disableUnderline: true, readOnly: true }}
                  className={global.userTextField}
                  size="small"
                  title={`${meta.displayname} - ${participant.metadata[i]}`}
                />
              </Grid>
            ))}

            <Grid item xs={3}>
              <TextField
                id={`${participant.id}-condition`}
                label="Condition"
                value={participant.condition}
                InputProps={{ disableUnderline: true, readOnly: true }}
                className={global.userTextField}
              />
            </Grid>
            <Grid item xs={9}>
              <TextField
                id={`${participant.id}-SessionEnds`}
                label="Est. Ends"
                value={participant.sessionEnd}
                InputProps={{ disableUnderline: true, readOnly: true }}
                className={global.userTextField}
                title="Estimated Session End Time"
              />
            </Grid>
          </Grid>
          <Grid container item xs={4}>
            <Grid
              item
              xs={12}
              container
              justifyContent="flex-end"
              alignItems="flex-end"
            >
              <Typography variant="body2" color="textSecondary">
                {participant.received}
              </Typography>
            </Grid>
            <Grid
              container
              item
              xs={12}
              alignContent="center"
              justifyContent="center"
            >
              <Grid
                item
                xs={10}
                container
                justifyContent="center"
                style={{ paddingRight: '10pt' }}
              >
                <Typography className={classes.egvStyle}>
                  {participant.latestEgv}
                </Typography>
              </Grid>
              <Grid
                item
                xs={2}
                container
                justifyContent="center"
                alignContent="center"
              >
                <TrendArrow big trend={participant.trend} />
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              container
              justifyContent="flex-end"
              alignItems="flex-start"
            >
              <Typography variant="body2" color="textSecondary">
                mg/dL
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container style={{ height: '100%' }}>
          <Grid container item xs>
            {children}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default ParticipantDetails
