import { useMemo } from 'react'
import useSubjects from '@providers/subject/useSubjects'
import useSubjectMetrics from '@providers/metrics/useSubjectMetrics'
import { ComposedSubject, IndicatorStatus } from './models'
import { formatTime, justTime, timeSpan } from '@lib/utils/dates'
import useTimer from '@lib/hooks/useTimer'

const TEN_MINUTES = 1000 * 60 * 10

const MONTHS = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
]

export const formatDate = (dateStr: string) => {
    const date = new Date(dateStr)
    const month = date.getMonth()
    return `${MONTHS[month]} ${date.getDate()}, ${date.getFullYear()} ${justTime(date)}`
}

const decideIndicator = (status: string, lastKnownCommunication: number, now: number) => {
    switch(status) {
        case 'pending_login':
        case 'app_setup':
            return IndicatorStatus.WARNING
        case 'receiving_data':
        case 'warmup':
            if (now - lastKnownCommunication > TEN_MINUTES) {
                return IndicatorStatus.WARNING
            }
            return IndicatorStatus.OK
        default:
            return IndicatorStatus.UNKNOWN
    }
}

const formatStatus = (status: string, lastKnownCommunication: number, now: number) => {
    switch(status) {
        case 'pending_login':
            return 'Pending Login'
        case 'app_setup':
            return 'App Setup'
        case 'warmup': {
            if (now - lastKnownCommunication > TEN_MINUTES) {
                return 'Not Connected'
            }
            return 'Warmup'
        }
        case 'receiving_data': {
            if (now - lastKnownCommunication > TEN_MINUTES) {
                return 'Not Connected'
            }
        }
            return 'Receiving Data'
        default:
            return 'Unknown'
    }
}

const date = (dateStr: string): string => {
    if (!dateStr) {
      return '---'
    }
    return formatDate(dateStr)
}

const countdown = (dateStr: string, now: number): string => {
    if (!dateStr) {
        return '---'
    }

    const date = Date.parse(dateStr)
    const diff = date - now
    if (diff <= 0) {
        return '0d 0h 0m'
    }

    return formatTime(diff / (1000 * 60))
}

const ticks = (dateStr: string, now: number): string => {
    if (!dateStr) {
        return '---'
    }

    const date = Date.parse(dateStr)

    return timeSpan(date, now)
}

const parseDateStr = (dateStr: string): number => {
    if (!dateStr) return 0
    return Date.parse(dateStr)
}

export default (siteLabel: string): ComposedSubject[] => {
    const subjects = useSubjects()
    const metrics = useSubjectMetrics()
    const now = useTimer(1000 * 60)

    const composedSubjects = useMemo(() => {
        return subjects.map(s => {
            const m = metrics[s.id]
            return {
                id: s.id,
                label: `${siteLabel}-${s.label}`,
                status: m ? formatStatus(m.status, parseDateStr(m.lastKnownCommunication), now): '',
                indicator: m ? decideIndicator(m.status, parseDateStr(m.lastKnownCommunication), now): IndicatorStatus.UNKNOWN,
                lastEgv: m ? ticks(m.lastEgv, now) : '',
                firstEgv: m ? date(m.firstEgv) : '',
                sessionStart: m ? date(m.sessionStart) : '',
                sessionEnd: m ? countdown(m.sessionEnd, now) : ''
            }
        })
    }, [subjects, metrics, siteLabel, now])

    return composedSubjects
}
