import { useContext } from "react"
import { initialState, MetricsContext } from "./metrics.provider"

export default () => {
    const ctx = useContext(MetricsContext)
    if (!ctx) {
        console.log('MetricsProvider not mounted!')
        return initialState.metrics
    }

    const { state } = ctx
    return state.metrics
}