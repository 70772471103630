import { useCallback, useMemo, useState } from 'react'
import { CreateStudyResponse, NewStudy } from '@models/api.models'
import { statusMap, StatusEnum } from '@components/Study/models'
import { StudyData } from '@models/data.models'
import * as StudyApi from '@lib/data/study.data'
import { setAllStudiesAction, setStudyAction } from '../actions/study.actions'
import { useGlobalState } from './useGlobalState'
import { useKeycloak } from '@providers/auth'
import useToast from './useToast'

const useStudies = () => {
  const [fetching, setFetching] = useState(false)
  const { state, dispatch } = useGlobalState()
  const { token } = useKeycloak()
  const { createToast } = useToast()

  const findDuplicates = useCallback(
    (name: string, protocolId: string, protocolTitle: string) => {
      const found: StudyData[] = []
      const studyIds = Object.keys(state.studies)
      for (let i = 0; i < studyIds.length; i++) {
        const studyId = studyIds[i]
        const study = state.studies[studyId]
        if (
          (name && name === study.name) ||
          (protocolId && protocolId === study.protocolId) ||
          (protocolTitle && protocolTitle === study.protocolTitle)
        ) {
          found.push(study)
        }
      }
      return found
    },
    [state.studies]
  )

  const fetchStudies = useCallback(() => {
    setFetching(true)

    StudyApi.getStudies(token)
      .then((studies) =>
        studies.map((study) => ({
          id: study.id,
          name: study.name,
          protocolId: study.protocolId,
          protocolTitle: study.protocolTitle,
          studyType: study.type,
          lead: study.lead,
          status: study.status as StatusEnum,
          siteCount: study.siteCount,
          subjectCount: study.subjectCount,
          actions: ['Export Data'],
        }))
      )
      .then((studies) => {
        dispatch(setAllStudiesAction(studies))
      })
      .catch((e) => {
        console.error(e)
        createToast('Failed to load studies', 'error')
      })
      .finally(() => {
        setFetching(false)
      })
  }, [createToast, dispatch, token])

  const createStudy = useCallback(
    async (study: NewStudy): Promise<boolean> => {
      return StudyApi.postStudy(study, token)
        .then(({ createdStudy: study, emailSuccess }: CreateStudyResponse) => ({
          emailSuccess,
          study: {
            id: study.id,
            name: study.name,
            protocolId: study.protocolId,
            protocolTitle: study.protocolTitle,
            studyType: study.type,
            lead: study.lead,
            status: study.status as StatusEnum,
            siteCount: study.siteCount,
            subjectCount: study.subjectCount,
            actions: ['Delete Study'],
            createdDate: new Date(),
          },
        }))
        .then(
          ({
            emailSuccess,
            study,
          }: {
            emailSuccess: boolean
            study: StudyData
          }) => {
            dispatch(setStudyAction(study))
            if (emailSuccess) {
              createToast('Created New Study!')
            } else {
              createToast(
                'Created New Study! - One or more emails could not be sent',
                'warning'
              )
            }
            return true
          }
        )
        .catch((e) => {
          console.error(e)
          createToast('Could not create study.', 'error')
          return false
        })
    },
    [token, createToast, dispatch]
  )

  const studiesSorted = useMemo(() => {
    return Object.values(state.studies).sort(sortByStatus || sortByCreatedDate)
  }, [state.studies])

  return {
    studies: studiesSorted,
    fetchStudies,
    createStudy,
    findDuplicates,
    fetching,
  }
}

const sortByCreatedDate = (a: StudyData, b: StudyData) => {
  const aDate = a.createdDate ? a.createdDate.getTime() : 0
  const bDate = b.createdDate ? b.createdDate.getTime() : 0

  return bDate - aDate
}

const sortByStatus = (a: StudyData, b: StudyData): number => {
  const aStatus = statusMap.get(a.status) || 0
  const bStatus = statusMap.get(b.status) || 0

  return aStatus === bStatus ? 0 : aStatus > bStatus ? 1 : -1
}

export default useStudies
