import { IInstanceDetails } from '@models/index'
import { Action, ActionTypes } from '../actions'

export default (
  state: IInstanceDetails[],
  action: Action
): IInstanceDetails[] => {
  switch (action.type) {
    case ActionTypes.ALL_INSTANCES: {
      return [...action.payload]
    }
    case ActionTypes.NEW_INSTANCE: {
      return [...state, action.payload]
    }
    case ActionTypes.DELETE_INSTANCE: {
      const newState = [...state]
      const idx = newState.findIndex(
        (instance) => instance.instanceId === action.payload
      )
      if (idx > -1) {
        newState.splice(idx, 1)
      }
      return newState
    }
    default:
      return state
  }
}
