import React, { useState } from 'react'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import Divider from '@material-ui/core/Divider'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import CustomEvent from '@components/Settings/CustomEvent'
import useCustomEvents from '@providers/data/hooks/useCustomEvents'
import { useTheme } from '@material-ui/core'
import DeleteEventDialog from './DeleteEventDialog'

export default function CustomEventsSettings() {
  const theme = useTheme()
  const { rules, newRule, updateRule, deleteRule } = useCustomEvents()
  const [deleteDialog, setDeleteDialog] = useState({ id: '', show: false })

  const handleCancel = () => {
    setDeleteDialog({ id: '', show: false })
  }

  const handleDelete = () => {
    deleteRule(deleteDialog.id)
    setDeleteDialog({ id: '', show: false })
  }

  const showDialog = (id: string) => {
    setDeleteDialog({ id: id, show: true })
  }

  return (
    <Card elevation={1}>
      <CardHeader
        title="Events"
        action={
          <Button
            color="primary"
            variant="contained"
            style={{ fontSize: 11, textTransform: 'initial' }}
            onClick={newRule}
          >
            Add
          </Button>
        }
      />
      <Divider />
      <CardContent>
        {rules.map((event, index) => (
          <CustomEvent
            customEvent={event}
            onsave={updateRule}
            onDelete={showDialog}
            key={event.id}
            backgroundColor={
              index % 2
                ? theme.palette.background.default
                : theme.palette.background.paper
            }
          />
        ))}
      </CardContent>
      <DeleteEventDialog
        show={deleteDialog.show}
        onCancel={handleCancel}
        onDelete={handleDelete}
      />
    </Card>
  )
}
