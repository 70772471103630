import { useGlobalState } from '../../providers/data/hooks/useGlobalState'
import {
  hideAccessCodeDialog,
  showAccessCodeDialog,
} from '../../providers/data/actions'

const useAccessCode = () => {
  const { state, dispatch } = useGlobalState()

  const showAccessCodeDialogModal = (
    participantId: string,
    studyId: string,
    siteId: string
  ) => {
    dispatch(
      showAccessCodeDialog({
        id: participantId,
        studyId,
        siteId,
      })
    )
  }

  const hideAccessCodeDialogModal = () => {
    dispatch(hideAccessCodeDialog())
  }

  return {
    open: state.accessCodeState.showDialog,
    subjectId: state.accessCodeState.subjectId,
    siteId: state.accessCodeState.siteId,
    studyId: state.accessCodeState.studyId,
    showAccessCodeDialog: showAccessCodeDialogModal,
    hideAccessCodeDialog: hideAccessCodeDialogModal,
  }
}

export default useAccessCode
