export interface HttpResponse<T> extends Response {
  parsedBody?: T
}

export class HttpError extends Error {
  statusCode: number

  constructor(statusCode: number, message: string) {
    super(message)
    this.statusCode = statusCode
  }
}

export async function http<T>(request: RequestInfo): Promise<HttpResponse<T>> {
  const response: HttpResponse<T> = await fetch(request)
  if (response.ok) {
    response.parsedBody = await response.json()
    return response
  } else {
    throw new HttpError(response.status, response.statusText)
  }
}

// http GET
export async function get<T>(
  url: string,
  token: string
): Promise<HttpResponse<T>> {
  const request = new Request(url, {
    headers: new Headers({
      Authorization: `Bearer ${token}`,
    }),
    method: 'GET',
  })
  return await http<T>(request)
}

// http POST
export async function post<T>(
  url: string,
  body: unknown,
  token: string
): Promise<HttpResponse<T>> {
  const request = new Request(url, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json; charset=utf-8',
      'x-requested-with': 'XmlHttpRequest',
    }),
  })

  return http<T>(request)
}

export async function put<T>(
  url: string,
  body: unknown,
  token: string
): Promise<HttpResponse<T>> {
  const request = new Request(url, {
    method: 'PUT',
    body: JSON.stringify(body),
    headers: new Headers({
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: `Bearer ${token}`,
    }),
  })

  return http<T>(request)
}

// http DELETE
export async function del(
  url: string,
  id: string,
  token: string
): Promise<HttpResponse<undefined>> {
  const request = new Request(`${url}/${id}`, {
    method: 'DELETE',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
    }),
  })

  return http<undefined>(request)
}
