import { useContext, useEffect } from 'react'
import useToast from './useToast'
import useDataRequests from './useDataRequests'
import { ITermsDetails } from '@models/index'
import { DataContext } from '../data.provider'
import { termsAction } from '../actions'

type TermsState = {
  terms: ITermsDetails
  saveTerms: (terms: string) => void
}

const useSiteTerms = (siteId: string): TermsState => {
  const ctx = useContext(DataContext)

  if (!ctx) {
    throw new Error('useTerms hook must be used inside DataProvider context')
  }

  const { state, dispatch } = ctx
  const { createToast } = useToast()
  const { getTermsBySiteId, createSiteTerms, updateTerms } = useDataRequests()

  useEffect(() => {
    getTermsBySiteId(siteId)
      .then((terms) => {
        dispatch(termsAction(terms))
      })
      .catch((e) => {
        console.log(e)
        createToast('Failed To Load Site Terms', 'error')
      })
  }, [siteId])

  const saveTerms = (terms: string) => {
    if (state.terms.terms.id) {
      const updateParams = { id: state.terms.terms.id, terms }
      updateTerms(updateParams)
        .then((updatedTerms) => {
          dispatch(termsAction(updatedTerms))
          createToast('Site Terms Updated')
        })
        .catch((e) => {
          console.log(e)
          createToast('Failed To Update Site Terms', 'error')
        })
    } else {
      const createTerms = { siteId, terms }

      createSiteTerms(createTerms)
        .then((createdTerms) => {
          dispatch(termsAction(createdTerms))
          createToast('Site Terms Created')
        })
        .catch((e) => {
          console.log(e)
          createToast('Failed To Create Site Terms', 'error')
        })
    }
  }

  return {
    terms: state.terms.terms,
    saveTerms: saveTerms,
  }
}

export default useSiteTerms
