import { useCallback, useContext } from "react";
import { useKeycloak } from "@providers/auth";
import { deleteEventRule } from "@lib/fetch-requests";
import { IEventRule } from "@models/index";

import { DataContext } from "../data.provider";
import useToast from "./useToast";
import useDataRequests from "./useDataRequests";
import { allEventRulesAction, deleteEventRuleAction, updateEventRuleAction } from "../actions";

export const DEFAULT_EVENT_RULE = {
  name: 'Event Rule',
  durationIn: 10,
  durationOut: 10,
  filters: [{ operator: '>', value: 200 }],
  color: 'red',
}

const useCustomEvents = () => {
  const ctx = useContext(DataContext)

  if (!ctx) {
    throw new Error('useCustomEvents hook must be used inside DataProvider context')
  }

  const { state, dispatch } = ctx
  const { token } = useKeycloak()
  const { createToast } = useToast()
  const { createEventRule, updateEventRule } = useDataRequests()

  const newRule = () => {
    createEventRule(DEFAULT_EVENT_RULE)
      .then(() => {
        createToast('New Rule Added Successfully!')
      })
      .catch((e) => {
        console.log(e)
        createToast('Error - Could Not Add New Rule', 'error')
      })
  }

  const updateRule = (eventRule: IEventRule) => {
    updateEventRule(eventRule)
      .then(() => {
        createToast('Settings Save Successfully!')
      })
      .catch((e) => {
        console.log(e)
        createToast('Error - Could Not Save Settings', 'error')
      })
  }

  const deleteRule = (id: string) => {
    deleteEventRule(token, id)
      .then(() => {
        createToast('Rule Deleted')
      })
      .catch((e) => {
        console.log(e)
        createToast('Failed to delete rule', 'error')
      })
  }

  const setAllEventRules = useCallback((rules: IEventRule[]) => {
    dispatch(allEventRulesAction(rules))
  }, [dispatch])

  const setEventRule = useCallback((rule: IEventRule) => {
    dispatch(updateEventRuleAction(rule))
  }, [dispatch])

  const removeEventRule = useCallback((id: string) => {
    dispatch(deleteEventRuleAction(id))
  }, [dispatch])

  return {
    rules: Object.values(state.eventRules),
    newRule,
    updateRule,
    deleteRule,
    setAllEventRules,
    setEventRule,
    removeEventRule
  }
} 

export default useCustomEvents
