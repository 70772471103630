import { MetricsSummary } from "@models/metrics.models";
import React, { createContext, FunctionComponent, useCallback, useReducer } from "react";
import { Action, setMetricsAction } from "./metrics.actions";
import reducer, { MetricsState } from "./metrics.reducer";
import useMetricsSocket from '../websocket/metrics.websocket'

export const initialState: MetricsState = {
    metrics: {}
}

const MetricsContext = createContext<{
    state: MetricsState,
    dispatch: React.Dispatch<Action>
}>({
    state: initialState,
    dispatch: () => null
})

type MetricsProviderParams = {
    siteId: string
}

const MetricsProvider: FunctionComponent<MetricsProviderParams> = ({ siteId, children }) => {
    const [state, dispatch] = useReducer(reducer, initialState)
    const setMetrics = useCallback((summaries: MetricsSummary[]) => {
        dispatch(setMetricsAction(summaries))
    }, [])
    useMetricsSocket({ siteId, setMetrics })

    return (
        <MetricsContext.Provider value={{ state, dispatch }}>
            {children}
        </MetricsContext.Provider>
    )
}

export { MetricsContext, MetricsProvider }