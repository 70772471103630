import { IAcknowledgement } from "@models/index";
import { Action, ActionTypes } from '../actions'

export default (state: IAcknowledgement[], action: Action): IAcknowledgement[] => {
  switch (action.type) {
    case ActionTypes.ALL_ACKNOWLEDGEMENTS: {
      return [...action.payload]
    }
    case ActionTypes.NEW_ACKNOWLEDGEMENT: {
      state = [...state, action.payload]
      return state
    }
    default:
      return state
  }
}
