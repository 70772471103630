import React from 'react'
import {
  Grid,
  Typography,
  Card,
  useTheme,
  Button,
  Divider,
} from '@material-ui/core'
import useInstances from '@providers/data/hooks/useInstances'
import Spinner from '@components/Spinner/Spinner'
import { InstancesListView } from './InstancesListView'
import CreateInstanceModalView from './CreateInstanceModalView'

export default function InstancesHome() {
  const theme = useTheme()
  const [open, setOpen] = React.useState(false)
  const { instances, newInstance, removeInstance } = useInstances()

  if (instances.length === 0) {
    return <Spinner />
  }

  const openModal = () => {
    setOpen(true)
  }

  const closeModal = () => {
    setOpen(false)
  }

  const handleInstanceCreate = (
    instanceId: string,
    adminUsername: string,
    password: string,
    sandbox?: boolean,
    voyager?: boolean
  ) => {
    newInstance(instanceId, adminUsername, password, sandbox, voyager)
    setOpen(false)
  }

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12} style={{ marginTop: 10, marginBottom: 10 }}>
          <Card
            style={{
              backgroundColor: theme.palette.background.paper,
              marginBottom: 10,
            }}
          >
            <Grid container>
              <Grid item xs={12}>
                <Grid container alignItems={'center'}>
                  <Grid item xs={9} sm={10} md={11}>
                    <Typography
                      style={{
                        padding: '10px',
                        color: theme.oppositePalette.colorInverted,
                      }}
                    >
                      Instances
                    </Typography>
                  </Grid>
                  <Grid item xs={3} sm={2} md={1}>
                    <Button
                      color="primary"
                      variant="contained"
                      style={{
                        textTransform: 'initial',
                        width: '90%',
                      }}
                      onClick={openModal}
                    >
                      New Instance
                    </Button>
                    <CreateInstanceModalView
                      open={open}
                      addInstance={handleInstanceCreate}
                      handleClose={closeModal}
                    ></CreateInstanceModalView>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Divider />
            <Grid
              container
              justify={'center'}
              style={{ marginBottom: '1em', marginTop: '1em' }}
            >
              <Grid item xs={12} style={{ maxWidth: '98%' }}>
                <InstancesListView
                  instances={instances}
                  onDelete={removeInstance}
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
