import React from 'react'
import { Grid, Typography, useTheme } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'

type BreadcrumbNavProps = {
  studyId: string
  studyName: string
  siteId?: string
  siteName?: string
}

export default function BreadcrumbNav(props: BreadcrumbNavProps) {
  const theme = useTheme()
  const coalesedStudyName = `Study - ${props.studyName || props.studyId}`
  const studyLinkText = props.siteId
    ? `${coalesedStudyName}   /   `
    : coalesedStudyName

  return (
    <Grid item xs={12} style={{ display: 'inline', textDecoration: 'none' }}>
      <Typography
        to={`/`}
        component={RouterLink}
        style={{
          fontSize: 14,
          color: theme.palette.text.secondary,
          display: 'inline',
          textDecoration: 'none',
        }}
      >
        {'All Studies   /   '}
      </Typography>
      <Typography
        to={`/study/${props.studyId}`}
        component={RouterLink}
        style={{
          fontSize: 14,
          color: props.siteId
            ? theme.palette.text.secondary
            : theme.palette.primary.main,
          display: 'inline',
          textDecoration: 'none',
        }}
      >
        {studyLinkText}
      </Typography>

      {props.siteId && (
        <Typography
          to={`/study/${props.studyId}/site/${props.siteId}`}
          component={RouterLink}
          style={{
            fontSize: 14,
            color: theme.palette.primary.main,
            display: 'inline',
            textDecoration: 'none',
          }}
        >
          {`Site - ${props.siteName || props.siteId}`}
        </Typography>
      )}
    </Grid>
  )
}
