export enum StudyActions {
  MONITOR_DEVICES = 'monitor_devices',
  MANAGE_SUBJECTS = 'manage_subjects',
  EXPORT_DATA = 'export_data',
  MANAGE_ROLES = 'manage_roles',
  MANAGE_USERS = 'manage_users',
  MANAGE_SETTINGS = 'manage_settings',
  VIEW_SUBJECTS = 'view_subjects',
  SPECIAL_ACCESS = 'special_access',
}

export type Role = {
  name: string
  description: string
}

export const dexcomStaffRoles: Record<string, Role> = {
  'monitor-devices': {
    name: 'Monitor Devices',
    description:
      'Check this box if the staff member should be able to monitor devices.',
  },
  'export-data': {
    name: 'Export Data',
    description:
      'Check this box if the staff member should be able to export data.',
  },
  'manage-roles': {
    name: 'Manage Roles',
    description:
      'Check this box if the staff member should be able to manage site staff permissions.',
  },
}

export const siteStaffRoles: Record<string, Role> = {
  'manage-subjects': {
    name: 'Manage Subjects',
    description:
      'Check this box if the staff member should be able to manage subjects.',
  },
  'manage-staff': {
    name: 'Manage Staff',
    description:
      'Check this box if the staff member should be able to manage access to this site.',
  },
  'devices-and-data': {
    name: 'Monitor Devices & Export Data',
    description:
      'Check this box if the staff member should be able to manage data.',
  },
}

export const allRoles = {
  ...dexcomStaffRoles,
  ...siteStaffRoles,
}
