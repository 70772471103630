import React, { useEffect, useState } from 'react'
import { useLocation, Redirect } from 'react-router-dom'
import { useKeycloak } from '@providers/auth'
import useToast from '@providers/data/hooks/useToast'
import { activateParticipant } from '@lib/fetch-requests'

const ActivateParticipant = () => {
  const [status, setStatus] = useState('activating')
  const location = useLocation()
  const { token } = useKeycloak()
  const { createToast } = useToast()

  useEffect(() => {
    // make call to activate the participant
    const params = new URLSearchParams(location.search)
    const state = params.get('state')
    const code = params.get('code')

    if (!state || !code) {
      console.log('Callback missing required params')
      setStatus('error')
      return
    }

    activateParticipant(token, state, { code: code })
      .then(() => {
        createToast('Successfully created participant')
        setStatus('success')
      })
      .catch((e) => {
        console.log('Error activating participant:\n' + e)
        setStatus('error')
      })
  }, [token, location.search]) // eslint-disable-line react-hooks/exhaustive-deps

  if (status === 'activating') {
    return <div>Activating Participant...</div>
  } else if (status === 'success') {
    return <Redirect to="/" />
  } else {
    return <div>Error while adding participant</div>
  }
}

export default ActivateParticipant
