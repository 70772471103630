import { IUserConfig, SiteViewEnum } from '@models/index'
import { setUserConfig } from '../actions'
import { useGlobalState } from './useGlobalState'

interface UserConfigData {
  userConfig: IUserConfig
  setUserConfigDashboardView: (dashboardView: string) => Promise<void>
  setUserConfigSiteView: (siteView: SiteViewEnum) => Promise<void>
}

const useUserConfig = (): UserConfigData => {
  const { state, dispatch } = useGlobalState()

  const setUserConfigDashboardView = async (dashboardView: string) => {
    try {
      const freshConfig = { ...state.config.userConfig, dashboardView }
      dispatch(setUserConfig(freshConfig))
    } catch (error) {
      console.log('userConfig Error: ' + error)
    }
  }

  const setUserConfigStudyView = async (studyView: SiteViewEnum) => {
    try {
      const freshConfig = { ...state.config.userConfig, studyView }
      dispatch(setUserConfig(freshConfig))
    } catch (error) {
      console.log('userConfig Error: ' + error)
    }
  }

  return {
    userConfig: state.config.userConfig,
    setUserConfigDashboardView,
    setUserConfigSiteView: setUserConfigStudyView,
  }
}

export default useUserConfig
