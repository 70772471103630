import React from 'react'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import { Dialog, Divider, Typography, useTheme } from '@material-ui/core'
import StudyWizard from './StudyWizard'

interface ICreateStudyModalProps {
  open: boolean
  handleClose: () => void
}

export default function CreateStudyModalView({
  open,
  handleClose,
}: ICreateStudyModalProps) {
  const theme = useTheme()

  return (
    <React.Fragment>
      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
        fullWidth
      >
        <MuiDialogTitle>
          <Typography style={{ color: theme.palette.text.secondary }}>
            New Study Wizard
          </Typography>
        </MuiDialogTitle>
        <Divider />
        <StudyWizard onClose={handleClose} />
      </Dialog>
    </React.Fragment>
  )
}
