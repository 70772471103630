import { API_URL, siteUrl } from '@config/resourceUrls'
import { get } from '@lib/utils/http'
import { StaffList } from '@models/staff.models'

export async function getAccessList(
  resourceId: string,
  token: string
): Promise<string[]> {
  const response = await get<string[]>(`${API_URL}/access/${resourceId}`, token)
  if (!response.parsedBody) {
    throw new Error('getAccessList - response body empty')
  }

  return response.parsedBody
}

export async function getStaffList(
  token: string,
  resourceId: string
): Promise<Record<string, StaffList>> {
  const response = await get<Record<string, StaffList>>(
    `${siteUrl}/${resourceId}/staff`,
    token
  )

  if (!response.parsedBody) {
    throw new Error('getStaffList - response body empty')
  }

  return response.parsedBody
}
