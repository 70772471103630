import React from 'react'
import { useParams, Link as RouterLink } from 'react-router-dom'
import {
  useTheme,
  Typography,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Divider,
  TextField,
  Button,
} from '@material-ui/core'
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined'
import Spinner from '@components/Spinner/Spinner'
import ActionMenu from '@components/ActionMenu/ActionMenu'
import { subjectStatusStyle, metricsStatusStyle } from '../Study/models'
import { useParticipantById } from '@components/Participant/useParticipantById'
import useInstanceConfig from '@providers/data/hooks/useInstanceConfig'
import useDashboardStyles from '@components/GlobalStyles'
import useStudy from '@providers/data/hooks/useStudy'
import useSite from '@lib/hooks/useSite'

export default function SubjectDetails() {
  const { studyId, siteId, subjectId } = useParams<{
    studyId: string
    siteId: string
    subjectId: string
  }>()
  const { instanceConfig } = useInstanceConfig()
  const theme = useTheme()
  const classes = useDashboardStyles(theme)
  const participantInfo = useParticipantById(subjectId)
  const { study, fetching: studyFetch } = useStudy(studyId)
  const site = useSite(siteId)

  if (!study || !site.data) {
    return <div />
  }

  if (studyFetch || site.fetching || !instanceConfig) {
    return <Spinner />
  }

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            style={{ fontSize: 48, color: theme.palette.text.secondary }}
          >
            Subject {participantInfo.id}
          </Typography>
        </Grid>
        <Grid container item xs={12}>
          <Button
            aria-label="Back To Study"
            to={`/study/${studyId}`}
            component={RouterLink}
            variant="outlined"
            color="primary"
            style={{ maxHeight: '2em', minHeight: '2em', marginRight: '1em' }}
            startIcon={<ArrowBackOutlinedIcon fontSize="small" />}
          >
            Study {study.name || study.protocolId}
          </Button>
          <Button
            aria-label="Back To Site"
            to={`/study/${studyId}/site/${siteId}`}
            component={RouterLink}
            variant="outlined"
            color="secondary"
            style={{ maxHeight: '2em', minHeight: '2em', marginRight: '1em' }}
            startIcon={<ArrowBackOutlinedIcon fontSize="small" />}
          >
            Site {site.data.label || site.data.region || study.protocolId}
          </Button>
        </Grid>

        <Grid container item xs={12} md={6} id="subjectDetailsArea">
          <Card style={{ width: '100%' }}>
            <CardHeader
              title={participantInfo.status}
              style={subjectStatusStyle(participantInfo.status, theme)}
              classes={{
                root: classes.cardRoot,
                title: classes.cardTitle,
                action: classes.cardAction,
              }}
              action={
                <ActionMenu
                  id={participantInfo.id}
                  menuOptions={['Ping', 'Archive']}
                  color={
                    subjectStatusStyle(participantInfo.status, theme).color ||
                    theme.palette.text.primary
                  }
                  studyId={studyId}
                  siteId={siteId}
                />
              }
            />
            <Divider />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Grid item xs={12}>
                    <TextField
                      id="subjectStudyIdDetails"
                      label="Study ID"
                      value={studyId}
                      InputProps={{ disableUnderline: true, readOnly: true }}
                      size="small"
                      title={'Subject Study - ' + studyId}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="subjectSiteIdDetails"
                      label="Site ID"
                      value={siteId}
                      InputProps={{ disableUnderline: true, readOnly: true }}
                      size="small"
                      title={'Subject Site - ' + siteId}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Typography variant="body2" color="textSecondary">
                    Subject Information Here
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid container item xs={12} md={6} id="studyMetricsArea">
          <Card style={{ width: '100%' }}>
            <CardHeader
              title="Device: Normal"
              style={metricsStatusStyle('active')}
              classes={{
                root: classes.cardRoot,
                title: classes.cardTitle,
                action: classes.cardAction,
              }}
              action={
                <ActionMenu
                  id={participantInfo.id}
                  menuOptions={['Pause', 'Reset', 'Disable']}
                  color={
                    metricsStatusStyle(participantInfo.status).color ||
                    theme.palette.text.primary
                  }
                />
              }
            />
            <Divider />
            <CardContent>
              <Grid container spacing={2} style={{ textAlign: 'center' }}>
                <Grid item xs={6}>
                  <Typography variant="h5" color="textSecondary">
                    Connected Since
                  </Typography>
                  <Typography variant="h3" color="primary">
                    {participantInfo.received}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h5" color="textSecondary">
                    Active Subjects
                  </Typography>
                  <Typography variant="h3" color="secondary">
                    5
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h5" color="textSecondary">
                    Sensor Errors
                  </Typography>
                  <Typography variant="h3" color="error">
                    0
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h5" color="textSecondary">
                    Average EGV
                  </Typography>
                  <Typography variant="h3" color="textSecondary">
                    {participantInfo.latestEgv}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid container item xs={12} id="subjectEGVGraph">
          Trend Graph Here
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
