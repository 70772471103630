import React from 'react'
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  InputAdornment,
  FormControlLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  useTheme,
} from '@material-ui/core'
import useDashboardStyles from '@components/GlobalStyles'
import { ClientConfig, ClientConfigErrors } from '@models/clientConfig.models'

type ClientConfigAdvancedProps = {
  values: ClientConfig
  errors: ClientConfigErrors
  canEdit: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onSwitchChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export default function ClientConfigAdvanced(props: ClientConfigAdvancedProps) {
  const theme = useTheme()
  const classes = useDashboardStyles(theme)
  const { values, errors } = props

  return (
    <Card>
      <CardHeader
        title="Advanced Settings"
        classes={{
          root: classes.cardRoot,
          title: classes.cardTitle,
          action: classes.cardAction,
        }}
        color="textSecondary"
      />
      <Divider />
      <CardContent>
        <Grid container item xs spacing={2}>
          <Grid item xs={12}>
            <TextField
              id="glucoseTargetRangeLower"
              label="Lower Target"
              size="small"
              type="number"
              variant="outlined"
              style={{ width: '100%' }}
              disabled={!props.canEdit}
              value={values.glucoseTargetRangeLower}
              error={errors.glucoseTargetRangeLower !== ''}
              helperText={errors.glucoseTargetRangeLower}
              onChange={props.onChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">mg/dL</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="glucoseTargetRangeUpper"
              label="Upper Target"
              size="small"
              type="number"
              variant="outlined"
              style={{ width: '100%' }}
              disabled={!props.canEdit}
              value={values.glucoseTargetRangeUpper}
              error={errors.glucoseTargetRangeUpper !== ''}
              helperText={errors.glucoseTargetRangeUpper}
              onChange={props.onChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">mg/dL</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="targetRangeGoalPercentage"
              label="Target Range Goal"
              size="small"
              variant="outlined"
              type="number"
              style={{ width: '100%' }}
              disabled={!props.canEdit}
              value={values.targetRangeGoalPercentage}
              error={errors.targetRangeGoalPercentage !== ''}
              helperText={errors.targetRangeGoalPercentage}
              onChange={props.onChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">%</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="trendGraphHeight"
              label="Trend Graph Height"
              size="small"
              variant="outlined"
              type="number"
              style={{ width: '100%' }}
              disabled={!props.canEdit}
              value={values.trendGraphHeight}
              error={errors.trendGraphHeight !== ''}
              helperText={errors.trendGraphHeight}
              onChange={props.onChange}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  id="isMealPhotoAllowed"
                  name="isMealPhotoAllowed"
                  checked={values.isMealPhotoAllowed}
                  onChange={props.onSwitchChange}
                  color="primary"
                  disabled={!props.canEdit}
                />
              }
              label="Meal Photos Allowed"
              labelPlacement="end"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Select
                  style={{ paddingLeft: 10 }}
                  value="0"
                  disabled={!props.canEdit}
                >
                  <MenuItem value="0">mg/dL</MenuItem>
                  <MenuItem value="1">mmol/L</MenuItem>
                </Select>
              }
              label="Egv Units"
              labelPlacement="end"
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
