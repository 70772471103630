import React, { useEffect, useState } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import TextField from '@material-ui/core/TextField'
import CardContent from '@material-ui/core/CardContent'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import InputAdornment from '@material-ui/core/InputAdornment'
import { IInstanceConfig } from '@models/index'

type GlobalSettingsState = {
  title: string
  supportphone: string
}

type GlobalSettingsProps = {
  title: string
  supportphone: string
  onsave: (settings: IInstanceConfig) => void
}

const useStyles = makeStyles(
  createStyles({
    global: {
      width: 275,
    },
  })
)

const hasChanged = (
  current: GlobalSettingsProps,
  next: GlobalSettingsState
): boolean => {
  return (
    current.title !== next.title || current.supportphone !== next.supportphone
  )
}

const validateFields = (data: string) => {
  return data !== ''
}

const valid = (data: GlobalSettingsState) => {
  return validateFields(data.title) && validateFields(data.supportphone)
}

export default function GlobalSettings(props: GlobalSettingsProps) {
  const [state, setState] = useState<GlobalSettingsState>({ ...props })
  const [canSave, setCanSave] = useState(false)
  const classes = useStyles()

  useEffect(() => {
    if (hasChanged(props, state) && valid(state)) {
      setCanSave(true)
    } else {
      setCanSave(false)
    }
  }, [state, props])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => {
      return {
        ...prevState,
        [event.target.id]: event.target.value,
      }
    })
  }

  const save = () => {
    props.onsave(state as IInstanceConfig)
  }

  const helpText = {
    title: 'Dashboard Name cannot be blank.',
    supportPhone: 'Technical Support Number cannot be blank.',
  }

  return (
    <Card>
      <CardHeader
        title="Global Settings"
        action={
          <Button
            color="primary"
            variant="contained"
            style={{ fontSize: 11, textTransform: 'initial' }}
            onClick={save}
            disabled={!canSave}
          >
            Save
          </Button>
        }
      />
      <Divider />
      <CardContent>
        <List>
          <ListItem>
            <label>Study Label</label>
          </ListItem>
          <ListItem>
            <TextField
              error={!validateFields(state.title)}
              id="title"
              className={classes.global}
              value={state.title}
              size="small"
              variant="outlined"
              onChange={handleChange}
              helperText={
                validateFields(state.title) ? undefined : helpText.title
              }
            />
          </ListItem>
          <ListItem>
            <label>Technical Support Number</label>
          </ListItem>
          <ListItem>
            <TextField
              error={!validateFields(state.supportphone)}
              id="supportphone"
              className={classes.global}
              value={state.supportphone}
              size="small"
              variant="outlined"
              onChange={handleChange}
              inputProps={{
                type: 'tel',
                maxLength: 10,
              }}
              helperText={
                validateFields(state.supportphone)
                  ? undefined
                  : helpText.supportPhone
              }
            />
          </ListItem>
        </List>
      </CardContent>
    </Card>
  )
}
