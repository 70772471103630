import { ConsentMap } from '@models/api.models'
import { Action, ActionTypes } from '../actions'

export type ConsentsState = {
  consents: ConsentMap
}

export default (state: ConsentsState, action: Action): ConsentsState => {
  switch (action.type) {
    case ActionTypes.CONSENTS: {
      return { ...state, consents: action.payload }
    }
    default:
      return state
  }
}
