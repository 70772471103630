import React, { FunctionComponent, useMemo } from 'react'
import { Grid, Tab, Tabs, Typography, useTheme } from '@material-ui/core'
import { SiteViewEnum } from '@models/index'
import { StudyActions } from '@models/access.models'
import BreadcrumbNav from '@components/Site/BreadcrumbNav'
import EmptyInfoContainer from '@components/EmptyInfoContainer'
import SiteConfig from '@components/Site/SiteConfig'
import SiteStaffManagementView from '@components/Staff/SiteStaffManagmentView'
import SpecialAccess from '@components/Site/SpecialAccess'
import Spinner from '@components/Spinner/Spinner'
import { StatusEnum } from '@components/Study/models'
import SubjectListView from '@components/Subject/SubjectListView'
import useSite from '@lib/hooks/useSite'
import useStudy from '@providers/data/hooks/useStudy'
import useUserConfig from '@providers/data/hooks/useUserConfig'
import useAccess from '@providers/access/useAccess'
import ContactTab from '@components/Site/ContactTab'

type TabPanelProps = {
  children?: React.ReactNode
  dir?: string
  index: unknown
  value: unknown
}

function allyProps(index: unknown) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  }
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <Grid
      container
      item
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
      style={{ width: '100%' }}
    >
      {value === index && (
        <Grid item xs={12}>
          {children}
        </Grid>
      )}
    </Grid>
  )
}

export type SiteTabsViewProps = {
  studyId: string
  siteId: string
}

export const SiteTabsView: FunctionComponent<SiteTabsViewProps> = ({
  studyId,
  siteId,
}) => {
  const { study, fetching: studyFetch } = useStudy(studyId)
  const {
    data,
    fetching: siteFetch,
    error: siteError,
    setSiteStatus,
  } = useSite(siteId)
  const { permissions } = useAccess()

  const theme = useTheme()
  const { userConfig, setUserConfigSiteView } = useUserConfig()

  const showSiteInformation = useMemo(() => {
    return (
      permissions.includes(StudyActions.MANAGE_SUBJECTS) ||
      permissions.includes(StudyActions.MANAGE_SETTINGS)
    )
  }, [permissions])

  const showAddSubjectsButton = useMemo(() => {
    if (!data) return false
    return (
      permissions.includes(StudyActions.MANAGE_SUBJECTS) &&
      data.status !== StatusEnum.CREATED
    )
  }, [permissions, data])

  const showStaffTab = useMemo(() => {
    return (
      permissions.includes(StudyActions.MANAGE_ROLES) ||
      permissions.includes(StudyActions.MANAGE_USERS)
    )
  }, [permissions])

  const handleTabChange = (
    event: React.ChangeEvent<Record<string, unknown>>,
    newValue: SiteViewEnum
  ) => {
    setUserConfigSiteView(newValue)
  }

  if (!study || !data) {
    if (studyFetch || siteFetch) {
      return <Spinner />
    }

    if (siteError) {
      return (
        <React.Fragment>
          <BreadcrumbNav
            studyId={studyId}
            studyName={studyId}
            siteId={siteId}
            siteName={siteId}
          />
          <EmptyInfoContainer infoText={siteError} />
        </React.Fragment>
      )
    }

    return (
      <React.Fragment>
        <BreadcrumbNav
          studyId={studyId}
          studyName={studyId}
          siteId={siteId}
          siteName={siteId}
        />
        <EmptyInfoContainer infoText="Site Information Unavailable" />
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <Grid container spacing={2} justifyContent={'space-between'}>
        <BreadcrumbNav
          studyId={studyId}
          studyName={study.name || study.protocolId}
          siteId={siteId}
          siteName={data.label || data.id}
        />

        <Grid
          container
          item
          xs={12}
          id="studySiteTabs"
          alignItems={'center'}
          justifyContent={'flex-start'}
        >
          <Tabs
            value={userConfig.studyView}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleTabChange}
            aria-label="Study Site View Selector"
          >
            <Tab label="Subjects" {...allyProps(0)} />
            {showStaffTab && <Tab label="Staff" {...allyProps(1)} />}
            <Tab label="Configuration" {...allyProps(2)} />
            <Tab label="Contact" {...allyProps(3)} />
            {permissions.includes(StudyActions.SPECIAL_ACCESS) && (
              <Tab label="Special Access" {...allyProps(4)} />
            )}
          </Tabs>
        </Grid>
        {showSiteInformation && (
          <Grid
            container
            item
            xs={12}
            spacing={2}
            justifyContent={'space-between'}
          >
            <Grid
              container
              item
              xs={10}
              style={{ display: 'inline', lineHeight: '3.5em' }}
            >
              <Typography
                variant={'h5'}
                color="textSecondary"
                style={{
                  fontWeight: 'bold',
                  display: 'inline',
                }}
              >
                Protocol ID:
              </Typography>
              <Typography
                variant={'h5'}
                color="textSecondary"
                style={{ display: 'inline' }}
              >
                {` ${study.protocolId}`}
              </Typography>
            </Grid>
          </Grid>
        )}
        <Grid container item xs={12} id="studySiteDetailsTabArea">
          <TabPanel
            value={userConfig.studyView}
            index={0}
            dir={theme.direction}
          >
            <SubjectListView
              siteId={siteId}
              studyId={studyId}
              siteLabel={data.label}
              showAddSubjectsButton={showAddSubjectsButton}
            />
          </TabPanel>
          <TabPanel
            value={userConfig.studyView}
            index={1}
            dir={theme.direction}
          >
            <SiteStaffManagementView siteId={siteId} />
          </TabPanel>

          <TabPanel
            value={userConfig.studyView}
            index={2}
            dir={theme.direction}
          >
            <SiteConfig
              studyId={studyId}
              siteId={siteId}
              siteStatus={data.status}
              setSiteStatus={setSiteStatus}
            />
          </TabPanel>

          <TabPanel
            value={userConfig.studyView}
            index={3}
            dir={theme.direction}
          >
            <ContactTab siteId={siteId} />
          </TabPanel>

          {permissions.includes(StudyActions.SPECIAL_ACCESS) && (
            <TabPanel
              value={userConfig.studyView}
              index={4}
              dir={theme.direction}
            >
              <SpecialAccess studyId={studyId} siteId={siteId} />
            </TabPanel>
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default SiteTabsView
