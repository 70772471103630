import { CUSTOM_SORT_OPTIONS } from '@models/sortOptions.models'
import { ActionTypes, Action } from '../actions'

export type SortByState = CUSTOM_SORT_OPTIONS

export default (state: SortByState, action: Action): SortByState => {
  switch (action.type) {
    case ActionTypes.SET_SORT_BY:
      return action.payload 
    default:
      return state
  }
}