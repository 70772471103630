import React from 'react'
import InputBase from '@material-ui/core/InputBase'
import Card from '@material-ui/core/Card'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import {
  makeStyles,
  createStyles,
  withStyles,
  useTheme,
} from '@material-ui/core'
import { CUSTOM_SORT_OPTIONS } from '@models/sortOptions.models'
import { enumKeys } from '@lib/utils/enumKeys'

const Input = withStyles(() =>
  createStyles({
    input: {
      borderRadius: 4,
      position: 'relative',
      border: '1px solid #cccccc',
      fontSize: 16,
      padding: '10px 10px 10px 10px',
      '&:focus': {
        borderRadius: 4,
      },
    },
  })
)(InputBase)

const useStyles = makeStyles({
  formControl: {
    minWidth: 180,
    fontSize: 14,
  },
})

interface IGroupingProps {
  group: string
  sortBy?: string
  setCustomSort?: (sortBy: CUSTOM_SORT_OPTIONS) => void
}

const Grouping: React.FunctionComponent<IGroupingProps> = ({
  children,
  group,
  sortBy,
  setCustomSort,
}) => {
  const theme = useTheme()
  const classes = useStyles(theme)
  const handleSortOrderChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    if (setCustomSort) {
      setCustomSort(event.target.value as CUSTOM_SORT_OPTIONS)
    }
  }

  return (
    <Card style={{ backgroundColor: theme.palette.background.paper }}>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        style={{ padding: 5, height: 50 }}
      >
        <Typography
          variant="h5"
          style={{ paddingLeft: 10, color: theme.palette.text.secondary }}
        >
          {group}
        </Typography>
        {sortBy && (
          <FormControl size="small" className={classes.formControl}>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={sortBy}
              onChange={handleSortOrderChange}
              label="SortOrder"
              input={<Input />}
            >
              {enumKeys(CUSTOM_SORT_OPTIONS).map((key) => (
                <MenuItem key={key} value={CUSTOM_SORT_OPTIONS[key]}>
                  {CUSTOM_SORT_OPTIONS[key]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Grid>
      <Divider />
      <div
        style={{
          padding: 10,
          width: '100%',
        }}
      >
        {children}
      </div>
    </Card>
  )
}

export default Grouping
