import React from "react"
import { generatePassword } from "@lib/utils/password"
import { resetPassword, resetUserPassword } from "@lib/fetch-requests"
import useToast from "./useToast"

const initialState = { userId: '', open: false, newPassword: '' }

export default (token: string, logout?: () => void) => {
  const { createToast } = useToast()
  const [resetPasswordState, setResetPasswordState] = React.useState(initialState)

  const handleResetPassword = (id: string) => {
    setResetPasswordState({
      userId: id,
      open: true,
      newPassword: generatePassword(),
    })
  }

  const submitResetPassword = () => {
    const { userId, newPassword } = resetPasswordState
    let resetPasswordRequest
    
    // Logout function supplied when intiating resetPassword request for current user.
    // If no logout function, password reset request is for a separate user by an admin.
    if (logout) {
      resetPasswordRequest = resetPassword(newPassword, token)
    } else {
      resetPasswordRequest = resetUserPassword(userId, newPassword, token)
    }
    resetPasswordRequest.then(() => {
      createToast('Password reset successfully')
      if(logout){
        logout()
      }
    })
    .catch((e) => {
      console.log(e)
      createToast('Password reset failed', 'error')
    })
    .finally(() => {
      setResetPasswordState(initialState)
    })
  }

  const closeResetPasswordModal = () => {
    setResetPasswordState(initialState)
  }

  return {
    open: resetPasswordState.open,
    newPassword: resetPasswordState.newPassword,
    handleResetPassword, 
    submitResetPassword, 
    closeResetPasswordModal
  }
}
