import { useMemo } from "react"
import { EGV_STATUS } from "@lib/egv"
import { IParticipantData, IParticipantGrouping } from "@models/index"
import { CUSTOM_SORT_OPTIONS } from "@models/sortOptions.models"
import useParticipantMap from '@providers/data/hooks/useParticipants'
import useCustomSort from '@providers/data/hooks/useCustomSort'

const statusToInt = (status: string) => {
  switch (status) {
    case EGV_STATUS.URGENT_LOW:
      return 1
    case EGV_STATUS.LOW:
      return 2
    case EGV_STATUS.URGENT_HIGH:
      return 3
    case EGV_STATUS.HIGH:
      return 4
    case EGV_STATUS.CHECK_SENSOR:
      return 5
    default:
      return 0
  }
}

export const sortSensorStatus = (a: IParticipantData, b: IParticipantData) => {
  if (a.status === b.status) {
    const sensorStatus = a.status
    if (a.latestEgv === b.latestEgv || sensorStatus.includes('Check Sensor')) {
      const aLatestEGV = a.allEgvs.length > 0 ? a.allEgvs[a.allEgvs.length - 1].systemtime.getTime() : 0
      const bLatestEGV = b.allEgvs.length > 0 ? b.allEgvs[b.allEgvs.length - 1].systemtime.getTime() : 0
      return aLatestEGV - bLatestEGV
    } else {
      if (sensorStatus.includes('High')) {
        return b.latestEgv - a.latestEgv
      } else {
        return a.latestEgv - b.latestEgv
      }
    }
  } else {
    return statusToInt(a.status) - statusToInt(b.status)
  }
}

export const sortParticipants = (sortBy: string) => {
  switch (sortBy) {
    case CUSTOM_SORT_OPTIONS.TIME_IN_RANGE:
      return sortByTimeInRange
    case CUSTOM_SORT_OPTIONS.HYPER_EVENTS:
      return sortByNumberOfHyperEvents
    case CUSTOM_SORT_OPTIONS.HYPO_EVENTS:
      return sortByNumberOfHypoEvents
    case CUSTOM_SORT_OPTIONS.HIGHEST_VALUE:
      return sortByHighEGV
    case CUSTOM_SORT_OPTIONS.LOWEST_VALUE:
      return sortByLowEGV
    default:
      return sortByTimeOutOfRange
  }
}

const sortByTimeInRange = (a: IParticipantData, b: IParticipantData) => {
  return b.percentTimeInRange - a.percentTimeInRange
}

const sortByTimeOutOfRange = (a: IParticipantData, b: IParticipantData) => {
  return b.percentTimeOutOfRange - a.percentTimeOutOfRange
}

const sortByNumberOfHyperEvents = (a: IParticipantData, b: IParticipantData) => {
  return b.numberOfHyperEvents - a.numberOfHyperEvents
}

const sortByNumberOfHypoEvents = (a: IParticipantData, b: IParticipantData) => {
  return b.numberOfHypoEvents - a.numberOfHypoEvents
}

const sortByHighEGV = (a: IParticipantData, b: IParticipantData) => {
  return b.latestEgv - a.latestEgv
}

const sortByLowEGV = (a: IParticipantData, b: IParticipantData) => {
  return a.latestEgv - b.latestEgv
}

export const sortByEndDate = (a: IParticipantData, b: IParticipantData) => {
  return b.endDate - a.endDate
}

export const useGroupedParticipants = () => {
  const { participantData: allParticipantsByKey } = useParticipantMap()
  const participantsByGroup = useMemo(() => {
    const participantGroups: IParticipantGrouping = {
      priority: [],
      active: [],
      inactive: [],
    }
    Object.keys(allParticipantsByKey).forEach(participantId => {
      const info = allParticipantsByKey[participantId]
      if (info.status === EGV_STATUS.INACTIVE) {
        participantGroups.inactive.push(info)
      } else if (info.status === EGV_STATUS.ACTIVE) {
        participantGroups.active.push(info)
      } else {
        participantGroups.priority.push(info)
      }
    })
    participantGroups.priority.sort(sortSensorStatus)
    participantGroups.inactive.sort(sortByEndDate)
    return participantGroups
  }, [allParticipantsByKey])
  return participantsByGroup
}

export const useSortedGroups = () => {
  const groupedParticipants = useGroupedParticipants()
  const { sortBy, setCustomSort } = useCustomSort()
  const sortedGroups = useMemo(() => {
    return {
      ...groupedParticipants,
      inRange: [...groupedParticipants.active].sort(sortParticipants(sortBy))
    }
  }, [groupedParticipants, sortBy])
  return {
    groupedParticipants: sortedGroups,
    sortBy,
    setCustomSort
  }
}
