import { KeycloakInstance } from "keycloak-js";
import createAuthContext from "./context";
import createAuthProvider from "./provider";

export const keycloakContext = createAuthContext<KeycloakInstance>();
export const KeycloakContextConsumer = keycloakContext.Consumer;

const KeycloakProvider = createAuthProvider(keycloakContext);

export default KeycloakProvider;
