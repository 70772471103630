import { siteUrl } from '@config/resourceUrls'
import { get } from '@lib/utils/http'
import { SiteContactInstructionsResponse } from '@models/api.models'

export const getSiteContactInstructions = async (
  token: string,
  siteId: string
): Promise<SiteContactInstructionsResponse> => {
  const resp = await get<SiteContactInstructionsResponse>(
    `${siteUrl}/${siteId}/contact`,
    token
  )

  if (!resp.parsedBody) {
    throw new Error('Expected response body, got undefined')
  }

  return resp.parsedBody
}
