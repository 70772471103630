export interface IDataState {
  participants: IParticipantRecord[]
  siteConfig: IInstanceConfig
  userConfig: IUserConfig
}

export interface ISession {
  startdate: Date
  enddate: Date
}

export interface EGVRecord {
  systemtime: Date
  displaytime: Date
  realtimevalue: number
  smoothedvalue: number
  status: string
  trend: string
  trendrate: string
  value: number
  participantid: string
}

export interface IMetaData {
  meta_id: number
  displayname: string
}

export interface IParticipantData {
  id: string
  status: string
  sessionEnd: string
  latestEgv: number
  trend: string
  received: string
  allEgvs: EGVRecord[]
  alarms: IAlarm[]
  metadata: string[]
  condition: string
  percentTimeInRange: number
  percentTimeOutOfRange: number
  timeInRange: number
  timeHyper: number
  timeHypo: number
  events: IEgvEventRecord[]
  numberOfHyperEvents: number
  numberOfHypoEvents: number
  endDate: number
  actions: string[]
  alarm: boolean
  alarmTrigger: boolean
  lastAlarmTrigger: number
  comments: IComment[]
  unit_id: string
}

export interface IParticipantGrouping {
  priority: IParticipantData[]
  active: IParticipantData[]
  inactive: IParticipantData[]
}

export interface IComment {
  body: string
  date: Date
  submitter: string
}

export interface IParticipantRecord {
  id: string
  unit_id: string | null
  metadata: string[] | null
  condition: string
  sessions: ISession[] | null
  status: string
  enddate: string
  aboverangecount: number
  belowrangecount: number
  egvcount: number
  events: IEgvEventRecord[] | null
  urgentlowcount: number
  withinrangecount: number
  comments: IComment[] | null
}

export interface IDashboardUserDetails {
  id: string
  username: string
  roles: IRoleDetail[]
  instanceId: string
  isCurrentUser?: boolean
}
export interface IEgvEventRecord {
  startdate: Date
  enddate: Date
  type: string
}

export interface IUserConfig {
  dashboardView: string
  studyView: SiteViewEnum
}

export interface IInstanceConfig {
  metas: Array<IMetaData>
  highalertthreshold: number
  highaudiblealarmsenabled: boolean
  higheventthreshold: number
  hypereventtime: number
  hypoeventtime: number
  lowalertthreshold: number
  lowaudiblealarmsenabled: boolean
  loweventthreshold: number
  mineventtime: number
  signallossaudiblealarmsenabled: boolean
  supportphone: string
  title: string
  is_voyager: boolean
}

export interface AddParticipantResponse {
  id: string
}

export enum DashboardView {
  LIST_VIEW = 'listView',
  CARD_VIEW = 'cardView',
  GRAPH_VIEW = 'graphView',
}

export enum SiteViewEnum {
  SUBJECTS_VIEW = 0,
  STAFF_VIEW = 1,
  SETTINGS_VIEW = 2,
  CONTACT_VIEW = 3,
  ENGINEER_VIEW = 4,
}

export interface IAlarm {
  id: string
  participantId: string
  siteId: string
  expiration: Date
  type: string
  canceled: boolean
}

export interface IAcknowledgement {
  userId: string
  siteId: string
  participantId: string
  timestamp: Date
  alarmId: string
}

export enum MENU_OPTION {
  ACKNOWLEDGE = 'Acknowledge',
  INACTIVATE = 'Inactivate',
  ACTIVATE = 'Activate',
  DISCHARGE = 'Discharge',
  ACCESS_CODE = 'Access Code',
  INACTIVATE_STUDY = 'Inactivate Study',
  DELETE_STUDY = 'Delete Study',
  EXPORT_DATA = 'Export Data',
  PING = 'Ping',
}

export interface IInstanceDetails {
  id: string
  instanceId: string
  userCount: number
  adminUsername: string
  title: string
  isVoyager: boolean
}

export interface IEventRule {
  id: string
  siteId: string
  name: string
  durationOut: number
  durationIn: number
  filters: IEventFilter[]
  color: string
}

export interface IEventFilter {
  operator: string
  value: number
}

export interface IDefaultEventRule {
  name: string
  durationOut: number
  durationIn: number
  filters: IEventFilter[]
  color: string
}

export interface ISubjectDetails {
  id: string
  studyId: string
  siteId: string
}

export interface IStudyDetails {
  id: string
  name: string
  instanceId: string
  type: string
  startDate: number
  endDate: number
  protocolTitle: string
  protocolId: string
  dexcomAdministrator: string
  status: string
  userCount?: number
  participantCount?: number
  actions?: string[]
}

export interface ITermsDetails {
  id: string
  studyId: string
  terms: string
  created: string
  updated: string
}

export type TermsUpdateParams = {
  id: string
  terms: string
}

export type TermsCreateBySiteParams = {
  siteId: string
  terms: string
}

export type TermsCreateByStudyParams = {
  studyId: string
  terms: string
}

export interface IRoleDetail {
  id: string
  name: string
  formattedName: string
  instanceType: string
}
