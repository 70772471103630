import React from 'react'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import { StudyErrors } from './models'
import { NewStudy } from '@models/api.models'
import { Grid, Typography, useTheme } from '@material-ui/core'
import { themeCreator } from '@providers/themes/themeService'

type CreateStudySitesFormProps = {
  values: NewStudy
  errors: StudyErrors
  onFieldChange: (key: string, value: string) => void
  onSiteFieldChange: (siteIndex: number, key: string, value: string) => void
  onAddSite: () => void
  onRemoveSite: (siteIndex: number) => void
}

const CreateStudySitesForm = (props: CreateStudySitesFormProps) => {
  const theme = useTheme()
  const handleSiteFieldChange =
    (index: number, field: string) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value
      props.onSiteFieldChange(index, field, value)
    }

  const handleAddSite = () => {
    props.onAddSite()
  }

  const handleRemoveSite = (siteIndex: number) => () => {
    props.onRemoveSite(siteIndex)
  }

  const { values, errors } = props

  return (
    <React.Fragment>
      {(errors.siteInfo || values.sites.length === 0) && (
        <Grid
          container
          justifyContent="center"
          alignItems={'center'}
          style={{
            textAlign: 'center',
            minHeight: '25vh',
          }}
        >
          {errors.siteInfo && (
            <Grid item xs={12}>
              <Typography variant={'h5'} color="error">
                {errors.siteInfo}
              </Typography>
            </Grid>
          )}

          {values.sites.length === 0 && (
            <Grid item xs={12}>
              <Button
                startIcon={<AddIcon />}
                onClick={handleAddSite}
                variant="contained"
                color="primary"
              >
                New Site
              </Button>
            </Grid>
          )}
        </Grid>
      )}
      {!errors.siteInfo && values.sites.length > 0 && (
        <Grid
          container
          justifyContent="center"
          alignItems={'center'}
          style={{
            marginTop: '10pt',
          }}
          zeroMinWidth
        >
          {values.sites.map((site, idx) => {
            const siteErrors = errors.sites[idx]
            return (
              <Grid
                container
                item
                xs={10}
                md={8}
                alignItems={'center'}
                style={{ marginTop: '10pt' }}
                key={idx}
                spacing={1}
              >
                <Grid item xs={12} md={3}>
                  <TextField
                    id="label"
                    label="Site Id"
                    variant="outlined"
                    value={site.label}
                    error={siteErrors && siteErrors.label !== ''}
                    helperText={siteErrors ? siteErrors.label : ''}
                    style={{
                      width: '100%',
                      backgroundColor: theme.palette.background.paper,
                    }}
                    onChange={handleSiteFieldChange(idx, 'label')}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    id="country"
                    label="Country"
                    variant="outlined"
                    select
                    value={site.country}
                    error={siteErrors && siteErrors.country !== ''}
                    helperText={siteErrors ? siteErrors.country : ''}
                    style={{
                      width: '100%',
                      backgroundColor: theme.palette.background.paper,
                    }}
                    onChange={handleSiteFieldChange(idx, 'country')}
                  >
                    <MenuItem value="United States">{'United States'}</MenuItem>
                    <MenuItem value="United Kingdom">
                      {'United Kingdom'}
                    </MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    id="siteAdministrator"
                    label="Site Admin"
                    variant="outlined"
                    value={site.siteAdministrator}
                    error={siteErrors && siteErrors.siteAdministrator !== ''}
                    helperText={siteErrors ? siteErrors.siteAdministrator : ''}
                    style={{
                      width: '100%',
                      backgroundColor: theme.palette.background.paper,
                    }}
                    onChange={handleSiteFieldChange(idx, 'siteAdministrator')}
                  />
                </Grid>
                <Grid item xs={2} md={1}>
                  <IconButton onClick={handleRemoveSite(idx)}>
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Grid>
                {idx === values.sites.length - 1 && (
                  <Grid item xs={2} md={1}>
                    <Button
                      onClick={handleAddSite}
                      variant="contained"
                      color="primary"
                    >
                      <AddIcon fontSize="small"></AddIcon>
                    </Button>
                  </Grid>
                )}
              </Grid>
            )
          })}
        </Grid>
      )}
    </React.Fragment>
  )
}

export default CreateStudySitesForm
