import React from 'react'
import Card from '@material-ui/core/Card'
import checkSensorImage from '@assets/images/faq-screen-shot.png'
import { CardContent, Grid, makeStyles, Typography } from '@material-ui/core'

const faqStyles = makeStyles({
  faqCard: {
    margin: '1em',
    width: '100%',
  },
  faqMargin: {
    margin: '1.5em',
  },
  faqPadLeft: {
    paddingLeft: '1.5em',
  },
  faqPadTop: {
    paddingTop: '1em',
  },
  faqAnswer: {
    paddingTop: '1em',
    marginLeft: '1.5em',
    textAlign: 'justify',
  },
})
function FAQ(): JSX.Element {
  const faqClasses = faqStyles()

  return (
    <React.Fragment>
      <Card classes={{ root: faqClasses.faqCard }}>
        <CardContent classes={{ root: faqClasses.faqMargin }}>
          <Typography variant="h5" align="center" color="error">
            USE OF DEXCOM’S INVESTIGATIONAL-USE DASHBOARD FOR SECONDARY DISPLAY
            OF DEXCOM CGM DATA AS ADDITIONAL TOOL FOR REMOTE PARTICIPANT
            MONITORING
          </Typography>
          <Typography align="justify" classes={{ root: faqClasses.faqPadTop }}>
            As there are no approved or available alternatives that could
            provide continuous remote glucose monitoring in the hospital
            setting, Dexcom is responding to numerous requests from hospitals
            for the use of our real-time continuous glucose monitoring devices,
            such as the Dexcom G6 CGM System (“<b>Dexcom G6</b>”), to remotely
            monitor participants. It is expected that the use of the Dexcom G6
            in this context will help reduce Personal Protective Equipment (“
            <b>PPE</b>”) usage and reduce hospital staff exposure to patients
            with COVID-19 during the current COVID-19 pandemic. For more
            information, please see{' '}
            <a href="https://www.dexcom.com/hospitalfacts">
              https://www.dexcom.com/hospitalfacts
            </a>
            .
          </Typography>

          <Typography align="justify" classes={{ root: faqClasses.faqPadTop }}>
            Dexcom’s investigational-use dashboard provides a secondary display
            of the data generated from the Dexcom G6 that may be used by
            hospitals as an additional tool used in the context of IRB-approved
            study to further support remote-participant monitoring in the
            hospital setting.
          </Typography>
        </CardContent>
      </Card>
      <Grid container justify="center">
        <Grid item>
          <Typography
            variant="h3"
            color="textSecondary"
            style={{ margin: '0.5em' }}
          >
            FAQs
          </Typography>
        </Grid>
      </Grid>

      <Card classes={{ root: faqClasses.faqCard }}>
        <CardContent classes={{ root: faqClasses.faqMargin }}>
          <Typography variant="h4" color="secondary">
            Treatment Decisions
          </Typography>
          <Typography
            variant="h6"
            align="justify"
            color="textSecondary"
            classes={{ root: faqClasses.faqPadTop }}
          >
            Can I make treatment decisions (e.g., dosing) based on the data in
            the Dashboard?
          </Typography>
          <Typography classes={{ root: faqClasses.faqAnswer }}>
            No. The investigational-use dashboard is an additional tool that has
            been made available by Dexcom for the secondary display of CGM data
            to assist healthcare professions in remotely monitoring their
            participants’ glucose data in the context of an IRB-approved
            clinical trial.
          </Typography>
          <Typography classes={{ root: faqClasses.faqAnswer }}>
            All treatment decisions should be based on standard point of care
            (POC) glucose test for treatment decision. Healthcare professionals
            (HCPs) should use existing hospital protocols which may include
            standard point of care (POC) or serum laboratory glucose tests to
            manage and treat participant glucose level. Real-time CGM systems
            can be used to provide remote monitoring and glucose trends to aid
            in glucose management and medical decision making.
          </Typography>

          <Typography classes={{ root: faqClasses.faqAnswer }}>
            To see information for the use of Dexcom CGM systems for remote
            participant monitoring in COVID-19 related efforts, please see{' '}
            <a href="https://www.dexcom.com/hospitalfacts">
              https://www.dexcom.com/hospitalfacts
            </a>
            .
          </Typography>
        </CardContent>
      </Card>
      <Card classes={{ root: faqClasses.faqCard }}>
        <CardContent style={{ margin: '1.5em' }}>
          <Typography variant="h4" color="secondary">
            Dashboard Layout
          </Typography>
          <Grid
            container
            classes={{ root: faqClasses.faqPadLeft }}
            alignItems="center"
          >
            <Grid item xs={12} classes={{ root: faqClasses.faqPadTop }}>
              <Typography variant="h6" align="justify" color="textSecondary">
                What is the difference between the dashboard sections?
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={2}
              md={1}
              classes={{ root: faqClasses.faqPadLeft }}
            >
              <Typography variant="h6" display="inline">
                Priority:
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={10}
              md={11}
              classes={{ root: faqClasses.faqPadLeft }}
            >
              <Typography display="inline">
                Participants who currently have Urgent Low, Low, High, or Check
                Sensor status.
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={2}
              md={1}
              classes={{ root: faqClasses.faqPadLeft }}
            >
              <Typography variant="h6" display="inline">
                Active:
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={10}
              md={11}
              classes={{ root: faqClasses.faqPadLeft }}
            >
              <Typography display="inline">
                Participants who have no currents alerts.
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={2}
              md={1}
              classes={{ root: faqClasses.faqPadLeft }}
            >
              <Typography variant="h6" display="inline">
                Inactive:
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={10}
              md={11}
              classes={{ root: faqClasses.faqPadLeft }}
            >
              <Typography display="inline">
                Manually move participants here when their session has ended.
                Participants in this section can be discharged.
              </Typography>
            </Grid>
            <Grid item xs={12} classes={{ root: faqClasses.faqPadTop }}>
              <Typography variant="h6" align="justify" color="textSecondary">
                How are participants sorted in the Priority section?
              </Typography>
            </Grid>
            <Grid xs={12}>
              <Typography classes={{ root: faqClasses.faqPadLeft }}>
                Participants are sorted by Urgent Low, Low, High, then Check
                Sensor.
              </Typography>
            </Grid>
            <Grid item xs={12} classes={{ root: faqClasses.faqPadTop }}>
              <Typography variant="h6" align="justify" color="textSecondary">
                How are participants sorted in the Active section?
              </Typography>
            </Grid>
            <Grid xs={12}>
              <Typography classes={{ root: faqClasses.faqPadLeft }}>
                The participants can be sorted by Percent Time In Range, Time
                Out of Range, Number of Hypoglycemia Events, Number of
                Hyperglycemia Events, EGV Low to High, or EGV High to Low.
              </Typography>
            </Grid>
            <Grid item xs={12} classes={{ root: faqClasses.faqPadTop }}>
              <Typography variant="h6" align="justify" color="textSecondary">
                What is the Active section’s default sort?
              </Typography>
            </Grid>
            <Grid xs={12}>
              <Typography classes={{ root: faqClasses.faqPadLeft }}>
                By default, participants are sorted by Time Out of Range. Use
                the dropdown to change the sort order. If you close the
                dashboard, it reopens to the default sort.
              </Typography>
            </Grid>
            <Grid item xs={12} classes={{ root: faqClasses.faqPadTop }}>
              <Typography variant="h6" align="justify" color="textSecondary">
                What does “X minutes ago” mean?
              </Typography>
            </Grid>
            <Grid xs={12}>
              <Typography classes={{ root: faqClasses.faqPadLeft }}>
                This is the time since the last EGV was logged by the Dexcom G6
                transmitter.
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card classes={{ root: faqClasses.faqCard }}>
        <CardContent style={{ margin: '1.5em' }}>
          <Typography variant="h4" color="secondary">
            Using the Dashboard
          </Typography>

          <Grid
            container
            classes={{ root: faqClasses.faqPadLeft }}
            alignItems="center"
          >
            <Grid item xs={12} classes={{ root: faqClasses.faqPadTop }}>
              <Typography variant="h6" align="justify" color="textSecondary">
                How do I add a participant to the Dashboard?
              </Typography>
            </Grid>
            <Grid xs={12}>
              <Typography classes={{ root: faqClasses.faqPadLeft }}>
                <ol>
                  <li>
                    Click “Add Participant” in the top right of the Dashboard
                  </li>
                  <li>
                    Enter the participant’s information and click “Submit”
                  </li>
                  <li>
                    When prompted, either create a new Dexcom account or enter
                    the username and password for a pre-created account
                  </li>
                  <li>Accept the data sharing agreement</li>
                  <li>
                    The participant is added - verify that the participant has
                    been added on the reports page
                  </li>
                </ol>
              </Typography>
            </Grid>
            <Grid item xs={12} classes={{ root: faqClasses.faqPadTop }}>
              <Typography variant="h6" align="justify" color="textSecondary">
                How do I remove a participant from the Dashboard?
              </Typography>
            </Grid>
            <Grid xs={12}>
              <Typography classes={{ root: faqClasses.faqPadLeft }}>
                <ol>
                  <li>
                    Within the Actions menu (ellipses in the top right of the
                    participant card or the far right of the row) choose
                    “Inactivate”.
                  </li>
                  <li>The Participant will move to the Inactive section.</li>
                  <li>Within the Actions menu select the Discharge option.</li>
                  <li>A confirmation menu will pop up. Click DISCHARGE.</li>
                </ol>
              </Typography>
            </Grid>
            <Grid item xs={12} classes={{ root: faqClasses.faqPadTop }}>
              <Typography variant="h6" align="justify" color="textSecondary">
                Can I export participant data?
              </Typography>
            </Grid>
            <Grid xs={12}>
              <Typography classes={{ root: faqClasses.faqPadLeft }}>
                Yes. Click Export and follow on-screen instructions
              </Typography>
            </Grid>
            <Grid item xs={12} classes={{ root: faqClasses.faqPadTop }}>
              <Typography variant="h6" align="justify" color="textSecondary">
                When/Why does the dashboard audibly alert?
              </Typography>
            </Grid>
            <Grid xs={12}>
              <Typography classes={{ root: faqClasses.faqPadLeft }}>
                The dashboard <b>audibly</b> alerts you when a participant is
                Urgent Low, Low, High, or if there is a Check Sensor error. The
                dashboard re-alerts every 3 minutes until you acknowledge the
                participant’s alert.
                <ul>
                  <li>Urgent Low: EGV below 60mg/dL.</li>
                  <li>
                    Low: EGV below the threshold set by your administrator.
                  </li>
                  <li>
                    High: EGV above the threshold set by your administrator.
                  </li>
                  <li>
                    Check Sensor: <b>After</b> the participant has been in the
                    “Check Sensor” state for <b>60</b> minutes.
                  </li>
                </ul>
              </Typography>
            </Grid>
            <Grid item xs={12} classes={{ root: faqClasses.faqPadTop }}>
              <Typography variant="h6" align="justify" color="textSecondary">
                How do I confirm alerts?
              </Typography>
            </Grid>
            <Grid xs={12}>
              <Typography classes={{ root: faqClasses.faqPadLeft }}>
                Acknowledge option within the actions menu (ellipses in the top
                right of the participant card or the far right of the row) If
                the participant’s glucose doesn’t come back within their target
                range, they get re-alerted.
                <ul>
                  <li>
                    Urgent Low: Re-alerts after <b>45</b> minutes.
                  </li>
                  <li>
                    Low: Re-alerts after <b>60</b> minutes.
                  </li>
                  <li>
                    High: Re-alerts after <b>120</b> minutes
                  </li>
                  <li>
                    Check Sensor: Re-alert after <b>60</b> minutes
                  </li>
                </ul>
              </Typography>
            </Grid>
            <Grid item xs={12} classes={{ root: faqClasses.faqPadTop }}>
              <Typography variant="h6" align="justify" color="textSecondary">
                What is the password policy?
              </Typography>
            </Grid>
            <Grid xs={12}>
              <Typography classes={{ root: faqClasses.faqPadLeft }}>
                <ul>
                  <li>Must be at least 8 characters </li>
                  <li>
                    Must contain some combination of at least three of the
                    following:
                  </li>
                  <ul>
                    <li>Uppercase letter </li>
                    <li>Lowercase letter </li>
                    <li>Number </li>
                    <li>
                      Punctuation or symbol (`~!@#$%^&amp;*()_-={}
                      |[]:;&apos;&lt;&gt;?,)
                    </li>
                  </ul>
                </ul>
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Card classes={{ root: faqClasses.faqCard }}>
        <CardContent style={{ margin: '1.5em' }}>
          <Typography variant="h4" color="secondary">
            Troubleshooting
          </Typography>
          <Grid
            container
            classes={{ root: faqClasses.faqPadLeft }}
            alignItems="center"
          >
            <Grid item xs={12} classes={{ root: faqClasses.faqPadTop }}>
              <Typography variant="h6" align="justify" color="textSecondary">
                Why doesn&apos;t my participant hanve an &quot;Est. Ends&quot;?
              </Typography>
            </Grid>
            <Grid xs={12}>
              <Typography classes={{ root: faqClasses.faqPadLeft }}>
                The session ends statistic is an estimated date and time for
                when the first sensor will expire.{' '}
                <strong>
                  <u>It is only valid for the first sensor session.</u>
                </strong>
              </Typography>
            </Grid>

            <Grid item xs={12} classes={{ root: faqClasses.faqPadTop }}>
              <Typography variant="h6" align="justify" color="textSecondary">
                How do I troubleshoot the &quot;Check Sensor&quot; error?
              </Typography>
            </Grid>
            <Grid xs={12}>
              <Typography classes={{ root: faqClasses.faqPadLeft }}>
                <img style={{ marginLeft: 36 }} src={checkSensorImage} />
                <ol>
                  <li>
                    {' '}
                    Press <u>ctrl+F5</u> on your keyboard or the refresh icon on
                    your browser
                  </li>
                  <li>
                    {' '}
                    Is this error being displayed by one participant, or all?
                  </li>
                  <ol>
                    <li>If displayed by one participant, go to # 3</li>
                    <li>If displayed by all participants, go to # 4 </li>
                  </ol>
                  <li>
                    Check the primary display in the participant’s room: Does it
                    display an EGV number?
                  </li>
                  <ol>
                    <li>YES, an EGV is present on the phone</li>
                    <ol>
                      <li>Check phone is connected to the Wi-Fi </li>
                      <li>Verify that sharing is enabled on the phone </li>
                    </ol>
                    <li>NO, the phone is also displaying an error</li>
                    <ol>
                      <li>Signal Loss Error</li>
                      <ol>
                        <li>Turn Bluetooth off and on. Wait 10 minutes</li>
                      </ol>
                      <li>Sensor Failed Alert</li>
                      <ol>
                        <li> Follow on-screen instructions</li>
                      </ol>
                      <li>Sensor Error or No Readings Alert</li>
                      <ol>
                        <li>
                          Make sure the sensor is secure and the transmitter is
                          snapped flat in its holder
                        </li>
                        <li>
                          Ensure that the participant is not laying directly on
                          the transmitter
                        </li>
                      </ol>
                    </ol>
                  </ol>
                  <li>
                    Check that the device you are using to view the Dashboard is
                    connected to the internet
                  </li>
                  <li>
                    {' '}
                    Access the Dashboard using a second computer or tablet
                  </li>
                  <li>
                    If you are connected to the internet and using a second
                    device does not work, please call the Tech Support number in
                    the top right of your screen.
                  </li>
                </ol>
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card classes={{ root: faqClasses.faqCard }}>
        <CardContent style={{ margin: '1.5em' }}>
          <Typography variant="h6" align="justify" color="textSecondary">
            I have questions about the Dexcom Investigational-Use Dashboard or
            the Dexcom G6 CGM systems or support tools, how can I reach Dexcom?{' '}
          </Typography>
          <Typography align="justify" style={{ margin: '1.5em' }}>
            Please contact{' '}
            <a href="mailto:covid-19medicalscience@dexcom.com">
              covid-19medicalscience@dexcom.com
            </a>
          </Typography>
        </CardContent>
      </Card>
      <Card classes={{ root: faqClasses.faqCard }}>
        <CardContent style={{ margin: '1.5em' }}>
          <Typography variant="h6" align="justify" color="textSecondary">
            Additional considerations for use of Dexcom G6 CGM Systems in the
            Hospital Settings during the COVID-19 Pandemic{' '}
          </Typography>
          <Typography align="justify" style={{ margin: '1.5em' }}>
            Dexcom Continuous Glucose Monitoring (CGM) Systems are indicated for
            home use and have not been FDA-cleared or approved for use in
            hospital settings. FDA notified Dexcom on April 1, 2020, however,
            that it will not object to Dexcom’s provision of CGM systems for the
            treatment of participants in hospital settings and other healthcare
            facilities to support COVID-19 healthcare-related efforts during the
            current pandemic. For more information, please see{' '}
            <a href="https://www.dexcom.com/hospitalfacts">
              https://www.dexcom.com/hospitalfacts
            </a>
            .
          </Typography>
          <Typography align="justify" style={{ margin: '1.5em' }}>
            <ul>
              <li>
                CGM glucose results are less accurate than blood glucose results
                obtained using traditional testing methods (e.g., lab glucose,
                blood glucose meters). Users should consider all CGM glucose
                information (e.g., trend) along with individual glucose values,
                and interpret CGM results in the context of the full clinical
                picture.
              </li>
              <li>
                CGMs may be subject to interferences that may generate falsely
                high and falsely low glucose readings. Levels of interference
                depend on drug concentration; substances that may not
                significantly interfere in non-hospitalized participants may
                interfere when used in the hospital setting because of higher
                dose levels. Most drugs used in hospital or critical care
                settings have not been evaluated and their interference with
                CGMs is unknown. Based on its structure, it is unlikely
                hydroxychloroquine would interfere with Dexcom CGM. Known
                interferences vary by CGM brand, and can include:
              </li>
              <ul>
                <li>Acetaminophen</li>
                <li>Ascorbic acid</li>
                <li>Hydroxyurea</li>
                <li>Other reducing drugs/ compounds</li>
              </ul>
              <li>
                Poor peripheral blood perfusion may cause inaccurate sensor
                readings. CGM results should be interpreted considering
                accompanying participant conditions and medications. Other
                clinical conditions may also cause inaccurate readings.
              </li>
            </ul>
          </Typography>
        </CardContent>
      </Card>
    </React.Fragment>
  )
}

export default FAQ
