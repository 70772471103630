import { siteUrl } from '@config/resourceUrls'
import { get } from '@lib/utils/http'
import { SiteConfig } from '@models/api.models'

export async function getSiteConfig(
  token: string,
  siteId: string
): Promise<SiteConfig> {
  const response = await get<SiteConfig>(`${siteUrl}/${siteId}/config`, token)
  if (!response.parsedBody) {
    throw new Error('getSiteConfig - response body empty')
  }

  return response.parsedBody
}
